import React, { useState, useEffect, useRef } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import StarRoundedIcon from '@material-ui/icons/StarRounded';
import AddIcon from '@material-ui/icons/Add';
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import SvgIcon from '@material-ui/core/SvgIcon';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import moment from 'moment';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet-async';

import BottomAppBar from '../../components/BottomAppBar';
import '../../style/App.css';
import SignInUpDrawer from '../../components/SignInDrawer';
import LogoutDrawer from '../../components/LogoutDrawer';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import ListLoader from '../errorsAndLoaders/ListLoader';
import { ThemeConsumer } from 'styled-components';
import SnackBarGeneric from '../../components/SnackBarGeneric';
import { isWithinInterval } from 'date-fns';
import Selfie from './CameraCapture';
import JobRolesPanel from '../jobs/SliderPanel';
import AppliedJobs from './AppliedJobs';
import JobInvites from './JobInvites';
import FollowedCompanies from './FollowedCompanies';
import AddRatingsReviews from './AddRatingsReviews';
import FullPageAutocomplete, { RoleAutocomplete, CompaniesAutocomplete } from './FullPageAutocomplete';
import { InsertChartOutlinedRounded } from '@material-ui/icons';
import AboutMe from './AboutMe';
import { useStyles } from './styles';
// import NoResultsImage from '../../assets/no_results.png';
import Wallpaper from '../../assets/Iconwallpaper2.png';
import { updateLastSeenAt } from '../../Helpers';
import Location from './Location';
import CustomRatings from './CustomRatings';
import logo from '../../assets/logo_header.png';
import ReactPlayer from 'react-player/youtube'
import FavouriteCompanies from './FavouriteCompanies';
import Notifications from '../../components/Notifications';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Badge } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tab-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}

function TabPanelSec(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tab-dec-${index}`}
      aria-labelledby={`scrollable-force-tab-sec-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}

function a11yPrimaryTabProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function a11yProps(index) {
  return {
    id: `secondary-tab-${index}`,
    'aria-controls': `secondary-tabpanel-${index}`,
  };
}

export default function ProfilePage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(2);
  const pageStateOnReturn = sessionStorage.getItem("pageStateOnReturn");
  const [primaryTabValue, setPrimaryTabValue] = React.useState(pageStateOnReturn === "appliedJobs" ? 1 : pageStateOnReturn === "jobInvites" ? 2 : 0);
  const [secondaryTabValue, setSecondaryTabValue] = React.useState(4);

  // secondary tabbar scroll vars -- starts
  const aboutMeRef = useRef(null);
  const educationRef = useRef(null);
  const experienceRef = useRef(null);

  const scrollToAboutMe = () => aboutMeRef.current.scrollIntoView();
  const scrollToEducation = () => educationRef.current.scrollIntoView();
  const scrollToExperience = () => experienceRef.current.scrollIntoView();
  // secondary tabbar scroll vars -- ends

  const [isLoading, setIsLoading] = React.useState(true);
  const [isAboutMeLoading, setIsAboutMeLoading] = React.useState(true);
  const [isEduLoading, setIsEduLoading] = React.useState(true);
  const [isExpLoading, setIsExpLoading] = React.useState(true);
  const [isSkillsLoading, setIsSkillsLoading] = React.useState(true);
  const [isPreferencesLoading, setIsPreferencesLoading] = React.useState(true);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);

  const [isAboutMe, setIsAboutMe] = React.useState(true);
  const [isEducation, setIsEducation] = React.useState(false);
  const [isExperience, setIsExperience] = React.useState(false);
  const [isSkills, setIsSkills] = React.useState(false);
  const [isPreferences, setIsPreferences] = React.useState(false);

  const [isBtnBusy, setIsBtnBusy] = React.useState(false);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = React.useState(true);

  const [institutes, setInstitutes] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [allDomains, setAllDomains] = useState([]);
  const [allSkillSources, setAllSkillSources] = useState([]);

  //Api integration
  //const [experienceArray, setExperienceArray] = React.useState([]);
  const [profileObj, setProfileObj] = React.useState(null);

  //Profile fields
  const [profilePhoto, setProfilePhoto] = useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [gender, setGender] = React.useState(null);
  const [locationValue, setLocationValue] = useState("");
  const [latLong, setLatLong] = React.useState({ lat: null, long: null });
  const [medhaAlumni, setMedhaAlumni] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [studentID, setStudentID] = React.useState("");
  const [isErrorAboutMe, setIsErrorAboutMe] = React.useState({ firstName: false, firstNameFormat: false, dob: false, gender: false, location: false, email: false, emailFormat: false, emailDuplicate: false });
  const pcVal = localStorage.getItem('profile_completed') === "true" ? true : false;
  const [isProfileCompleted, setIsProfileCompleted] = React.useState(pcVal);
  const [phoneNumber, setPhoneNumber] = React.useState("")
  //const [isProfileCompleted, setIsProfileCompleted] = React.useState(null);
  //const [emailVerified, setEmailVerified] = React.useState(false);

  const [JobRoles, setJobRoles] = React.useState([]);

  //for add another education/experience/skills
  const [numberEdu, setNumberEdu] = React.useState(1);
  const [numberExp, setNumberExp] = React.useState(1);
  const [numberSkills, setNumberSkills] = React.useState(1);
  const [eduCountArr, setEduCountArr] = React.useState([]);
  const [expCountArr, setExpCountArr] = React.useState([]);
  const [skillsCountArr, setSkillsCountArr] = React.useState([]);

  const [realNumberEdu, setRealNumberEdu] = React.useState(0);
  const [realNumberExp, setRealNumberExp] = React.useState(0);
  const [realNumberSkills, setRealNumberSkills] = React.useState(0);

  // for education
  const [eduIdArray, setEduIdArray] = React.useState([]);
  const [instituteNameArray, setInstituteNameArray] = React.useState([]);
  const [qualTypeArray, setQualTypeArray] = React.useState([]);
  const [courseNameArray, setCourseNameArray] = React.useState([]);
  const [specializationNameArray, setSpecializationNameArray] = React.useState([]);
  const [graduationYearInputArray, setGraduationYearInputArray] = React.useState([]);
  const [prevEduFormVals, setPrevEduFormVals] = React.useState({
    institute: '',
    qualType: '',
    degree: '',
    stream: '',
    year: '',
  });

  const [isEduOpen, setIsEduOpen] = React.useState([]);
  const [isEduEditModeOn, setIsEduEditModeOn] = React.useState(false);
  const [activeEduIndex, setActiveEduIndex] = React.useState(null);
  const [addEduBtnAction, setAddEduBtnAction] = React.useState("add new");
  const [isErrorEdu, setIsErrorEdu] = React.useState({ institute: false, qualType: false, degree: false, stream: false, year: false })
  const [isEduEditDeleteDisabled, setIsEduEditDeleteDisabled] = React.useState(false); // to manage the edit and delete btns visibility for Education
  const [isDeleteEduDialogOpen, setIsDeleteEduDialogOpen] = React.useState(false);
  const [activeEduIndexForDelete, setActiveEduIndexForDelete] = React.useState(null);

  //for experience
  const [showExpRadio, setShowExpRadio] = React.useState(true)
  const [expIdArray, setExpIdArray] = React.useState([]);
  const [jobRoleArray, setJobRoleArray] = React.useState([]);
  const [expTypeArray, setExpTypeArray] = React.useState([]);
  const [expType, setExpType] = React.useState([]);
  const [jobDesc, setJobDesc] = React.useState([]);
  const [isCurrentJob, setIsCurrentJob] = useState([]);
  const [jobStartDate, setJobStartDate] = React.useState([]);
  const [jobEndDate, setJobEndDate] = React.useState([]);
  //const [jobEndDate, setJobEndDate] = React.useState(new Date());
  const [isUnpaid, setIsUnpaid] = useState([]);
  const [fixedSalaryInput, setFixedSalaryInput] = React.useState([]);
  const [variableSalaryInput, setVariableSalaryInput] = React.useState([]);
  const [variableSalaryUnitInput, setVariableSalaryUnitInput] = React.useState([]);
  const [companyNameArray, setCompanyNameArray] = React.useState([]);
  const [workLocationType, setWorkLocationType] = React.useState("");
  const [isWFH, setIsWFH] = React.useState([]);
  const [workLocations, setWorkLocations] = React.useState([]);
  const [workLatLongs, setWorkLatLongs] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [avgRatings, setAvgRatings] = React.useState([]);
  const [envRatings, setEnvRatings] = React.useState([]);
  const [growthRatings, setGrowthRatings] = React.useState([]);
  const [learningRatings, setLearningRatings] = React.useState([]);
  const [facilityRatings, setFacilityRatings] = React.useState([]);
  const [locationRatings, setLocationRatings] = React.useState([]);
  const [managerRatings, setManagerRatings] = React.useState([]);
  const [salaryRatings, setSalaryRatings] = React.useState([]);
  const [roleReviews, setRoleReviews] = React.useState([]);
  const [goodReviews, setGoodReviews] = React.useState([]);
  const [badReviews, setBadReviews] = React.useState([]);
  const [audioReviews, setAudioReviews] = React.useState([]);
  const [videoReviews, setVideoReviews] = React.useState([]);
  const [overallRatings, setOverallRatings] = React.useState([]);
  const [prevExpFormVals, setPrevExpFormVals] = React.useState({
    role: '',
    expType: '',
    jobDesc: '',
    isCurrentJob: '',
    jobStartDate: '',
    jobEndDate: '',
    isUnpaid: '',
    fixedSalaryInput: '',
    variableSalaryInput: '',
    variableSalaryUnitInput: '',
    companyName: '',
    isWFH: '',
    workLocation: '',
    workLatLong: '',
    envRating: '',
    growthRating: '',
    learningRating: '',
    facilityRating: '',
    locationRating: '',
    managerRating: '',
    salaryRating: '',
    reviewTags: '',
    isReviewPublic: '',
    goodReview: '',
    badReview: '',
    audioReview: '',
    videoReview: '',
    overallRating: ''
  });
  const [showOnePageRatings, setShowOnePageRatings] = React.useState(false);
  const [reviewTags, setReviewTags] = React.useState([]);
  const [isReviewPublic, setIsReviewPublic] = React.useState([]);
  const [isWorkLocationACPageOpen, setIsWorkLocationACPageOpen] = React.useState(false);

  const [isExpOpen, setIsExpOpen] = React.useState([]);
  const [isRatingsOpen, setIsRatingsOpen] = React.useState([false]);
  const [isEditModeOn, setIsEditModeOn] = React.useState(false);
  const [activeExpIndex, setActiveExpIndex] = React.useState(null);
  const [addExpBtnAction, setAddExpBtnAction] = React.useState("add new");
  const [isErrorExp, setIsErrorExp] = React.useState({ role: false, expType: false, startDt: false, endDt: false, endDtGreater: false, company: false, location: false, ratings: false })
  const [isEditDeleteDisabled, setIsEditDeleteDisabled] = React.useState(false); // to manage the edit and delete btns visibility
  const [isDeleteExpDialogOpen, setIsDeleteExpDialogOpen] = React.useState(false);
  const [activeExpIndexForDelete, setActiveExpIndexForDelete] = React.useState(null);

  // for skills
  const [skillsIdArray, setSkillsIdArray] = React.useState([]);
  const [skillNameArray, setSkillNameArray] = React.useState([]);
  const [proficiencyArray, setProficiencyArray] = React.useState([]);
  const [domainArray, setDomainArray] = React.useState([]);
  const [skillSourceArray, setSkillSourceArray] = React.useState([]);
  const [skillInfoArray, setSkillInfoArray] = React.useState([]);
  const [prevSkillFormVals, setPrevSkillFormVals] = React.useState({
    name: '',
    proficiency: '',
    domain: '',
    source: '',
    info: '',
  });

  const [isSkillOpen, setIsSkillOpen] = React.useState([]);
  const [isSkillEditModeOn, setIsSkillEditModeOn] = React.useState(false);
  const [activeSkillIndex, setActiveSkillIndex] = React.useState(null);
  const [addSkillBtnAction, setAddSkillBtnAction] = React.useState("add new");
  const [isErrorSkill, setIsErrorSkill] = React.useState({ name: false, proficiency: false, domain: false, source: false, info: false })
  const [isSkillEditDeleteDisabled, setIsSkillEditDeleteDisabled] = React.useState(false); // to manage the edit and delete btns visibility for Education
  const [isDeleteSkillDialogOpen, setIsDeleteSkillDialogOpen] = React.useState(false);
  const [activeSkillIndexForDelete, setActiveSkillIndexForDelete] = React.useState(null);

  // for preferences
  const [prefObj, setPrefObj] = React.useState({});

  const [prefJobType, setPrefJobType] = React.useState("")
  const [prefJobLocation, setPrefJobLocation] = React.useState("")
  const [prefWFH, setPrefWFH] = React.useState(false)
  const [prefQualName, setprefQualName] = React.useState("")
  const [prefSalary, setPrefSalary] = React.useState("")
  const prefSalaryArray = [5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000, 100000]
  const [isErrorPreferences, setIsErrorPreferences] = React.useState({ job_type: false })
  const [locationList, setLocationList] = React.useState([]);
  const [jobTypeList, setJobTypeList] = React.useState([]);
  const [coursesListOnly, setCoursesListOnly] = useState([]);


  //let fullTime = "Full-time", partTime = "Part-time";

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  //let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  const matchesMinHeight = useMediaQuery('(min-height:450px)');

  // for controlling open event -->starts
  const [openInst, setOpenInst] = React.useState(false);
  const [inputValInst, setInputValInst] = React.useState("");
  const handleOpenInst = () => {
    if (inputValInst.length > 0) {
      setOpenInst(true);
    }
  };

  const [openCourse, setOpenCourse] = React.useState(false);
  const [inputValCourse, setInputValCourse] = React.useState("");
  const handleOpenCourse = () => {
    if (inputValCourse.length > 0) {
      setOpenCourse(true);
    }
  };
  // for controlling open event -->ends

  const [isVideoDialogOpen, setIsVideoDialogOpen] = React.useState(false)
  const handleVideoDialogClose = () => {
    setIsVideoDialogOpen(false);
  }

  const handleVideoDialogOpen = () => {
    setIsVideoDialogOpen(true)
  }

  // incentive info tooltip -->starts
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  // incentive info tooltip -->ends

  let jobseekerID = localStorage.getItem("jobseeker_id");

  document.body.style.overflow = "hidden"

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
  };

  const [isImgLoading, setIsImgLoading] = useState(profilePhoto ? true : false);

  const handleProfileImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 0.75) {
      //event.target.style.height = (300 * (190.9 / imageWidth)) + "px"
      event.target.style.height = 'auto';
      event.target.style.width = '100%';
    }
    setIsImgLoading(false);
    event.target.style.opacity = 1
    //event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
  }

  //dialog box for create profile button--starts
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    AnalyticsHandler.track('Profile_deleteAccount_1_BtnClick');
    setIsDialogOpen(true);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }
  //dialog box for create profile button--ends

  //dialog box for delete--starts
  const [isConfDialogOpen, setIsConfDialogOpen] = React.useState(false);

  const handleConfDialogOpen = () => {
    setIsConfDialogOpen(true);
  }

  const handleConfDialogClose = () => {
    setIsConfDialogOpen(false);
  }

  const handleDeleteEduDialogClose = () => {
    setIsDeleteEduDialogOpen(false);
  }

  const handleDeleteExpDialogClose = () => {
    setIsDeleteExpDialogOpen(false);
  }

  const handleDeleteSkillDialogClose = () => {
    setIsDeleteSkillDialogOpen(false);
  }
  //dialog box for delete--ends

  const denyTabChange = () => {
    if (document.getElementById("saveAndCancelBtns")) {
      let saveCancelBtns = document.getElementById("saveAndCancelBtns");
      saveCancelBtns.classList.add('animate');
      setTimeout(() => {
        saveCancelBtns.classList.remove('animate');
      }, 500)
    }
  }

  const enableTabChange = () => {
    if (document.getElementById("saveAndCancelBtns")) document.getElementById("saveAndCancelBtns").style.display = "none"
    // window.location.reload();
    setIsSaveBtnDisabled(true);
  }

  const handlePrimaryTabValueChange = (event, newValue) => {
    if (document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none"
      //&& !isSaveBtnDisabled
    ) {
      denyTabChange()
      return
    }
    if (newValue === 0) {
      AnalyticsHandler.track('Profile_myResume_TabClick');
      setTimeout(() => {
        document.body.style.overflow = "hidden"
      }, 10)
    } else {
      if (newValue === 1) {
        AnalyticsHandler.track('Profile_appliedJobs_TabClick');
      } else if (newValue === 2) {
        AnalyticsHandler.track('Profile_invitesReceived_TabClick');
      }
      setTimeout(() => {
        document.body.style.overflowY = "scroll"
      }, 10)
    }
    setPrimaryTabValue(newValue);
  };

  const handleSecondaryTabValueChange = (event, newValue) => {
    if (document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none"
      //&& !isSaveBtnDisabled
    ) {
      denyTabChange()
      return
    }
    setSecondaryTabValue(newValue);
    if (newValue === 0) {
      AnalyticsHandler.track('Profile_myResume_aboutMe_TabClick');
    } else if (newValue === 1) {
      AnalyticsHandler.track('Profile_myResume_education_TabClick');
    } else if (newValue === 2) {
      AnalyticsHandler.track('Profile_myResume_experience_TabClick');
    }
  };

  const showSaveCancelBtns = () => {
    if (document.getElementById('saveAndCancelBtns')) {
      document.getElementById('saveAndCancelBtns').style.display = "flex";
    }
  }

  const hideSaveCancelBtns = () => {
    if (document.getElementById('saveAndCancelBtns')) {
      document.getElementById('saveAndCancelBtns').style.display = "none";
    }
  }

  const clearLocalStorageAndSignOutFE = () => {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('jobseeker_id');
    localStorage.removeItem('profile_completed');
    setIsSignedIn(false);
    enableTabChange();
    setTimeout(() => { // to reset the form values if session expires while form filling
      window.location.reload();
    }, 1000)
  }

  const deleteAccount = () => {
    AnalyticsHandler.track('Profile_deleteAccount_2_BtnClick');
    let data = { jobseeker_id: jobseekerID }
    ApiHandler.deleteAccount(data, (response) => {
      if (response.status === 200) {
        AnalyticsHandler.track('Profile_deleteAccount_Success');
        handleLogout("onDeleteAccount");
      } else if (response.response) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        handleSnackBar("Something went wrong. Please try again.", "error");
      }
    })
  }

  const handleLogout = (caseParam) => {
    ApiHandler.logout(function (res) {
      if (res.status === 200) {
        AnalyticsHandler.track('Profile_logout_Success');
        AnalyticsHandler.reset();
        clearLocalStorageAndSignOutFE();
        if (caseParam === "onDeleteAccount") {
          handleDialogClose();
          // handleSnackBar("Your account has been deleted successfully.");
          handleSnackBar("Done");
          enableTabChange();
          //reset edu vars
          setInstituteNameArray([]);
          setQualTypeArray([]);
          setCourseNameArray([]);
          setSpecializationNameArray([]);
          setGraduationYearInputArray([]);
          //reset exp vars
          setJobRoleArray([]);
          setExpType([]);
          setJobDesc([]);
          setIsCurrentJob([]);
          setJobStartDate([]);
          setJobEndDate([]);
          setIsUnpaid([]);
          setFixedSalaryInput([]);
          setVariableSalaryInput([]);
          setVariableSalaryUnitInput([]);
          setCompanyNameArray([]);
          setIsWFH([]);
          setWorkLocations([]);
          setWorkLatLongs([]);
          setEnvRatings([]);
          setGrowthRatings([]);
          setLearningRatings([]);
          setFacilityRatings([]);
          setLocationRatings([]);
          setManagerRatings([]);
          setSalaryRatings([]);
          setReviewTags([]);
          setIsReviewPublic([]);
          setGoodReviews([]);
          setBadReviews([]);
          setAudioReviews([]);
          setVideoReviews([]);
          setOverallRatings([]);
          setAvgRatings([]);
          return
        }
        handleConfDialogClose();
        handleSnackBar("You have logged out successfully.")
      } else if (res.response) { // on error
        if (res.response.data.message === "Session Expire") {
          clearLocalStorageAndSignOutFE();
          handleSnackBar("Your Session expired. Please login again.", "error")
        } else {
          handleSnackBar("Something went wrong. Please try again.", "error")
        }
        if (process.env.REACT_APP_NODE_ENV === "development") { // for development env do the logout without auth on click
          clearLocalStorageAndSignOutFE();
          if (caseParam === "onDeleteAccount") {
            handleDialogClose();
            handleSnackBar("Your account has been deleted successfully.");
            return
          } else if (caseParam === "logoutOnSessionExpire") {
            handleSnackBar("Your Session expired. Please login again.", "error");
            return
          }
          handleConfDialogClose();
          handleSnackBar("You have logged out successfully.")
        }
      }
    })
  }

  const [isAutocompletePageOpen, setIsAutocompletePageOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [showAC, setShowAC] = React.useState({ role: false, company: false, institute: false, degree: false, stream: false })

  //open full page with autocomplete
  const handleOpenFullPageAC = (key) => {
    let objTemp = { ...showAC, [key]: true }
    setShowAC({ ...objTemp });
    setIsAutocompletePageOpen(true);
  }

  const handleCloseFullPageAC = () => {
    setIsAutocompletePageOpen(false);
    AutocompletePageReset();
  }

  const AutocompletePageReset = () => {
    setShowAC({ role: false, company: false, institute: false, degree: false, stream: false })
  }

  //on Click of Cancel Button
  const doCancel = (e) => {
    if (isAboutMe) {
      setFirstName(profileObj && profileObj.user_first_name ? profileObj.user_first_name : "");
      setLastName(profileObj && profileObj.user_last_name ? profileObj.user_last_name : "");
      setDob(profileObj && profileObj.user_dob ? profileObj.user_dob : "");
      setGender(profileObj && profileObj.user_gender ? profileObj.user_gender : null);
      setLocationValue(profileObj && profileObj.user_city ? profileObj.user_city : "");
      setMedhaAlumni(profileObj && profileObj.user_medha_alumni_status ? profileObj.user_medha_alumni_status : false);
      setStudentID(profileObj && profileObj.user_medha_student_id ? profileObj.user_medha_student_id : "")
      setProfilePhoto(profileObj && profileObj.user_image_url ? profileObj.user_image_url : "");
      setEmail(profileObj && profileObj.user_email ? profileObj.user_email : "");

      setIsSaveBtnDisabled(true);
      hideSaveCancelBtns();
    }
    else if (isEducation) {
      cancelAddEditEdu();
    }
    else if (isSkills) {
      cancelAddEditSkill();
    }

    else if (isExperience) {
      cancelAddEditExp();
    }
    else if (isPreferences) {
      setPrefJobType(prefObj && prefObj.job_type ? prefObj.job_type : "")
      setPrefJobLocation(prefObj && prefObj.job_location ? prefObj.job_location : "")
      setPrefWFH(prefObj && prefObj.isWorkFromHome ? prefObj.isWorkFromHome : false)
      setprefQualName(prefObj && prefObj.qual_name ? prefObj.qual_name : "")
      setPrefSalary(prefObj && prefObj.salary ? prefObj.salary : "")

      setIsSaveBtnDisabled(true);
      hideSaveCancelBtns();
    }
  }

  //exp radio starts
  const [experienceRadio, setExperienceRadio] = useState('experienced');

  const handleExperienceRadioChange = (event, newValue) => {
    setExperienceRadio(newValue);
    if (newValue === "fresher") {
      setIsSaveBtnDisabled(false);
      showSaveCancelBtns();
    } else if (newValue === "experienced") {
      hideSaveCancelBtns();
    }
  }
  // exp radio ends

  //exp datepicker
  const changeDate = (date, dateArray, setDateArray, index) => {
    let temp_array = [];
    let newItem = '';
    if (dateArray != []) {
      temp_array = [...dateArray];
      //console.log(date.getMonth())
      newItem = moment(date).format('DD-MMM-YYYY');
      let sliced = newItem.slice(2);
      newItem = newItem.replace(newItem, '01' + sliced)
      //console.log(newItem + " " + sliced)
      //temp_array[index] = moment(date).format('DD-MMM-YYYY');
      temp_array[index] = newItem
      setDateArray(temp_array)
    }
  }

  //add ratings
  const handleAddRatings = (index) => {
    let _val = [], i = 0;
    for (; i < numberExp; i++) {
      if (i === index) {
        _val[i] = true;
      } else {
        _val[i] = false;
      }
    }

    if (numberExp !== 0) {
      setIsRatingsOpen([..._val])
    } else {
      setIsRatingsOpen([true])
    }

  }

  const closeRatingsModal = () => {
    let _val = [], i = 0;
    for (; i < numberExp; i++) {
      _val[i] = false;
    }
    setIsRatingsOpen([..._val]);
  }

  const changeArrayItem = (array, setArray, newValue, index) => {
    let temp_array = []
    temp_array = [...array];
    temp_array[index] = newValue;
    setArray(temp_array)
  }

  const delArrayItem1OnLoop1 = (array, setArray, initVal, index) => {
    let arrayTmp = [...array, array[index] = initVal];
    index === 0 && !array[0] && arrayTmp.splice(0, 1);//to remove the first array item of falsy value on first iteration which gets added in the case of 0 exp
    setArray(arrayTmp);
  }

  const changeEnvRatings = (newValue, index) => {
    let temp_array = []
    // if (envRatings != []) {
    temp_array = [...envRatings];
    temp_array[index] = newValue;
    setEnvRatings(temp_array)
    // }
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeGrowthRatings = (newValue, index) => {
    changeArrayItem(growthRatings, setGrowthRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeLearningRatings = (newValue, index) => {
    changeArrayItem(learningRatings, setLearningRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeFacilityRatings = (newValue, index) => {
    changeArrayItem(facilityRatings, setFacilityRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeLocationRatings = (newValue, index) => {
    changeArrayItem(locationRatings, setLocationRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeManagerRatings = (newValue, index) => {
    changeArrayItem(managerRatings, setManagerRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeSalaryRatings = (newValue, index) => {
    changeArrayItem(salaryRatings, setSalaryRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeIsReviewPublic = (newValue, index) => {
    changeArrayItem(isReviewPublic, setIsReviewPublic, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeGoodReviews = (newValue, index) => {
    changeArrayItem(goodReviews, setGoodReviews, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeBadReviews = (newValue, index) => {
    changeArrayItem(badReviews, setBadReviews, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeAudioReviews = (newValue, index) => {
    changeArrayItem(audioReviews, setAudioReviews, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeVideoReviews = (newValue, index) => {
    changeArrayItem(videoReviews, setVideoReviews, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }
  const changeOverallRatings = (newValue, index) => {
    changeArrayItem(overallRatings, setOverallRatings, newValue, index);
    changeArrayItem(avgRatings, setAvgRatings, newValue, index);
    setIsSaveBtnDisabled(false);
    showSaveCancelBtns();
  }

  const scrollToTargetAdjusted = (targetElem) => {
    var element = document.getElementById(targetElem);
    var headerOffset = 90;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  //to update user's last seen at timestamp on load and at the time of login
  React.useEffect(() => {
    if (isSignedIn) {
      updateLastSeenAt(); //to update user's last seen at timestamp
    }
  }, [isSignedIn])

  // GetExperience
  React.useEffect(() => {
    //console.log(jobseekerID)
    if (!isSignedIn) return
    setIsExpLoading(true);
    let payload = {
      jobseeker_id: jobseekerID
    }
    ApiHandler.getExperience(payload, response => {
      if (response.status == 200) {
        //console.log(response.data)
        //setExperienceArray(response.data);
        setNumberExp(response.data.length);
        setRealNumberExp(response.data.length)

        let expArrayTemp = response.data;
        if (response.data.length === 0) {
          // setShowOnePageRatings(false); // if no exp then show one by one ratings questionnaire
          expArrayTemp = [
            {
              "job_experience_or_internship_status": null,
              "user_post": "",
              "experience_type": null,
              "unpaid_work": null,
              "fixed_salary": null,
              "variable_salary": null,
              "environment_rating": null,
              "growth_rating": null,
              "learning_rating": null,
              "facility_rating": null,
              "company_location_rating": null,
              "manager_rating": null,
              "ratings": {
                // "environment_rating": null,
                // "growth_rating": null,
                // "learning_rating": null,
                // "facility_rating": null,
                // "company_location_rating": null,
                // "manager_rating": null,
                "avg_rating": null
              },
              "reviews": {
                "good_things": null,
                "bad_things": null
              }
            }
          ]
        } else {
          // let i;
          // for (i = 0; i < response.data.length; i++) {
          //   if (response.data[i].overall_rating) { // if overall rating is present in even a single exp then show single page for ratings
          //     setShowOnePageRatings(true);
          //     break;
          //   }
          // }
        }
        //console.log(expArrayTemp)
        expArrayTemp.map((item, index) => {
          //set the exp radio btn state, form state and action btn state
          if (expArrayTemp.length === 1 && item.job_experience_or_internship_status === null) {
            //case1: new user with no profile > experienced, form open and save n add new
            setExperienceRadio('experienced');
            setIsExpOpen([true]);
            setAddExpBtnAction("save and add new");
          } else if (expArrayTemp.length > 0 && index === 0 && item.job_experience_or_internship_status === false) {
            // case2: user had marked as fresher in their profile > fresher, form open and save n add new
            setExperienceRadio('fresher');
            setIsExpOpen([true]);
            setAddExpBtnAction("save and add new");

          } else {
            //case3: user had marked as experienced in their profile > experienced, form close and add new
            setExperienceRadio('experienced');
            setShowExpRadio(false); // hide exp/fresher radio btns if the user had marked themselves as experienced earlier
            setIsExpOpen([...isExpOpen, isExpOpen[index] = false]);
            setAddExpBtnAction("add new");
          }

          let expIdTmp = item && item.experience_id && item.job_experience_or_internship_status !== false ? item.experience_id : null; // do not add if fresher exp id is present
          // let expIdTmp1 = [...expIdArray, expIdArray[index] = expIdTmp]; console.log(expIdTmp1)
          // index === 0 && expIdArray[0] === null && expIdTmp1.splice(0, 1); console.log(expIdTmp1)
          // setExpIdArray(expIdTmp1);
          delArrayItem1OnLoop1(expIdArray, setExpIdArray, expIdTmp, index);

          let jobRoleTmp = item && item.user_post && item.user_post !== 'Fresher' ? item.user_post : "";
          setJobRoleArray([...jobRoleArray, jobRoleArray[index] = jobRoleTmp]);

          let expTypeTmp = item && item.experience_type ? item.experience_type : "select";
          setExpType([...expType, expType[index] = expTypeTmp]);

          let jobDescTmp = item && item.job_desc ? item.job_desc : "";
          setJobDesc([...jobDesc, jobDesc[index] = jobDescTmp]);

          let currentJob = item && item.company_ending_date && item.company_ending_date === 'Present' ? true : false
          delArrayItem1OnLoop1(isCurrentJob, setIsCurrentJob, currentJob, index);

          let isUnpaidVar = item && item.unpaid_work ? item.unpaid_work : false;
          delArrayItem1OnLoop1(isUnpaid, setIsUnpaid, isUnpaidVar, index);

          let jobStartDateTmp = item && item.company_starting_date ? item.company_starting_date : null;
          delArrayItem1OnLoop1(jobStartDate, setJobStartDate, jobStartDateTmp, index);

          let jobEndDateTmp = item && item.company_ending_date ? item.company_ending_date : null;
          delArrayItem1OnLoop1(jobEndDate, setJobEndDate, jobEndDateTmp, index);

          let fixedSalaryInputTmp = item && item.fixed_salary ? item.fixed_salary : "";
          setFixedSalaryInput([...fixedSalaryInput, fixedSalaryInput[index] = fixedSalaryInputTmp]);

          let variableSalaryInputTmp = item && item.variable_salary ? item.variable_salary : "";
          setVariableSalaryInput([...variableSalaryInput, variableSalaryInput[index] = variableSalaryInputTmp]);

          let variableSalaryInputUnitTmp = item && item.variable_salary_unit ? item.variable_salary_unit : "";
          setVariableSalaryUnitInput([...variableSalaryUnitInput, variableSalaryUnitInput[index] = variableSalaryInputUnitTmp]);

          let companyNameTmp = item && item.company_name ? item.company_name : "";
          setCompanyNameArray([...companyNameArray, companyNameArray[index] = companyNameTmp]);

          let wfhTmp = item && item.isWorkFromHome ? item.isWorkFromHome : false;
          delArrayItem1OnLoop1(isWFH, setIsWFH, wfhTmp, index);

          let workLocationTmp = item && item.city ? item.city : "";
          setWorkLocations([...workLocations, workLocations[index] = workLocationTmp]);

          let workLatLongsTmp = item && item.latitude && item.longitude ? { lat: item.latitude, long: item.longitude } : "";
          setWorkLatLongs([...workLatLongs, workLatLongs[index] = workLatLongsTmp]);

          let avgRatingsTmp = item && item.ratings.avg_rating ? item.ratings.avg_rating : null;
          setAvgRatings([...avgRatings, avgRatings[index] = avgRatingsTmp]);

          let envRatingsTmp = item && item.environment_rating ? item.environment_rating : null;
          setEnvRatings([...envRatings, envRatings[index] = envRatingsTmp]);

          let growthRatingsTmp = item && item.growth_rating ? item.growth_rating : null;
          setGrowthRatings([...growthRatings, growthRatings[index] = growthRatingsTmp]);

          let facilityRatingsTmp = item && item.facility_rating ? item.facility_rating : null;
          setFacilityRatings([...facilityRatings, facilityRatings[index] = facilityRatingsTmp]);

          let locationRatingsTmp = item && item.company_location_rating ? item.company_location_rating : null;
          setLocationRatings([...locationRatings, locationRatings[index] = locationRatingsTmp]);

          let learningRatingsTmp = item && item.learning_rating ? item.learning_rating : null;
          setLearningRatings([...learningRatings, learningRatings[index] = learningRatingsTmp]);

          let managerRatingsTmp = item && item.manager_rating ? item.manager_rating : null;
          setManagerRatings([...managerRatings, managerRatings[index] = managerRatingsTmp]);

          let salaryRatingsTmp = item && item.salary_rating ? item.salary_rating : null;
          setSalaryRatings([...salaryRatings, salaryRatings[index] = salaryRatingsTmp]);

          let roleReviewsTmp = item && item.reviews.job_role ? item.reviews.job_role : null;
          setRoleReviews([...roleReviews, roleReviews[index] = roleReviewsTmp]);

          let goodReviewsTmp = item && item.reviews.good_things ? item.reviews.good_things : null;
          setGoodReviews([...goodReviews, goodReviews[index] = goodReviewsTmp]);

          let badReviewsTmp = item && item.reviews.bad_things ? item.reviews.bad_things : null;
          setBadReviews([...badReviews, badReviews[index] = badReviewsTmp]);

          let audioReviewsTmp = item && item.audio_review ? item.audio_review : null;
          setAudioReviews([...audioReviews, audioReviews[index] = audioReviewsTmp]);

          let videoReviewsTmp = item && item.video_review ? item.video_review : null;
          setVideoReviews([...videoReviews, videoReviews[index] = videoReviewsTmp]);

          let overallRatingsTmp = item && item.overall_rating ? item.overall_rating : null;
          setOverallRatings([...overallRatings, overallRatings[index] = overallRatingsTmp]);

          let reviewTagsTmp = item && item.review_tag ? item.review_tag : null;
          if (reviewTagsTmp && reviewTagsTmp.length !== 0) setReviewTags([...reviewTags, reviewTags[index] = reviewTagsTmp]);
          if (reviewTagsTmp && reviewTagsTmp.length === 0) setReviewTags([...reviewTags, reviewTags[index] = []]);


          let isReviewPublicTmp = item && item.public_review ? item.public_review : false;
          delArrayItem1OnLoop1(isReviewPublic, setIsReviewPublic, isReviewPublicTmp, index);
        })

        hideSaveCancelBtns();
        setIsExpLoading(false);
      } else if (response.response) {
        if (response.response.data && response.response.data.message && response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        console.log("error in getting a successful response")
      }
    })
  }, [isSignedIn])

  // React.useState(() => {
  //   console.log(isWFH)
  // }, [isWFH])

  // Add/Edit Experience
  const errValidationHelper = (errObj, key, index, id) => {
    let objTemp = { ...errObj, [key]: true }
    setIsErrorExp({ ...objTemp });
    document.querySelector(id + (index)).scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  const saveExpHelper = (index, saveAndAddNew) => {
    //console.log(expIdArray[index])
    if (!index) { // when save only btn is clicked where index is not available
      //console.log(activeExpIndex)
      if (isEditModeOn) { // case of save edits
        var index = activeExpIndex;
      } else { //case of save new exp
        var index = numberExp ? numberExp - 1 : 0;
      }
    }
    let payload = {
      jobseeker_id: jobseekerID,
      job_experience_or_internship_status: true,
      job_desc: jobDesc[index],
      company_name: companyNameArray[index],
      isWorkFromHome: isWFH[index],
      city: workLocations[index],
      latitude: workLatLongs[index] ? workLatLongs[index].lat : null,
      longitude: workLatLongs[index] ? workLatLongs[index].long : null,
      user_post: jobRoleArray[index],
      company_starting_date: jobStartDate[index],
      company_ending_date: isCurrentJob[index] ? "Present" : jobEndDate[index],
      experience_type: expType[index],
      unpaid_work: isUnpaid[index],
      fixed_salary: isUnpaid[index] ? "" : fixedSalaryInput[index],
      variable_salary: isUnpaid[index] ? "" : variableSalaryInput[index],
      variable_salary_unit: isUnpaid[index] ? "" : variableSalaryUnitInput[index],
      environment_rating: envRatings[index],
      growth_rating: growthRatings[index],
      learning_rating: learningRatings[index],
      facility_rating: facilityRatings[index],
      company_location_rating: locationRatings[index],
      manager_rating: managerRatings[index],
      salary_rating: salaryRatings[index],
      review_tag: reviewTags[index] ? reviewTags[index] : [],
      public_review: isReviewPublic[index],
      good_things: goodReviews[index],
      bad_things: badReviews[index],
      audio_review: audioReviews[index],
      video_review: videoReviews[index],
      overall_rating: overallRatings[index],
    }

    //console.log(reviewTags[index])

    let sd = jobStartDate[index] ? moment(jobStartDate[index]) : null;
    let ed = jobEndDate[index] ? moment(jobEndDate[index]) : null;

    if (!payload.user_post) {
      errValidationHelper(isErrorExp, 'role', index, "#designation_id");
      // scrollToTargetAdjusted("designation_id" + (numberExp - 1))
    } else if (!payload.company_name) {
      errValidationHelper(isErrorExp, 'company', index, "#companyName_id");
    } else if (!payload.experience_type || payload.experience_type === "select") {
      errValidationHelper(isErrorExp, 'expType', index, "#employmentType_id");
    } else if (!payload.company_starting_date) {
      errValidationHelper(isErrorExp, 'startDt', index, "#startDate_id");
    } else if (!payload.company_ending_date || (!isCurrentJob[index] && payload.company_ending_date === 'Present')) {
      errValidationHelper(isErrorExp, 'endDt', index, "#endDate_id");
    } else if (!isCurrentJob[index] && (sd > ed)) {
      errValidationHelper(isErrorExp, 'endDtGreater', index, "#endDate_id");
    }
    // else if (!payload.city) {
    //   errValidationHelper(isErrorExp, 'location', index, "#workLocation_id");
    // }
    // else if (!avgRatings[numberExp - 1]){ 
    //   let objTemp = {...isErrorExp}
    //   objTemp.ratings = true;
    //   setIsErrorExp({...objTemp})
    // } 
    else {
      setIsSaveBtnDisabled(true);
      setIsBtnBusy(true);
      if (isEditModeOn) { // to save edits
        //console.log("edit mode edit request")
        payload['experience_id'] = expIdArray[index];
        ApiHandler.editExp(payload, res => {
          // console.log("edit exp response: " + res)
          // console.log(res.status)
          setIsBtnBusy(false);

          if (res.status === 200) {
            if (index === 0) {
              setIsExpOpen([false])
            } else {
              changeArrayItem(isExpOpen, setIsExpOpen, false, index);
            }
            setActiveExpIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
            setIsEditModeOn(false);

            // handleSnackBar("Experience edited successfully.");
            handleSnackBar("Done")
            hideSaveCancelBtns();
            setIsSaveBtnDisabled(true);

          } else if (res.response) {
            if (res.response.status === 400) {
              if (res.response.data.message === "Session Expire") {
                handleLogout("logoutOnSessionExpire");
              }
            } else if (res.response.status === 500) {
              switch (res.response.data.message) {
                case 'Review not updated':
                  console.log("Review not updated");
                  break;
                case 'Rating not updated':
                  console.log("Rating not updated");
                  break;
                case 'Some error occured':
                  console.log("Some error occured");
                default:
                  console.log("Some error occured");
              }
              handleSnackBar("Something went wrong. Please try again.", "error")
            } else {
              handleSnackBar("Something went wrong. Please try again.", "error")
            }

          }
          // else if (res.status === 400) {
          //   handleLogout("logoutOnSessionExpire");
          // }

        })

      } else { // to save new exp 
        ApiHandler.addExp(payload, response => {
          //console.log("add exp response" + response)
          //console.log(response)

          setIsBtnBusy(false);

          if (response.status == 200) {
            let expID = response.data.experience_id;
            setExpIdArray([...expIdArray, expIdArray[index] = expID]); // add the exp id to the array so that delete and edit APIs have a reference to operate
            if (isUnpaid[index]) {
              fixedSalaryInput[index] = "";
              variableSalaryInput[index] = "";
              variableSalaryUnitInput[index] = "";
            }
            if (saveAndAddNew) {
              if (numberExp !== 0) {
                setNumberExp(prev => prev + 1);
              } else {
                setNumberExp(2) // for the case of numberExp === 0, incremented by 2 to adjust the numberExp
                setShowExpRadio(false);
              }
            } else { //only save exp
              if (index === 0) {
                setIsExpOpen([false])
              } else {
                changeArrayItem(isExpOpen, setIsExpOpen, false, index);
              }
              if (numberExp >= 0) setShowExpRadio(false); // hide exp radio if first exp gets added
              setActiveExpIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
              setIsEditModeOn(false);
            }

            // handleSnackBar("Experience added successfully.")
            handleSnackBar("Done")
            setIsSaveBtnDisabled(true);

            setRealNumberExp(prev => prev + 1)

          } else if (response.response) {
            if (response.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Something went wrong. Please try again.", "error")
          }
        })
      }
    }
  }

  const saveFresherExpHelper = () => {
    let payload = {
      jobseeker_id: jobseekerID,
      job_experience_or_internship_status: false,
    }

    ApiHandler.addExp(payload, response => {
      if (response.status == 200) {
        // handleSnackBar("We have added you as a Fresher successfully.");
        handleSnackBar("Done")
        hideSaveCancelBtns();
        setIsSaveBtnDisabled(true);
      } else if (response.response) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        } else if (response.response.data.message === "multiple entries of Fresher is not allowed") {
          handleSnackBar("Done");
          hideSaveCancelBtns();
          setIsSaveBtnDisabled(true);
        }
      } else {
        handleSnackBar("Something went wrong. Please try again.", "error");
      }
    })
  }

  // on Add New btn click
  const handleAddExpForm = () => {
    if (numberExp !== 0) {
      setNumberExp(prev => prev + 1);
    } else {
      setNumberExp(2) // for the case of numberExp === 0, incremented by 2 to adjust the numberExp
    }
    setAddExpBtnAction("save and add new");
    setShowOnePageRatings(false);
    setWorkLocationType(""); // to reset work location type
    //setIsEditDeleteDisabled(true);
  }

  // on save and add btn click
  const handleSaveAndAddExp = (index) => {
    let saveAndAddNew = "saveAndAddNew";
    saveExpHelper(index, saveAndAddNew);
    setShowOnePageRatings(false);
    setWorkLocationType(""); // to reset work location type
  }

  // on save btn click
  const handleSaveExp = () => {
    if (experienceRadio === "experienced") {
      saveExpHelper();
      // setActiveExpIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
      //setIsEditModeOn(false);
    } else {
      saveFresherExpHelper();
    }
  }

  const expErrorReset = () => {
    setIsErrorExp({ role: false, expType: false, startDt: false, endDt: false, company: false, location: false, ratings: false })
  }

  const deletArrayItem = (array, setArray, index) => {
    let arr = [...array];
    arr.splice(index, 1);
    setArray(arr);
  }

  // delete exp
  const deleteExp = (index) => {
    let payload = {
      jobseeker_id: jobseekerID,
      experience_id: expIdArray[index]
    }
    ApiHandler.deleteExp(payload, response => {
      if (response.status == 200) {
        expIdArray.splice(index, 1);
        jobRoleArray.splice(index, 1);
        expType.splice(index, 1);
        jobDesc.splice(index, 1);
        isCurrentJob.splice(index, 1);
        jobStartDate.splice(index, 1);
        jobEndDate.splice(index, 1);
        isUnpaid.splice(index, 1);
        fixedSalaryInput.splice(index, 1);
        variableSalaryInput.splice(index, 1);
        variableSalaryUnitInput.splice(index, 1);
        companyNameArray.splice(index, 1);
        isWFH.splice(index, 1);
        workLocations.splice(index, 1);
        workLatLongs.splice(index, 1);
        avgRatings.splice(index, 1);
        envRatings.splice(index, 1);
        growthRatings.splice(index, 1);
        learningRatings.splice(index, 1);
        facilityRatings.splice(index, 1);
        locationRatings.splice(index, 1);
        managerRatings.splice(index, 1);
        salaryRatings.splice(index, 1);
        goodReviews.splice(index, 1);
        badReviews.splice(index, 1);
        audioReviews.splice(index, 1);
        videoReviews.splice(index, 1);
        overallRatings.splice(index, 1);
        if (numberExp !== 0) {
          setNumberExp(prev => prev - 1);
        } else {
          setNumberExp(1)
        }
        if (numberExp === 1 || numberExp === 0) { // if only 1 exp is there
          setShowExpRadio(true);
        }

        // handleSnackBar("Experience deleted successfully.")
        handleSnackBar("Done")
        setIsSaveBtnDisabled(true);

        setRealNumberExp(prev => prev - 1)
        // if (index === 0) { // if only 1 exp is there
        //   setShowExpRadio(true);
        // }
      } else if (response.response) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        handleSnackBar("Experience could not be deleted. Please try again.", "error")
      }
    })
  }

  // copy of delete function to cancel addition/editing of exp
  const cancelAddEditExp = () => {
    let index = numberExp !== 0 ? numberExp - 1 : 0;
    if (isEditModeOn) { // for edit exp use the activeExpIndex as index
      index = activeExpIndex;
      // revert to prev vals
      jobRoleArray[index] && changeArrayItem(jobRoleArray, setJobRoleArray, prevExpFormVals.role, index);
      expType[index] && changeArrayItem(expType, setExpType, prevExpFormVals.expType, index);
      isCurrentJob[index] && changeArrayItem(isCurrentJob, setIsCurrentJob, prevExpFormVals.isCurrentJob, index);
      jobStartDate[index] && changeArrayItem(jobStartDate, setJobStartDate, prevExpFormVals.jobStartDate, index);
      jobEndDate[index] && changeArrayItem(jobEndDate, setJobEndDate, prevExpFormVals.jobEndDate, index);
      isUnpaid[index] && changeArrayItem(isUnpaid, setIsUnpaid, prevExpFormVals.isUnpaid, index);
      fixedSalaryInput[index] && changeArrayItem(fixedSalaryInput, setFixedSalaryInput, prevExpFormVals.fixedSalaryInput, index);
      variableSalaryInput[index] && changeArrayItem(variableSalaryInput, setVariableSalaryInput, prevExpFormVals.variableSalaryInput, index);
      variableSalaryUnitInput[index] && changeArrayItem(variableSalaryUnitInput, setVariableSalaryUnitInput, prevExpFormVals.variableSalaryUnitInput, index);
      companyNameArray[index] && changeArrayItem(companyNameArray, setCompanyNameArray, prevExpFormVals.companyName, index);
      isWFH[index] && changeArrayItem(isWFH, setIsWFH, prevExpFormVals.isWFH, index);
      workLocations[index] && changeArrayItem(workLocations, setWorkLocations, prevExpFormVals.workLocation, index);
      workLatLongs[index] && changeArrayItem(workLatLongs, setWorkLatLongs, prevExpFormVals.workLatLong, index);

      envRatings[index] && changeArrayItem(envRatings, setEnvRatings, prevExpFormVals.envRating, index);
      growthRatings[index] && changeArrayItem(growthRatings, setGrowthRatings, prevExpFormVals.growthRating, index);
      learningRatings[index] && changeArrayItem(learningRatings, setLearningRatings, prevExpFormVals.learningRating, index);
      facilityRatings[index] && changeArrayItem(facilityRatings, setFacilityRatings, prevExpFormVals.facilityRating, index);
      locationRatings[index] && changeArrayItem(locationRatings, setLocationRatings, prevExpFormVals.locationRating, index);
      managerRatings[index] && changeArrayItem(managerRatings, setManagerRatings, prevExpFormVals.managerRating, index);
      salaryRatings[index] && changeArrayItem(salaryRatings, setSalaryRatings, prevExpFormVals.salaryRating, index);

      reviewTags[index] && changeArrayItem(reviewTags, setReviewTags, prevExpFormVals.reviewTags, index);
      isReviewPublic[index] && changeArrayItem(isReviewPublic, setIsReviewPublic, prevExpFormVals.isReviewPublic, index);
      goodReviews[index] && changeArrayItem(goodReviews, setGoodReviews, prevExpFormVals.goodReview, index);
      badReviews[index] && changeArrayItem(badReviews, setBadReviews, prevExpFormVals.badReview, index);
      audioReviews[index] && changeArrayItem(audioReviews, setAudioReviews, prevExpFormVals.audioReview, index);
      videoReviews[index] && changeArrayItem(videoReviews, setVideoReviews, prevExpFormVals.videoReview, index);

      overallRatings[index] && changeArrayItem(overallRatings, setOverallRatings, prevExpFormVals.overallRating, index);

      changeArrayItem(isExpOpen, setIsExpOpen, false, index);
      setActiveExpIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
      setIsEditModeOn(false);

      setIsSaveBtnDisabled(true);
      hideSaveCancelBtns();
      return
    }
    // for cancelling adding of exp

    if (experienceRadio === "fresher") {// to cancel fresher experience and activate experienced radio
      setExperienceRadio("experienced");
    }

    jobRoleArray[index] && deletArrayItem(jobRoleArray, setJobRoleArray, index);
    jobStartDate[index] && deletArrayItem(jobStartDate, setJobStartDate, index);
    jobEndDate[index] && deletArrayItem(jobEndDate, setJobEndDate, index);
    companyNameArray[index] && deletArrayItem(companyNameArray, setCompanyNameArray, index);
    isWFH[index] && deletArrayItem(isWFH, setIsWFH, index);
    workLocations[index] && deletArrayItem(workLocations, setWorkLocations, index);
    workLatLongs[index] && deletArrayItem(workLatLongs, setWorkLatLongs, index);
    avgRatings[index] && deletArrayItem(avgRatings, setAvgRatings, index);
    envRatings[index] && deletArrayItem(envRatings, setEnvRatings, index);
    growthRatings[index] && deletArrayItem(growthRatings, setGrowthRatings, index);
    facilityRatings[index] && deletArrayItem(facilityRatings, setFacilityRatings, index);
    learningRatings[index] && deletArrayItem(learningRatings, setLearningRatings, index);
    locationRatings[index] && deletArrayItem(locationRatings, setLocationRatings, index);
    managerRatings[index] && deletArrayItem(managerRatings, setManagerRatings, index);
    salaryRatings[index] && deletArrayItem(salaryRatings, setSalaryRatings, index);
    reviewTags[index] && deletArrayItem(reviewTags, setReviewTags, index);
    isReviewPublic[index] && deletArrayItem(isReviewPublic, setIsReviewPublic, index);
    goodReviews[index] && deletArrayItem(goodReviews, setGoodReviews, index);
    badReviews[index] && deletArrayItem(badReviews, setBadReviews, index);
    audioReviews[index] && deletArrayItem(audioReviews, setAudioReviews, index);
    videoReviews[index] && deletArrayItem(videoReviews, setVideoReviews, index);
    overallRatings[index] && deletArrayItem(overallRatings, setOverallRatings, index);

    if (index !== 0) {
      fixedSalaryInput[index] && deletArrayItem(fixedSalaryInput, setFixedSalaryInput, index);
      variableSalaryInput[index] && deletArrayItem(variableSalaryInput, setVariableSalaryInput, index);
      variableSalaryUnitInput[index] && deletArrayItem(variableSalaryUnitInput, setVariableSalaryUnitInput, index);
      expType[index] && deletArrayItem(jobRoleArray, setJobRoleArray, index);
      isCurrentJob[index] && deletArrayItem(isCurrentJob, setIsCurrentJob, index);
      isUnpaid[index] && deletArrayItem(isUnpaid, setIsUnpaid, index);
    } else { // initialize the values of the below fields if there are no exps and the form is open
      fixedSalaryInput[index] && setFixedSalaryInput([""]);
      variableSalaryInput[index] && setVariableSalaryInput([""]);
      variableSalaryUnitInput[index] && setVariableSalaryUnitInput([""]);
      expType[index] && setExpType(["select"]);
      isCurrentJob[index] && setIsCurrentJob([false]);
      isUnpaid[index] && setIsUnpaid([false]);
    }
    if (numberExp !== 0) {
      setNumberExp(prev => prev - 1);
    }

    if (numberExp - 1 === 0) { // if only 1 exp is there
      setShowExpRadio(true);
    }
    setIsSaveBtnDisabled(true);
    hideSaveCancelBtns();

  }

  // for comparing prev numberExp
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNumberExp = usePrevious(numberExp);
  const prevIsExpOpen = usePrevious(isExpOpen);

  //for adding experience: whenever numberExp is changed the expCountArr & isExpOpen are updated
  useEffect(() => {
    //console.log(prevNumberExp + "-" + numberExp)
    //update exp array for the purpose of mapping experiences to display them in the ui
    if (numberExp === 0) {
      //by default exp1 form will be open in case of 0 experience. So exp[0] obj to be added
      setExpCountArr([{ [0]: 0 }]);
    } else {
      let k = 0; let _expCountArr = [];
      for (; k < numberExp; k++) {
        _expCountArr[k] = { [k]: k }
      }
      setExpCountArr([..._expCountArr]);
    }

    // form open/collapse interaction based on delete/add exp 
    if (prevNumberExp && prevNumberExp > numberExp) { // in case of delete exp
      let _val = [], i = 0;
      if (numberExp === 0) { // when last exp is deleted, open the form
        _val[i] = true;
      } else { // else collapse all forms
        for (; i < numberExp; i++) {
          _val[i] = false;
        }
      }
      setIsExpOpen([..._val])
    } else {  // Open the new exp form and collapse others
      let _val = [], i = 0;
      for (; i < numberExp; i++) {
        if (i === numberExp - 1) { // last one
          _val[i] = true;
        } else { // other than last
          _val[i] = false;
        }
      }
      setIsExpOpen([..._val]);
    }

  }, [numberExp])

  //show hide save/cancel btns & edit/delete btns, change add btn text based on exp form open/close
  useEffect(() => {
    //console.log(isExpOpen);
    // console.log(prevIsExpOpen && prevIsExpOpen.length)
    let isSomeExpOpen = isExpOpen.some((item) => {
      return item === true;
    })
    if (isSomeExpOpen === true) { //do somethnig when a form is open
      //console.log(numberExp)
      if (numberExp !== 0 && isExperience) {
        showSaveCancelBtns();
      } else if (numberExp === 0 && isExperience) {
        hideSaveCancelBtns();
      }
      setIsEditDeleteDisabled(true);
      if (prevIsExpOpen && prevIsExpOpen.length < isExpOpen.length) {
        setAddExpBtnAction("save and add new");
      } else if (prevIsExpOpen && prevIsExpOpen.length === isExpOpen.length && showExpRadio === false) {
        setAddExpBtnAction("save edits and add new");
      } else if (prevIsExpOpen && prevIsExpOpen.length === isExpOpen.length && showExpRadio === true) {
        setAddExpBtnAction("save and add new");
      }
      // animate the opened form to top
      let newSection = document.querySelector("#exp_id" + (numberExp - 1));
      if (newSection) {
        newSection.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }


    } else { // do reverse when all forms are closed
      hideSaveCancelBtns();
      setIsEditDeleteDisabled(false);
      setAddExpBtnAction("add new");
    }
    expErrorReset(); // always reset error whenever any exp form opens or closes

    //console.log(isSomeExpOpen)
  }, [isExpOpen])

  // for editing exp: open the specific exp form on click of edit icon
  useEffect(() => {
    let _val = [], i = 0;
    for (; i < numberExp; i++) {
      if (i === activeExpIndex) {
        _val[i] = true;
      } else {
        _val[i] = false;
      }
    }

    if (numberExp === 0 && activeExpIndex !== null) {
      // case of delete all and then add new and edit it
      setIsExpOpen([true]);
      //console.log("case 1")
    } else if (numberExp === 0 && activeExpIndex === null) {
      // case of delete all and then add new, edit it then cancel/save
      setIsExpOpen([false]); //console.log("case 2")
    } else {
      setIsExpOpen([..._val]); //console.log("case 3:" + [..._val])
    }

    setAddExpBtnAction("save and add new");
    //console.log("activeExpIndex::" + activeExpIndex)
  }, [activeExpIndex])

  // Job Roles starts
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getJobRoles_V2((Result) => {
      let rolesArr = Result.data;
      function getFresher(n) {
        if (n.job_role_name === 'Fresher') {
          return 1
        } else {
          return 0
        }
      }
      let fresherIndex = rolesArr ? rolesArr.findIndex(getFresher) : null;
      if (rolesArr && fresherIndex !== -1) {
        rolesArr.splice(fresherIndex, 1);  // Remove Fresher from the list of roles
      }
      setJobRoles(rolesArr);
    })
  }, [isSignedIn])
  // Job Roles ends

  //Employment Types starts
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getEmploymentTypes((Result) => {
      let arr = Result.data;
      if (arr) {
        // let job_i = arr.findIndex(item => item === "Job");
        // let freelance_i = arr.findIndex(item => item === "Freelance");

        // if (job_i !== -1) {
        //   arr[job_i] = fullTime;
        // }
        // if (freelance_i !== -1) {
        //   arr[freelance_i] = partTime;
        // }
        setExpTypeArray(['select', ...arr])
        let _arr = arr.filter((item, index) => item !== "Self-Employed")
        setJobTypeList([..._arr])
      }
    })
  }, [isSignedIn])
  // Employment Types  ends  

  // company data starts
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getCompanies_V2((Result) => {
      setCompanies(Result.data);

      ApiHandler.getInstitutes_V2((result) => {
        setInstitutes(result.data);
        let _inst = result.data.map((item, i) => {
          return { ["company_name"]: item.name }
        })
        setAllSkillSources(_inst.concat(Result.data))
      })
    })

    ApiHandler.getAllSectors((result) => {
      setAllDomains(result.data);
    })

  }, [isSignedIn])
  // company data ends
  // var canvas = document.createElement("canvas");
  // var context = canvas.getContext('2d');

  // function make_base() { // trying to convert image to datauri for faster loading
  //   let base_image = new Image();
  //   base_image.crossOrigin="anonymous"
  //   base_image.src = 'https://testmeets3bucket01042020.s3.us-east-1.amazonaws.com/public/1645090089027_1.png';
  //   base_image.onload = function () {
  //     context.drawImage(base_image, 100, 100);
  //     let u = canvas.toDataURL();
  //     canvas.toDataURL();
  //     console.log(u)
  //   }
  // }

  // GetProfile
  React.useEffect(() => {
    if (!isSignedIn) return
    setIsAboutMeLoading(true);
    let payload = {
      jobseeker_id: jobseekerID
    }
    ApiHandler.getProfile(payload, response => {
      if (response.status == 200) {
        setProfileObj(response.data);
        // let img = response.data.user_image_url ? response.data.user_image_url : "";
        // if (img) {
        //   make_base()
        // }
        setProfilePhoto(response.data.user_image_url ? response.data.user_image_url : "");
        setFirstName(response.data.user_first_name ? response.data.user_first_name : "");
        setLastName(response.data.user_last_name ? response.data.user_last_name : "");
        setDob(response.data.user_dob ? response.data.user_dob : "");
        setGender(response.data.user_gender ? response.data.user_gender : null);
        setMedhaAlumni(response.data.user_medha_alumni_status ? response.data.user_medha_alumni_status : false);
        setStudentID(response.data.user_medha_student_id ? response.data.user_medha_student_id : "")
        setEmail(response.data.user_email ? response.data.user_email : "");
        setLocationValue(response.data.user_city ? response.data.user_city : "");
        setLatLong({
          lat: response.data.user_latitude ? response.data.user_latitude : "",
          long: response.data.longitude ? response.data.longitude : "",
        });
        setIsProfileCompleted(response.data.profile_completed ? response.data.profile_completed : false);
        let pcLSVal = response.data.profile_completed && response.data.profile_completed === true ? "true" : "false";
        localStorage.setItem('profile_completed', pcLSVal);
        setPhoneNumber(response.data.user_mobile ? response.data.user_mobile : "")

        //setEmailVerified(response.data.user_email_verification_status ? response.data.user_email_verification_status : false)

        setIsAboutMeLoading(false);
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }, [isSignedIn]);

  //Add edit about me

  // Add/Edit Edu
  const errValidationHelper2 = (errObj, setErrObj, key, id) => {
    let objTemp = { ...errObj, [key]: true }
    setErrObj({ ...objTemp });
    document.querySelector(id).scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  const handleAddEditAboutMe = () => {
    let payload = {
      jobseeker_id: jobseekerID,
      first_name: firstName,
      last_name: lastName,
      user_dob: dob,
      gender: gender,
      medha_alumni_status: medhaAlumni,
      student_id: medhaAlumni ? studentID : "",
      email: email.trim(),
    }


    let locationPayload = {
      jobseeker_id: jobseekerID,
      user_latitude: latLong.lat,
      user_longitude: latLong.long,
      user_city: locationValue
    }

    const handleEmailValid = () => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).trim().toLowerCase());
    }
    let isEmailValid = handleEmailValid();

    const handleNameValidation = () => {
      // const re = /^[a-zA-Z\s.]+$/
      const re = /[a-z]/i;
      return re.test(String(payload.first_name).trim().toLowerCase());
    }

    const handleStudentIDValidation = () => {
      const re = /^STU\d{5,6}$/
      return re.test(String(payload.student_id).trim());
    }

    const handleNameDotValidation = () => {
      const re = /^[a-zA-Z\s.]{}+$/ //alphabets and fullstop allowed
      //const re = /[a-z]/i;
      return re.test(String(payload.first_name).trim().toLowerCase());
    }

    let isNameValid = handleNameValidation();
    let isStudentIDValid = handleStudentIDValidation();

    if (!payload.first_name) {
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'firstName', "#firstInput_id");
    }
    else if (!isNameValid || payload.first_name.charAt(0) === '.' || payload.first_name.length < 3) {
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'firstNameFormat', "#firstInput_id");
    }
    else if (!payload.user_dob) {
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'dob', "#dob_id");
    }
    else if (payload.medha_alumni_status && !payload.student_id) {
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'studentID', "#studentID_id");
    }
    else if (payload.medha_alumni_status && payload.student_id && !isStudentIDValid) {
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'studentIDFormat', "#studentID_id");
    }
    // else if (!payload.email) {
    //   errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'email', "#eInput_id");
    // } 
    else if (payload.email && !isEmailValid) { //email is not mandatory but if entered then it should be a valid format
      errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'emailFormat', "#eInput_id");
    } else {
      ApiHandler.addEditAboutMe(payload, response => {
        if (response.status === 200) {
          ApiHandler.addEditLocation(locationPayload, response => {
            console.log(response)
          });

          //To send Email for email verification
          ApiHandler.getProfile(payload, response => {
            if (response.status == 200) {
              if (response.data.user_email && !response.data.user_email_verification_status) { // send email for email verification if email id is present and email has not been verified
                ApiHandler.sendEmailForVerification(jobseekerID, response => {
                  if (response.status === 200) {
                    console.log(response);
                  } else {
                    console.log(response);
                  }
                });
              }

              //console.log(realNumberEdu)

              //case when qual was added before the about me section and the profile_completed flag is still false
              if (!response.data.profile_completed && realNumberEdu === 1 && response.data.user_first_name && response.data.user_email) { //if profile_completed flag is false, first qual has been added, first name and email are present then turn the flag to true via API
                let payload = {
                  jobseeker_id: jobseekerID,
                  profile_completed_status: true
                }
                ApiHandler.profileCompletedFlagToggle(payload, response => {
                  if (response.status === 200) {
                    //console.log(response);
                    AnalyticsHandler.track('Profile_completed_Success');
                    localStorage.setItem('profile_completed', "true");
                    setIsProfileCompleted(true);
                  } else {
                    console.log(response);
                  }
                });
              }

            }
          });

          // handleSnackBar("Details saved successfully.");
          handleSnackBar("Done")
          setIsSaveBtnDisabled(true);
          hideSaveCancelBtns();

        } else if (response.response && response.response.status === 400) {
          if (response.response.data.message === "Email is already exists, Please use a different one") {
            errValidationHelper2(isErrorAboutMe, setIsErrorAboutMe, 'emailDuplicate', "#eInput_id");
          } else if (response.response.data.message === "User has not updated profile") {
            console.log(response.response.data.message)
            handleSnackBar("Details could not be saved. Please try again", "error");
          } else if (response.response.data.message === "Session Expire") {
            handleLogout("logoutOnSessionExpire");
          }
        } else {
          handleSnackBar("Details could not be saved. Please try again", "error");
        }
      })
    }
  }

  // Get Education
  React.useEffect(() => {
    if (!isSignedIn) return
    setIsEduLoading(true);
    let payload = {
      jobseeker_id: jobseekerID
    }

    ApiHandler.getQualifications(payload, response => {
      if (response.status == 200) {
        //console.log(response.data);
        setNumberEdu(response.data.length);
        setRealNumberEdu(response.data.length)

        let eduArrayTemp = response.data;
        if (response.data.length === 0) {
          eduArrayTemp = [
            {
              "course_name": null,
              "course_type": null,
              "specialization_name": null,
              "user_college": null,
              "user_passing_year": null
            }
          ]
        }
        eduArrayTemp.map((item, index) => {
          //set the edu form state and action btn state
          if (eduArrayTemp.length === 1 && !eduArrayTemp[0].course_name) {
            //case1: form open and save n add new 
            setIsEduOpen([true]);
            setAddEduBtnAction("save and add new");
          } else {
            //case2: form close and add new
            setIsEduOpen([...isEduOpen, isEduOpen[index] = false]);
            setAddEduBtnAction("add new");
          }

          let eduIdTmp = item && item.qual_obj_id ? item.qual_obj_id : null;
          delArrayItem1OnLoop1(eduIdArray, setEduIdArray, eduIdTmp, index);

          let instituteTmp = item && item.user_college ? item.user_college : "";
          setInstituteNameArray([...instituteNameArray, instituteNameArray[index] = instituteTmp]);

          let qualTypeTmp = item && item.course_type ? item.course_type : "select";
          setQualTypeArray([...qualTypeArray, qualTypeArray[index] = qualTypeTmp]);

          let courseNameTmp = item && item.course_name ? item.course_name : "";
          setCourseNameArray([...courseNameArray, courseNameArray[index] = courseNameTmp]);

          let specializationTmp = item && item.specialization_name ? item.specialization_name : "";
          setSpecializationNameArray([...specializationNameArray, specializationNameArray[index] = specializationTmp]);

          let graduationYearTmp = item && item.user_passing_year ? item.user_passing_year : "";
          setGraduationYearInputArray([...graduationYearInputArray, graduationYearInputArray[index] = graduationYearTmp]);

        })

        setIsEduLoading(false);
      } else if (response.response) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        console.log("error in getting a successful response")
      }
    })
  }, [isSignedIn])

  // Add/Edit Edu
  const errValidationHelper1 = (errObj, setErrObj, key, index, id) => {
    let objTemp = { ...errObj, [key]: true }
    setErrObj({ ...objTemp });
    document.querySelector(id + (index)).scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  const saveEduHelper = (index, saveAndAddNew) => {

    if (!index) { // when save only btn is clicked where index is not available
      //console.log(activeEduIndex)
      if (isEduEditModeOn) { // case of save edits
        var index = activeEduIndex;
      } else { //case of save new edu
        var index = numberEdu ? numberEdu - 1 : 0;
      }
    }
    let payload = {
      jobseeker_id: jobseekerID,
      user_college: instituteNameArray[index],
      course_name: courseNameArray[index],
      course_type: qualTypeArray[index],
      specialization_name: specializationNameArray[index],
      user_passing_year: graduationYearInputArray[index],
    }

    if (!payload.user_college) {
      errValidationHelper1(isErrorEdu, setIsErrorEdu, 'institute', index, "#institute_id");
    } else if (!payload.course_type || payload.course_type === "Select") {
      errValidationHelper1(isErrorEdu, setIsErrorEdu, 'qualType', index, "#qualTypeInput_id");
    } else if (!payload.course_name) {
      errValidationHelper1(isErrorEdu, setIsErrorEdu, 'degree', index, "#degree_id");
    } else if (!payload.user_passing_year) {
      errValidationHelper1(isErrorEdu, setIsErrorEdu, 'year', index, "#graduationYear_id");
    } else {
      setIsSaveBtnDisabled(true);
      setIsBtnBusy(true); // to prevent double api call when save btn is busy
      if (isEduEditModeOn) { // to save edits
        payload['qual_obj_id'] = eduIdArray[index];
        ApiHandler.editEdu(payload, res => {
          //console.log(res)
          setIsBtnBusy(false);

          if (res.status === 200) {
            if (index === 0) {
              setIsEduOpen([false])
            } else {
              changeArrayItem(isEduOpen, setIsEduOpen, false, index);
            }
            setActiveEduIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
            setIsEduEditModeOn(false);

            // handleSnackBar("Education edited successfully.");
            handleSnackBar("Done");
            hideSaveCancelBtns();
            setIsSaveBtnDisabled(true);

          } else if (res.response) {
            if (res.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Something went wrong. Please try again", "error");
          }
        })

      } else { // to save new edu 
        ApiHandler.addEdu(payload, response => {
          setIsBtnBusy(false);

          if (response.status == 200) {
            //console.log(response)
            let eduID = response.data.qual_obj_id;
            setEduIdArray([...eduIdArray, eduIdArray[index] = eduID]); // add the edu id to the array so that delete and edit APIs have a reference to operate
            //console.log("isProfileCompleted: " + isProfileCompleted)
            //For making the profile_completed flag true and send consent email for making the profile public
            if (!isProfileCompleted && numberEdu === 0) { // if profile_completed fetched onload is false and first qualification is added sucessfully then call the profile API to receive latest vals
              ApiHandler.getProfile(payload, response => {
                if (response.status == 200) {
                  console.log("entered success of get profile")
                  // console.log(response.data.profile_completed)
                  // console.log(response.data.user_first_name)
                  // console.log(response.data.user_email)
                  if (!response.data.profile_completed && response.data.user_first_name && response.data.user_email) { //if profile_completed flag is false, first name and email are present then turn the flag to true via API
                    let payload = {
                      jobseeker_id: jobseekerID,
                      profile_completed_status: true
                    }
                    ApiHandler.profileCompletedFlagToggle(payload, response => {
                      if (response.status === 200) {
                        //console.log(response);
                        AnalyticsHandler.track('Profile_completed_Success');
                        localStorage.setItem('profile_completed', "true");
                        setIsProfileCompleted(true);
                      } else {
                        console.log(response);
                      }
                    });
                  } else if (response.data.profile_completed) { //if the BE turns the profile_completed true on add edu then setLS to true as well
                    AnalyticsHandler.track('Profile_completed_Success');
                    localStorage.setItem('profile_completed', "true");
                  }
                }
              });
            }

            if (saveAndAddNew) {
              if (numberEdu !== 0) {
                setNumberEdu(prev => prev + 1);
              } else {
                setNumberEdu(2) // for the case of numberEdu === 0, incremented by 2 to adjust the numberEdu
              }
            } else { //only save edu
              if (index === 0) {
                setIsEduOpen([false])
              } else {
                changeArrayItem(isEduOpen, setIsEduOpen, false, index);
              }

              setActiveEduIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
              setIsEduEditModeOn(false);
            }

            // handleSnackBar("Education saved successfully.")
            handleSnackBar("Done");
            setIsSaveBtnDisabled(true);

            setRealNumberEdu(prev => prev + 1)

          } else if (response.response) {
            if (response.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Something went wrong. Please try again.", "error");
          }
        })
      }
    }
  }

  // on Add New btn click
  const handleAddEduForm = () => {
    if (numberEdu !== 0) {
      setNumberEdu(prev => prev + 1);
    } else {
      setNumberEdu(2) // for the case of numberEdu === 0, incremented by 2 to adjust the numberEdu
    }
    setAddEduBtnAction("save and add new");
    //setIsEduEditDeleteDisabled(true);
  }

  // on save and add btn click
  const handleSaveAndAddEdu = (index) => {
    let saveAndAddNew = "saveAndAddNew";
    saveEduHelper(index, saveAndAddNew);
  }

  // on save btn click
  const handleSaveEdu = () => {
    saveEduHelper();
  }

  const eduErrorReset = () => {
    setIsErrorEdu({ institute: false, qualType: false, degree: false, stream: false, year: false })
  }
  // delete edu
  const deleteEdu = (index) => {
    console.log("numberEdu at the time of delete click: " + numberEdu)

    let payload = {
      qual_obj_id: eduIdArray[index],
    }
    ApiHandler.deleteEdu(payload, response => {
      if (response.status == 200) {
        eduIdArray.splice(index, 1);
        instituteNameArray.splice(index, 1);
        qualTypeArray.splice(index, 1);
        courseNameArray.splice(index, 1);
        specializationNameArray.splice(index, 1);
        graduationYearInputArray.splice(index, 1);

        if ((isProfileCompleted && numberEdu === 1) || (isProfileCompleted && numberEdu === 0)) { // if profile_completed flag fetched onload (and updated on addition of first edu) is true and remaining 1 qualification is deleted sucessfully then toggle the flag to false via API
          //console.log("isProfileCompleted & numberEdu is 1 or 0: " + numberEdu)
          let payload = {
            jobseeker_id: jobseekerID,
            profile_completed_status: false
          }
          ApiHandler.profileCompletedFlagToggle(payload, response => {
            if (response.status === 200) {
              console.log(response);
              localStorage.setItem('profile_completed', 'false');
              setIsProfileCompleted(false);
            } else {
              console.log(response);
            }
          });
        }

        if (numberEdu !== 0) {
          setNumberEdu(prev => prev - 1);
        } else {
          setNumberEdu(1)
        }

        // handleSnackBar("Education deleted successfully.");
        handleSnackBar("Done");
        setIsSaveBtnDisabled(true);

        setRealNumberEdu(prev => prev - 1)

      } else if (response.response && response.response.status === 400) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        handleSnackBar("Something went wrong. Please try again.", "error");
      }
    })
  }

  // copy of delete function to cancel addition/editing of edu
  const cancelAddEditEdu = () => {
    let index = numberEdu !== 0 ? numberEdu - 1 : 0;
    if (isEduEditModeOn) { // for edit edu use the activeEduIndex as index
      index = activeEduIndex;
      // revert to prev vals
      instituteNameArray[index] && changeArrayItem(instituteNameArray, setInstituteNameArray, prevEduFormVals.institute, index);
      qualTypeArray[index] && changeArrayItem(qualTypeArray, setQualTypeArray, prevEduFormVals.qualType, index);
      courseNameArray[index] && changeArrayItem(courseNameArray, setCourseNameArray, prevEduFormVals.degree, index);
      specializationNameArray[index] && changeArrayItem(specializationNameArray, setSpecializationNameArray, prevEduFormVals.stream, index);
      graduationYearInputArray[index] && changeArrayItem(graduationYearInputArray, setGraduationYearInputArray, prevEduFormVals.year, index);

      changeArrayItem(isEduOpen, setIsEduOpen, false, index);
      setActiveEduIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
      setIsEduEditModeOn(false);

      setIsSaveBtnDisabled(true);
      hideSaveCancelBtns();
      return
    }
    // for cancelling adding of exp
    instituteNameArray[index] && deletArrayItem(instituteNameArray, setInstituteNameArray, index);
    courseNameArray[index] && deletArrayItem(courseNameArray, setCourseNameArray, index);
    specializationNameArray[index] && deletArrayItem(specializationNameArray, setSpecializationNameArray, index);

    if (index !== 0) {
      graduationYearInputArray[index] && deletArrayItem(graduationYearInputArray, setGraduationYearInputArray, index);
      qualTypeArray[index] && deletArrayItem(qualTypeArray, setQualTypeArray, index);
    } else { // initialize the values of the below fields if there are no exps and the form is open
      graduationYearInputArray[index] && setGraduationYearInputArray([""]);
      qualTypeArray[index] && setQualTypeArray(["select"]);
    }
    if (numberEdu !== 0) {
      setNumberEdu(prev => prev - 1);
    }

    setIsSaveBtnDisabled(true);
    hideSaveCancelBtns();
  }

  const prevNumberEdu = usePrevious(numberEdu);
  const prevIsEduOpen = usePrevious(isEduOpen);

  //for adding education: whenever numberEdu is changed the eduCountArr & isEduOpen are updated
  useEffect(() => {
    //console.log(prevNumberEdu + "-" + numberEdu)
    //update edu array for the purpose of mapping quals to display them in the ui
    if (numberEdu === 0) {
      //by default edu1 form will be open in case of 0 experience. So edu[0] obj to be added
      setEduCountArr([{ [0]: 0 }]);
    } else {
      let k = 0; let _eduCountArr = [];
      for (; k < numberEdu; k++) {
        _eduCountArr[k] = { [k]: k }
      }
      setEduCountArr([..._eduCountArr]);
    }

    // form open/collapse interaction based on delete/add edu
    if (prevNumberEdu && prevNumberEdu > numberEdu) { // in case of delete edu
      let _val = [], i = 0;
      if (numberEdu === 0) { // when last edu is deleted, open the form
        _val[i] = true;
      } else { // else collapse all forms
        for (; i < numberEdu; i++) {
          _val[i] = false;
        }
      }
      setIsEduOpen([..._val])
    } else {  // Open the new edu form and collapse others
      let _val = [], i = 0;
      for (; i < numberEdu; i++) {
        if (i === numberEdu - 1) { // last one
          _val[i] = true;
        } else { // other than last
          _val[i] = false;
        }
      }
      setIsEduOpen([..._val]);
    }
  }, [numberEdu])

  //show hide save/cancel btns & edit/delete btns, change add btn text based on edu form open/close
  useEffect(() => {
    //console.log(isEduOpen);
    // console.log(prevIsEduOpen && prevIsEduOpen.length)
    let isSomeEduOpen = isEduOpen.some((item) => {
      return item === true;
    })
    if (isSomeEduOpen === true) { //do somethnig when a form is open
      if (numberEdu !== 0 && isEducation) {
        showSaveCancelBtns();
      } else if (numberEdu === 0 && isEducation) {
        hideSaveCancelBtns();
      }

      setIsEduEditDeleteDisabled(true);
      if (prevIsEduOpen && prevIsEduOpen.length < isEduOpen.length) {
        setAddEduBtnAction("save and add new");
      }
      // else if (prevIsEduOpen && prevIsEduOpen.length === isEduOpen.length && showExpRadio === false) {
      //   setAddEduBtnAction("save edits and add new");
      // } 
      else if (prevIsEduOpen && prevIsEduOpen.length === isEduOpen.length) {
        setAddEduBtnAction("save and add new");
      }
      // animate the opened form to top
      let newSection = document.querySelector("#edu_id" + (numberEdu - 1));
      if (newSection) {
        newSection.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }


    } else { // do reverse when all forms are closed
      hideSaveCancelBtns();
      setIsEduEditDeleteDisabled(false);
      setAddEduBtnAction("add new");
    }
    eduErrorReset(); // always reset error whenever any edu form opens or closes

    //console.log(isSomeExpOpen)
  }, [isEduOpen])

  // for editing edu: open the specific edu form on click of edit icon
  useEffect(() => {
    let _val = [], i = 0;
    for (; i < numberEdu; i++) {
      if (i === activeEduIndex) {
        _val[i] = true;
      } else {
        _val[i] = false;
      }
    }

    if (numberEdu === 0 && activeEduIndex !== null) {
      // case of delete all and then add new and edit it
      setIsEduOpen([true]);
    } else if (numberEdu === 0 && activeEduIndex === null) {
      // case of delete all and then add new, edit it then cancel/save
      setIsEduOpen([false]);
    } else {
      setIsEduOpen([..._val]);
    }

    setAddEduBtnAction("save and add new");
  }, [activeEduIndex])

  // *****Skill Section******
  // Get Skills
  React.useEffect(() => {
    if (!isSignedIn) return
    setIsSkillsLoading(true);
    let jobseeker_id = jobseekerID

    ApiHandler.getUserSkills(jobseeker_id, response => {
      if (response.status == 200) {
        setNumberSkills(response.data.length);
        setRealNumberSkills(response.data.length)

        let skillArrayTemp = response.data;
        if (response.data.length === 0) {
          skillArrayTemp = [
            {
              "skill_name": null,
              "rating": null,
              "domain": null,
              "source": null,
              "details": null
            }
          ]
        }
        skillArrayTemp.map((item, index) => {
          //set the edu form state and action btn state
          if (skillArrayTemp.length === 1 && !skillArrayTemp[0].skill_name) {
            //case1: form open and save n add new 
            setIsSkillOpen([true]);
            setAddSkillBtnAction("save and add new");
          } else {
            //case2: form close and add new
            setIsSkillOpen([...isSkillOpen, isSkillOpen[index] = false]);
            setAddSkillBtnAction("add new");
          }

          let skillIdTmp = item && item._id ? item._id : null;
          delArrayItem1OnLoop1(skillsIdArray, setSkillsIdArray, skillIdTmp, index);

          let skillNameTmp = item && item.skill_name ? item.skill_name : "";
          setSkillNameArray([...skillNameArray, skillNameArray[index] = skillNameTmp]);

          let ratingTmp = item && item.rating ? item.rating : "";
          setProficiencyArray([...proficiencyArray, proficiencyArray[index] = ratingTmp]);

          let domainTmp = item && item.domain ? item.domain : "";
          setDomainArray([...domainArray, domainArray[index] = domainTmp]);

          let sourceTmp = item && item.source ? item.source : "";
          setSkillSourceArray([...skillSourceArray, skillSourceArray[index] = sourceTmp]);

          let skillInfoTmp = item && item.details ? item.details : "";
          setSkillInfoArray([...skillInfoArray, skillInfoArray[index] = skillInfoTmp]);

        })

        setIsSkillsLoading(false);
      } else if (response.response) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        console.log("error in getting a successful response")
      }
    })
  }, [isSignedIn])

  const saveSkillHelper = (index, saveAndAddNew) => {

    if (!index) { // when save only btn is clicked where index is not available
      if (isSkillEditModeOn) { // case of save edits
        var index = activeSkillIndex;
      } else { //case of save new skill
        var index = numberSkills ? numberSkills - 1 : 0;
      }
    }
    let payload = {
      jobseeker_id: jobseekerID,
      skill_name: skillNameArray[index],
      rating: proficiencyArray[index],
      domain: domainArray[index],
      source: skillSourceArray[index],
      details: skillInfoArray[index]
    }

    if (!payload.skill_name) {
      errValidationHelper1(isErrorSkill, setIsErrorSkill, 'name', index, "#skillName_id");
    } else {
      setIsSaveBtnDisabled(true);
      setIsBtnBusy(true); // to prevent double api call when save btn is busy
      if (isSkillEditModeOn) { // to save edits
        payload['_id'] = skillsIdArray[index];
        ApiHandler.editSkill(payload, res => {
          //console.log(res)
          setIsBtnBusy(false);

          if (res.status === 200) {
            if (index === 0) {
              setIsSkillOpen([false])
            } else {
              changeArrayItem(isSkillOpen, setIsSkillOpen, false, index);
            }
            setActiveSkillIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
            setIsSkillEditModeOn(false);
            handleSnackBar("Done");
            hideSaveCancelBtns();
            setIsSaveBtnDisabled(true);

          } else if (res.response) {
            if (res.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Something went wrong. Please try again", "error");
          }
        })

      } else { // to save new skill 
        ApiHandler.addSkill(payload, response => {
          setIsBtnBusy(false);

          if (response.status == 200) {
            console.log(response)
            let skillID = response.data._id;
            setSkillsIdArray([...skillsIdArray, skillsIdArray[index] = skillID]); // add the skill id to the array so that delete and edit APIs have a reference to operate              

            if (saveAndAddNew) {
              if (numberSkills !== 0) {
                setNumberSkills(prev => prev + 1);
              } else {
                setNumberSkills(2) // for the case of numberSkills === 0, incremented by 2 to adjust the numberSkills
              }
            } else { //only save skill
              if (index === 0) {
                setIsSkillOpen([false])
              } else {
                changeArrayItem(isSkillOpen, setIsSkillOpen, false, index);
              }

              setActiveSkillIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
              setIsSkillEditModeOn(false);
            }

            // handleSnackBar("Education saved successfully.")
            handleSnackBar("Done");
            setIsSaveBtnDisabled(true);

            setRealNumberSkills(prev => prev + 1)

          } else if (response.response) {
            if (response.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Something went wrong. Please try again.", "error");
          }
        })
      }
    }
  }

  // on Add New btn click
  const handleAddSkillForm = () => {
    if (numberSkills !== 0) {
      setNumberSkills(prev => prev + 1);
    } else {
      setNumberSkills(2) // for the case of numberEdu === 0, incremented by 2 to adjust the numberEdu
    }
    setAddSkillBtnAction("save and add new");
    //setIsEduEditDeleteDisabled(true);
  }

  // on save and add btn click
  const handleSaveAndAddSkill = (index) => {
    let saveAndAddNew = "saveAndAddNew";
    saveSkillHelper(index, saveAndAddNew);
  }

  // on save btn click
  const handleSaveSkill = () => {
    saveSkillHelper();
  }

  const skillErrorReset = () => {
    setIsErrorSkill({ name: false, proficiency: false, domain: false, source: false, info: false })
  }
  // delete skill
  const deleteSkill = (index) => {

    let _id = skillsIdArray[index];
    ApiHandler.deleteSkill(_id, response => {
      if (response.status == 200) {
        skillsIdArray.splice(index, 1);
        skillNameArray.splice(index, 1);
        proficiencyArray.splice(index, 1);
        domainArray.splice(index, 1);
        skillSourceArray.splice(index, 1);
        skillInfoArray.splice(index, 1);

        if (numberSkills !== 0) {
          setNumberSkills(prev => prev - 1);
        } else {
          setNumberSkills(1)
        }

        // handleSnackBar("Education deleted successfully.");
        handleSnackBar("Done");
        setIsSaveBtnDisabled(true);

        setRealNumberSkills(prev => prev - 1)

      } else if (response.response && response.response.status === 400) {
        if (response.response.data.message === "Session Expire") {
          handleLogout("logoutOnSessionExpire");
        }
      } else {
        handleSnackBar("Something went wrong. Please try again.", "error");
      }
    })
  }

  // copy of delete function to cancel addition/editing of skill
  const cancelAddEditSkill = () => {
    let index = numberSkills !== 0 ? numberSkills - 1 : 0;
    if (isSkillEditModeOn) { // for edit skill use the activeSkillIndex as index
      index = activeSkillIndex;
      // revert to prev vals
      skillNameArray[index] && changeArrayItem(skillNameArray, setSkillNameArray, prevSkillFormVals.skill, index);
      proficiencyArray[index] && changeArrayItem(proficiencyArray, setProficiencyArray, prevSkillFormVals.proficiency, index);
      domainArray[index] && changeArrayItem(domainArray, setDomainArray, prevSkillFormVals.domain, index);
      skillSourceArray[index] && changeArrayItem(skillSourceArray, setSkillSourceArray, prevSkillFormVals.source, index);
      skillInfoArray[index] && changeArrayItem(skillInfoArray, setSkillInfoArray, prevSkillFormVals.info, index);

      changeArrayItem(isSkillOpen, setIsSkillOpen, false, index);
      setActiveSkillIndex(null); // to reset active index so that it triggers opening form even if the prev index was same
      setIsSkillEditModeOn(false);

      setIsSaveBtnDisabled(true);
      hideSaveCancelBtns();
      return
    }
    // for cancelling adding of skill
    skillNameArray[index] && deletArrayItem(skillNameArray, setSkillNameArray, index);
    proficiencyArray[index] && deletArrayItem(proficiencyArray, setProficiencyArray, index);
    domainArray[index] && deletArrayItem(domainArray, setDomainArray, index);
    skillSourceArray[index] && deletArrayItem(skillSourceArray, setSkillSourceArray, index);

    if (index !== 0) {
      skillInfoArray[index] && deletArrayItem(skillInfoArray, setSkillInfoArray, index);
    } else { // initialize the values of the below fields if there are no skills and the form is open
      skillInfoArray[index] && setSkillInfoArray([""]);
    }
    if (numberSkills !== 0) {
      setNumberSkills(prev => prev - 1);
    }

    setIsSaveBtnDisabled(true);
    hideSaveCancelBtns();
  }

  const prevNumberSkill = usePrevious(numberSkills);
  const prevIsSkillOpen = usePrevious(isSkillOpen);

  //for adding skill: whenever numberSkills is changed the skillCountArr & isSkillOpen are updated
  useEffect(() => {
    //update skill array for the purpose of mapping quals to display them in the ui
    if (numberSkills === 0) {
      //by default skill1 form will be open in case of 0 experience. So skills[0] obj to be added
      setSkillsCountArr([{ [0]: 0 }]);
    } else {
      let k = 0; let _skillsCountArr = [];
      for (; k < numberSkills; k++) {
        _skillsCountArr[k] = { [k]: k }
      }
      setSkillsCountArr([..._skillsCountArr]);
    }

    // form open/collapse interaction based on delete/add skill
    if (prevNumberSkill && prevNumberSkill > numberSkills) { // in case of delete skill
      let _val = [], i = 0;
      if (numberSkills === 0) { // when last skill is deleted, open the form
        _val[i] = true;
      } else { // else collapse all forms
        for (; i < numberSkills; i++) {
          _val[i] = false;
        }
      }
      setIsSkillOpen([..._val])
    } else {  // Open the new skill form and collapse others
      let _val = [], i = 0;
      for (; i < numberSkills; i++) {
        if (i === numberSkills - 1) { // last one
          _val[i] = true;
        } else { // other than last
          _val[i] = false;
        }
      }
      setIsSkillOpen([..._val]);
    }
  }, [numberSkills])

  //show hide save/cancel btns & edit/delete btns, change add btn text based on skill form open/close
  useEffect(() => {
    let isSomeOpen = isSkillOpen.some((item) => {
      return item === true;
    })
    if (isSomeOpen === true) { //do somethnig when a form is open
      if (numberSkills !== 0 && isSkills) {
        showSaveCancelBtns();
      } else if (numberSkills === 0 && isSkills) {
        hideSaveCancelBtns();
      }

      setIsSkillEditDeleteDisabled(true);
      if (prevIsSkillOpen && prevIsSkillOpen.length < isSkillOpen.length) {
        setAddSkillBtnAction("save and add new");
      }
      else if (prevIsSkillOpen && prevIsSkillOpen.length === isSkillOpen.length) {
        setAddSkillBtnAction("save and add new");
      }
      // animate the opened form to top
      let newSection = document.querySelector("#skill_id" + (numberSkills - 1));
      if (newSection) {
        newSection.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }

    } else { // do reverse when all forms are closed
      hideSaveCancelBtns();
      setIsSkillEditDeleteDisabled(false);
      setAddSkillBtnAction("add new");
    }
    skillErrorReset(); // always reset error whenever any skill form opens or closes
  }, [isSkillOpen])

  // for editing skill: open the specific skill form on click of edit icon
  useEffect(() => {
    let _val = [], i = 0;
    for (; i < numberSkills; i++) {
      if (i === activeSkillIndex) {
        _val[i] = true;
      } else {
        _val[i] = false;
      }
    }

    if (numberSkills === 0 && activeSkillIndex !== null) {
      // case of delete all and then add new and edit it
      setIsSkillOpen([true]);
    } else if (numberSkills === 0 && activeSkillIndex === null) {
      // case of delete all and then add new, edit it then cancel/save
      setIsSkillOpen([false]);
    } else {
      setIsSkillOpen([..._val]);
    }

    setAddEduBtnAction("save and add new");
  }, [activeSkillIndex])

  const handleGetPreferencesAPICall = () => {
    let payload = { jobseeker_id: jobseekerID }

    ApiHandler.getPreferences(payload, response => {
      if (response.status == 200) {
        if (!response.data || response.data.length < 1) return // case of no preferences
        let res = response.data[0]

        let _loc = res.job_location ? res.job_location.map((item, index) => {
          return item.city
        }) : ""
        let _prefObj = { ...res, ["job_location"]: _loc }

        setPrefObj(_prefObj);
        setPrefJobType(res.job_type ? res.job_type : "")
        setPrefJobLocation(_loc)
        setPrefWFH(res.isWorkFromHome ? res.isWorkFromHome : false)
        setprefQualName(res.qual_name ? res.qual_name : "")
        setPrefSalary(res.salary ? res.salary : "")

        // setIsAboutMeLoading(false);
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  // GetPreferences
  React.useEffect(() => {
    if (!isSignedIn) return
    //setIsAboutMeLoading(true);
    handleGetPreferencesAPICall()
  }, [isSignedIn]);

  const handleAddEditPreferences = () => {
    let payload = {
      jobseeker_id: jobseekerID,
      job_type: prefJobType,
      qual_name: prefQualName,
      job_location: prefJobLocation,
      isWorkFromHome: prefWFH,
      salary: prefSalary,
    }

    if (prefObj.preference_id) {
      payload.Preference_id = prefObj.preference_id
      ApiHandler.editPreferences(payload, response => {
        if (response.status === 200) {
          handleSnackBar("Done")
          setIsSaveBtnDisabled(true);
          hideSaveCancelBtns();
          handleGetPreferencesAPICall()

        } else if (response.response && response.response.status === 400) {
          if (response.response.data.message === "Session Expire") {
            handleLogout("logoutOnSessionExpire");
          }
        } else {
          handleSnackBar("Details could not be saved. Please try again", "error");
        }
      })

    }
    else {
      if (!payload.job_type) {
        errValidationHelper2(isErrorPreferences, setIsErrorPreferences, 'job_type', "#prefJobType_id");
      }
      else {
        ApiHandler.addPreferences(payload, response => {
          if (response.status === 200) {
            handleSnackBar("Done")
            setIsSaveBtnDisabled(true);
            hideSaveCancelBtns();
            handleGetPreferencesAPICall()
          } else if (response.response && response.response.status === 400) {
            if (response.response.data.message === "Session Expire") {
              handleLogout("logoutOnSessionExpire");
            }
          } else {
            handleSnackBar("Details could not be saved. Please try again", "error");
          }
        })
      }
    }

  }

  // Courses starts
  // useEffect(() => {
  //   if (!isSignedIn) return
  //   ApiHandler.getInstitutes_V2((Result) => {
  //     setInstitutes(Result.data);
  //   })
  // }, [isSignedIn])
  // Institutes ends

  // Courses starts 
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getCourses_V2((Result) => {
      setCourses(Result.data)
      let list = Result.data.map((item, i) => {
        return item.course_name
      })
      setCoursesListOnly(list)
    })

  }, [isSignedIn])
  // Courses ends 

  // Course Types starts
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getCourseTypes_V2((Result) => {
      let arr = Result.data;
      if (arr) {
        setCourseTypes([{ ['course_type']: 'select' }, ...arr])
      }
    })
  }, [isSignedIn])
  // CourseTypes  ends 

  // Specializations starts v2
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getSpecializations_V2((Result) => {
      setSpecializations(Result.data)
    })
  }, [isSignedIn])
  // Specializations ends v2

  // All Skills
  useEffect(() => {
    if (!isSignedIn) return
    ApiHandler.getAllSkills((Result) => {
      setAllSkills(Result.data)
    })
  }, [isSignedIn])
  // All Skills ends

  //getAllLocations
  useEffect(() => {
    ApiHandler.getAllLocations((Result) => {
      let arr = Result.data;
      if (arr) {
        let _city = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].city !== "Work From Home") _city.push(arr[i].city)
        }
        setLocationList(_city)
      }
    })
  }, [])

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])

  React.useEffect(() => {
    if (isAboutMeLoading || isEduLoading && isExpLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isAboutMeLoading, isEduLoading, isExpLoading])
  // Page Loader ends

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // Settings drawer callback starts
  const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
  const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);

  const [refreshSettings, doRefreshSettings] = React.useState(false);

  const handleSettingsDrawer = () => {
    setCanSettingsDrawerOpen(true)
    doRefreshSettings(prev => !prev)
  }
  // Settings drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
  const handleSnackBar = (message, severity) => {
    if (severity) {
      setSnackBarMessageSeverity(severity);
    } else {
      setSnackBarMessageSeverity("success");
    }
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev);
  }
  // snackbar callback ends

  //secondary navbar effect on scroll starts
  let lastScrollY = 0;
  let scrolling = "aboutMe";

  const handleScrollChange = () => {
    //console.log(aboutMeRef.current.clientHeight)
    lastScrollY = window.scrollY;
    if (aboutMeRef && aboutMeRef.current && educationRef && educationRef.current && (lastScrollY > aboutMeRef.current.clientHeight - 60 && lastScrollY < aboutMeRef.current.clientHeight + educationRef.current.clientHeight - 60)) {
      scrolling = "edu";
    } else if (aboutMeRef && aboutMeRef.current && educationRef && educationRef.current && lastScrollY > aboutMeRef.current.clientHeight + educationRef.current.clientHeight - 60) {
      scrolling = "exp"
    } else {
      scrolling = "aboutMe"
    }

    if (scrolling === "aboutMe" && aboutMeRef && aboutMeRef.current) {
      window.requestAnimationFrame(() => {
        setSecondaryTabValue(0);
      });
    } else if (scrolling === "edu" && aboutMeRef && aboutMeRef.current) {
      window.requestAnimationFrame(() => {
        setSecondaryTabValue(1);
      });
    } else {
      window.requestAnimationFrame(() => {
        setSecondaryTabValue(2);
      });
    }
  };

  const [pressed, setPressed] = React.useState(false)

  // React.useEffect(() => {
  //   window.onpopstate = e => {
  //     setPressed(true)
  //     console.log('pressed')
  //   };
  // });


  // useEffect(() => {
  //   return () => { // if users cliks the browser back button and there is unsaved form data then redirect to profile page instead of navigating back

  //     if (history.action === "POP" && document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none" && !isSaveBtnDisabled) {
  //       denyTabChange()
  //       // history.replace(
  //       //   { pathname: `/profile`, }
  //       // )


  //       console.log("back pressed and there is unsaved data")
  //     }
  //     console.log("oustside back btn condition")

  //   }
  // })
  const [finishStatus, setfinishStatus] = useState(true);

  // React.useEffect(()=>{
  //   if(!isSaveBtnDisabled) {setfinishStatus(true);}
  //   else {setfinishStatus(true);}

  // }, [isSaveBtnDisabled])

  const onBackButtonEvent = (e) => {
    // if (document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none" && !isSaveBtnDisabled) { console.log("detected")
    //   denyTabChange();
    //   e.preventDefault();
    // }
    if (!isSaveBtnDisabled) {
      //console.log("detected 2")
      denyTabChange();
      e.preventDefault();
    }
    //console.log("detected 3")
    e.preventDefault();
    denyTabChange();
    // if (!finishStatus) {
    //     if (window.confirm("Warning! You have unsaved changes! Going back will delete all those changes.")) {
    //         setfinishStatus(true)
    //         // your logic
    //         //props.history.push("/");
    //     } else {
    //         window.history.pushState(null, null, window.location.pathname);
    //         setfinishStatus(false)
    //     }
    // }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  // React.useEffect(() => {
  //   window.addEventListener('scroll', handleScrollChange);
  //   return () => {
  //     window.removeEventListener('scroll', handleScrollChange);
  //   };
  // }, []);
  //secondary navbar effect on scroll ends

  //notification start
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  const closeNotificationDialog = () => {
    setOpenNotificationDialog(false);
  }

  //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

  return (

    <React.Fragment>
      <Helmet>
        <title>{primaryTabValue == 0 ? "Showcase your skills and achievements | Meetworks" : "Favourite employers on the career horizon | Meetworks"}</title>
        <meta name="description" content={primaryTabValue == 0 ? "Craft a compelling narrative that highlights your unique strengths and sets you apart in the competitive job market for showcasing to prospective employers" : "Discover and explore your favorite companies on our job portal, where opportunities meet passion and connect with employers who prioritize your success"} />
        <meta
        name="keywords"
        content={primaryTabValue == 0 ? "employment portals, job openings near me, job search websites, work from home jobs" : "preferred employers, top companies, top-rated companies, favoured employers"}/>
      </Helmet>

      {isSignedIn ?
        <>
          {/* Top App Bar on Mobile */}
          <AppBar position="fixed" style={{ background: '#fff' }}>
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <div style={{ width: '100%' }}>
                <div className={classes.sectionMobile}>
                  <Button
                    style={{ display: 'flex' }}
                    id="meetLogo_id"
                    onClick={() => {
                      history.push({
                        pathname: '/'
                      });
                    }}>
                    <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
                  </Button>
                  <Grid
                    item xs={9} md={11}
                    container
                    direction='column'
                    justifyContent="center"
                    alignItems='flex-start'>

                  </Grid>
                  {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                    <Badge badgeContent={notificationBadgeCount} color="error">
                      <NotificationsOutlinedIcon color='action' onClick={() => setOpenNotificationDialog(true)} />
                    </Badge>
                  </IconButton>}
                  <IconButton
                    id="shareButton"
                    style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                    onClick={() => {
                      if (isSignedIn) handleSettingsDrawer()
                      else handleDrawer()
                    }}
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>

                  {/* <IconButton
                  id="shareButton"
                  style={{ position: 'absolute', top: 0, right: 50, zIndex: 9, padding: '12px 0' }}
                // onClick={() => {

                // }}
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton> */}
                </div>
              </div>
            </Toolbar>
          </AppBar>

          {/* Secondary Tab bar          */}
          <AppBar position="fixed" className={secondaryTabValue !== 4 ? classes.tabBar : classes.tabBarSecondary} style={{ alignItems: 'center' }}>

            <Toolbar variant="dense" style={{
              width: secondaryTabValue !== 4 ? '100%' : 'initial',
              minHeight: 40
            }} >

              <div className={classes.grow} style={{ width: '100%' }}>
                <div className={classes.sectionMobile} style={{ display: "flex" }}>
                  {secondaryTabValue === 4 &&
                    <Tabs
                      value={primaryTabValue}
                      onChange={handlePrimaryTabValueChange}
                      scrollButtons="on"
                      indicatorColor="secondary"
                      //textColor="secondary"
                      aria-label="navigation header bar"
                      className={classes.navTabBarSecondary}
                      classes={{ flexContainer: classes.flexContainer, }}
                    >
                      <Tab label="मेरा Resume" className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(0)} />
                      <Tab label="Favourite Companies" className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(1)} />
                      {/* <Tab label="Invites Received" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(2)} /> 
                      <Tab label="Followed Companies" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(3)} /> */}
                    </Tabs>
                  }
                  {secondaryTabValue !== 4 &&
                    <>
                      <IconButton id="backButton_jobDetail" label="Back"
                        onClick={() => {
                          if (document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none"
                          ) {
                            denyTabChange()
                            return
                          }
                          setSecondaryTabValue(4)
                        }}
                        style={{ position: 'relative', zIndex: 9, paddingLeft: 10 }}>
                        <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                      </IconButton>
                      <Grid
                        item xs={9} md={11}
                        container
                        direction='column'
                        justifyContent="center"
                        alignItems='flex-start'>
                        <Typography
                          variant="subtitle1"
                          className={classes.ellipsisPost2Lines}
                          style={{ color: theme.palette.common.blackDark, lineHeight: '1.2', textAlign: 'center', width: '100%' }}>मेरा Resume
                        </Typography>
                      </Grid>
                    </>
                  }
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <TabPanel value={primaryTabValue} index={0}
          // style={{ paddingTop: 40, paddingBottom: 60 }}
          >
            {isLoading
              ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <ListLoader setListLoaderRef={setListLoaderRef} />
              </div>
              :
              <React.Fragment>
                {secondaryTabValue !== 4 &&
                  <AppBar position="fixed" className={classes.tabBarSecondary} style={{ top: 48 }}>
                    <Toolbar variant="dense" style={{ minHeight: 40 }} disableGutters>

                      <div className={classes.grow}>
                        <div className={classes.sectionMobile}>
                          <Tabs
                            value={secondaryTabValue}
                            onChange={handleSecondaryTabValueChange}
                            scrollButtons="on"
                            aria-label="resume section bar"
                            className={classes.navTabBarSecondary}
                            classes={{
                              flexContainer: classes.flexContainer,
                              //indicator: classes.indicatorLess 
                            }}
                          >
                            <Tab id="aboutme_tab_id" label="My Info" onClick={() => {
                              //  scrollToAboutMe()
                              if (document.getElementById("saveAndCancelBtns").style.display === "none"
                                // && !isSaveBtnDisabled
                              ) {
                                setIsAboutMe(true);
                                setIsEducation(false);
                                setIsExperience(false);
                                setIsSkills(false);
                                setIsPreferences(false);
                              }
                            }
                            } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }}  {...a11yProps(0)} />
                            <Tab id="edu_tab_id" label="Education" onClick={() => {
                              //scrollToEducation()
                              if (document.getElementById("saveAndCancelBtns").style.display === "none"
                                // && !isSaveBtnDisabled
                              ) {
                                //console.log("clicked edu")
                                setIsAboutMe(false);
                                setIsEducation(true);
                                setIsExperience(false);
                                setIsSkills(false);
                                setIsPreferences(false);
                              }
                            }
                            } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(1)} />
                            <Tab id="exp_tab_id" label="Experience" onClick={() => {
                              //scrollToExperience()
                              if (document.getElementById("saveAndCancelBtns").style.display === "none") {
                                //console.log("clicked exp")
                                setIsAboutMe(false);
                                setIsEducation(false);
                                setIsExperience(true);
                                setIsSkills(false);
                                setIsPreferences(false);
                              }
                            }
                            } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(2)} />

                            <Tab id="skills_tab_id" label="Skills" onClick={() => {
                              //scrollToExperience()
                              if (document.getElementById("saveAndCancelBtns").style.display === "none") {
                                //console.log("clicked exp")
                                setIsAboutMe(false);
                                setIsEducation(false);
                                setIsExperience(false);
                                setIsSkills(true);
                                setIsPreferences(false);
                              }
                            }
                            } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(3)} />

                            <Tab id="singlePage_tab_id" label="Resume"
                              style={{ display: "none" }}
                              disabled={true}
                              onClick={() => {
                                //scrollToExperience()
                                if (document.getElementById("saveAndCancelBtns").style.display === "none") {
                                  //console.log("clicked exp")
                                  setIsAboutMe(false);
                                  setIsEducation(false);
                                  setIsExperience(false);
                                  setIsSkills(false);
                                  setIsPreferences(false);
                                }
                              }
                              } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(4)} />

                            <Tab id="preferences_tab_id" label="Preferences" onClick={() => {

                              if (document.getElementById("saveAndCancelBtns").style.display === "none") {

                                setIsAboutMe(false);
                                setIsEducation(false);
                                setIsExperience(false);
                                setIsSkills(false)
                                setIsPreferences(true);
                              }
                            }
                            } className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(5)} />

                          </Tabs>
                        </div>
                      </div>
                    </Toolbar>
                  </AppBar>
                }
                {/****** ABOUT ME ******/}
                {/* {isAboutMe ? */}
                <TabPanelSec value={secondaryTabValue} index={0}>
                  <div style={{ paddingTop: 90, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>
                    <Grid container
                      id="aboutMe_id"
                      ref={aboutMeRef}
                      className={classes.section}
                      direction="column"
                    >
                      <AboutMe
                        profileObj={profileObj}
                        profilePhoto={profilePhoto}
                        setProfilePhoto={setProfilePhoto}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        dob={dob}
                        setDob={setDob}
                        gender={gender}
                        setGender={setGender}
                        locationValue={locationValue}
                        setLocationValue={setLocationValue}
                        latLong={latLong}
                        setLatLong={setLatLong}
                        medhaAlumni={medhaAlumni}
                        setMedhaAlumni={setMedhaAlumni}
                        email={email}
                        setEmail={setEmail}
                        studentID={studentID}
                        setStudentID={setStudentID}
                        isErrorAboutMe={isErrorAboutMe}
                        setIsErrorAboutMe={setIsErrorAboutMe}
                        isSignedIn={isSignedIn}
                        setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                        showSaveCancelBtns={showSaveCancelBtns}
                        handleSnackBar={handleSnackBar}
                      />

                    </Grid> </div>
                </TabPanelSec>
                {/* : null} */}
                {/****** EDUCATION ******/}
                {/* {isEducation ? */}
                <TabPanelSec value={secondaryTabValue} index={1}>
                  <div style={{ paddingTop: 90, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>
                    <Typography variant="h3" color="primary"
                      style={{ background: 'white', padding: '20px 23px' }} className={`${classes.pageHeading} ${classes.pageHeadingEditSec}`}>अपनी Educational qualifications के बारे में बताइये </Typography>
                    {eduCountArr.map((item, index) => (<Grid
                      container
                      id={"edu_id" + index}
                      key={index}
                      // ref={educationRef}
                      className={classes.section}
                      direction="column"
                    >
                      {/****** badge ******/}
                      {numberEdu > 1 && (
                        <Grid item xs={12} style={{ position: 'sticky', top: 7, zIndex: 9 }}>
                          <Grid container item xs={12}
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: 1, flexBasis: 1, backgroundColor: theme.palette.common.greyLight, marginBottom: 12, marginTop: 12 }}>
                            <Grid item style={{ position: "absolute", width: 24, height: 24, lineHeight: "24px", textAlign: 'center', verticalAlign: 'center', borderRadius: '50%', backgroundColor: theme.palette.primary.main, color: 'white' }}>{index + 1}</Grid>
                          </Grid>
                        </Grid>
                      )}

                      {!isEduOpen[index] ?
                        <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 20px', marginTop: 30, position: 'relative' }}
                          onClick={(e) => { //duplicate function as on edit
                            e.stopPropagation();
                            setActiveEduIndex(index);
                            setIsEduEditModeOn(true);
                            setPrevEduFormVals({
                              institute: instituteNameArray[index],
                              qualType: qualTypeArray[index],
                              degree: courseNameArray[index],
                              stream: specializationNameArray[index],
                              year: graduationYearInputArray[index],
                            });

                          }}
                        >
                          <Grid container item xs={12} justifyContent="flex-end" style={{ position: 'absolute', right: '-16px', top: '-32px' }}>
                            <IconButton
                              onClick={() => {
                                setActiveEduIndex(index);
                                setIsEduEditModeOn(true);
                                setPrevEduFormVals({
                                  institute: instituteNameArray[index],
                                  qualType: qualTypeArray[index],
                                  degree: courseNameArray[index],
                                  stream: specializationNameArray[index],
                                  year: graduationYearInputArray[index],
                                });

                              }}
                              style={{ display: isEduEditDeleteDisabled ? 'none' : 'block' }}
                            >
                              <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }}></EditRoundedIcon>
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();//added to prevent event bubbling
                                // deleteEdu(index);  
                                setActiveEduIndexForDelete(index);
                                setIsDeleteEduDialogOpen(true);
                              }}
                              style={{ display: isEduEditDeleteDisabled ? 'none' : 'inherit' }}
                            >
                              <DeleteForeverRoundedIcon style={{ color: theme.palette.common.greyMedium }}></DeleteForeverRoundedIcon>
                            </IconButton>
                          </Grid>
                          <Typography variant="subtitle1" >{instituteNameArray[index] ? instituteNameArray[index] : ''} </Typography>
                          <Typography variant="body1" >{courseNameArray[index] ? courseNameArray[index] : null} </Typography>
                          <Grid container item xs={12} justifyContent="flex-start" style={{ marginTop: 5 }}>
                            <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{qualTypeArray[index] && qualTypeArray[index] !== 'select' ? qualTypeArray[index] : null} </Typography>
                            {qualTypeArray[index] && qualTypeArray[index] !== 'select' &&
                              <Typography variant="body2" style={{ color: theme.palette.common.grey, marginLeft: 5, marginRight: 5 }}> |
                              </Typography>
                            }

                            <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                              {graduationYearInputArray[index] ? graduationYearInputArray[index] : null}
                            </Typography>
                          </Grid>
                        </Grid>
                        :
                        <>
                          {/****** institute ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Institute*</FormLabel>
                              <TextField
                                id={"institute_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={instituteNameArray[index] ? instituteNameArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('institute');
                                  setIndex(index);
                                }}
                                helperText={isErrorEdu.institute ? "Institute name डालें" : ""}
                                error={isErrorEdu.institute}
                              />
                            </FormControl>
                          </Grid>
                          {/****** qualification type ******/}
                          <Grid item xs={6} >
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Qualification type</FormLabel>
                              <TextField
                                required
                                id={"qualTypeInput_id" + index}
                                select
                                value={qualTypeArray[index] ? qualTypeArray[index] : "select"}
                                placeholder="Select"
                                onChange={(event) => {
                                  let temp_array = []
                                  if (qualTypeArray != []) {
                                    temp_array = [...qualTypeArray];
                                    temp_array[index] = event.target.value;
                                    setQualTypeArray(temp_array);
                                    setIsSaveBtnDisabled(false);
                                    showSaveCancelBtns();
                                  }
                                  eduErrorReset();
                                }}
                                inputProps={{ maxLength: 10 }}
                                variant="outlined"
                                classes={{ root: classes.selectRoot }}
                                helperText={isErrorEdu.qualType ? "अपना qualification type डालें" : ""}
                                error={isErrorEdu.qualType}
                              >{courseTypes.map((option, index) => (
                                <MenuItem key={index} value={option.course_type} disabled={option.course_type === "select" ? true : false}>
                                  {option.course_type}
                                </MenuItem>
                              ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                          {/****** degree ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Degree/Certification*</FormLabel>
                              <TextField
                                id={"degree_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={courseNameArray[index] ? courseNameArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('degree');
                                  setIndex(index);
                                }}
                                helperText={isErrorEdu.degree ? "required" : ""}
                                error={isErrorEdu.degree}
                              />
                            </FormControl>
                          </Grid>

                          {/****** stream ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Stream or Specialization(Optional)</FormLabel>
                              <TextField
                                id={"stream_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={specializationNameArray[index] ? specializationNameArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('stream');
                                  setIndex(index);
                                }}
                              />
                            </FormControl>
                          </Grid>

                          {/****** graduation year ******/}
                          <Grid item xs={6} >
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Year of completion</FormLabel>
                              <TextField
                                required
                                id={"graduationYear_id" + index}
                                value={graduationYearInputArray[index] ? graduationYearInputArray[index] : ''}
                                autoComplete="off"
                                inputProps={{
                                  maxLength: 4,
                                  //autoComplete: 'new-password',
                                  autoComplete: 'off',//to disable autofill
                                }}
                                onChange={(event) => {
                                  let temp_array = []
                                  if (graduationYearInputArray != []) {
                                    temp_array = [...graduationYearInputArray];
                                    temp_array[index] = event.target.value.replace(/[^0-9]/g, '');
                                    setGraduationYearInputArray(temp_array);
                                    setIsSaveBtnDisabled(false);
                                    showSaveCancelBtns();
                                  }
                                }}
                                placeholder="Enter"
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                                helperText={isErrorEdu.year ? "required" : ""}
                                error={isErrorEdu.year}
                              />
                            </FormControl>
                          </Grid>
                        </>}
                      {!isEduEditModeOn && (index === numberEdu - 1 || (index === 0 && numberEdu === 0)) ?
                        <Button
                          id={"addEduBtn_id" + index}
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            addEduBtnAction === "save and add new" ? handleSaveAndAddEdu(index)
                              : handleAddEduForm()
                          }}
                          style={{ justifyContent: "flex-start", textTransform: "capitalize" }}
                        >
                          {addEduBtnAction === "add new" ? "Add New" : "Save and Add New"}
                        </Button>
                        : null}

                    </Grid>))}
                  </div>
                </TabPanelSec>
                {/* : null} */}
                {/****** EXPERIENCE ******/}

                {/* {isExperience ? */}
                <TabPanelSec value={secondaryTabValue} index={2}>
                  <div style={{ paddingTop: 90, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>
                    <Typography variant="h3" color="primary" style={{ background: 'white', padding: '20px 23px' }} className={`${classes.pageHeading} ${classes.pageHeadingEditSec}`}>कृपया अपने Full-time/ Part-time/ Apprenticeship/ Internship experience के बारे में बताइये</Typography>
                    {/****** fresher or experienced radio ******/}
                    <Grid item xs={12} className={classes.section} style={{ background: 'white', padding: '0 20px 20px', display: showExpRadio ? 'flex' : 'none' }}>
                      <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="experience"
                          value={experienceRadio}
                          onChange={handleExperienceRadioChange}
                          //defaultValue="experienced"
                          id={"isExperienced_id"}>
                          <FormControlLabel value="fresher" control={<Radio color="primary" />} label="Fresher" />
                          <FormControlLabel value="experienced" control={<Radio color="primary" />} label="Experienced" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {expCountArr.map((item, index) =>
                      <Grid
                        container
                        //ref={experienceRef}
                        id={"exp_id" + index}
                        key={index}
                        className={classes.section}
                        direction="column">

                        {experienceRadio === "experienced" ?
                          <Grid container item xs={12} direction="column"
                          >

                            {/****** badge ******/}
                            {numberExp > 1 && (
                              <Grid item xs={12} style={{ position: 'sticky', top: 7, zIndex: 9 }}>
                                <Grid container item xs={12}
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{ height: 1, flexBasis: 1, backgroundColor: theme.palette.common.greyLight, marginBottom: 12, marginTop: 12 }}>
                                  <Grid item style={{ position: "absolute", width: 24, height: 24, lineHeight: "24px", textAlign: 'center', verticalAlign: 'center', borderRadius: '50%', backgroundColor: theme.palette.primary.main, color: 'white' }}>{index + 1}</Grid>
                                </Grid>
                              </Grid>
                            )}

                            {!isExpOpen[index] ?
                              <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 20px', marginTop: 30, position: 'relative' }}
                                onClick={() => {
                                  setActiveExpIndex(index);
                                  setIsEditModeOn(true);
                                  setPrevExpFormVals({
                                    role: jobRoleArray[index],
                                    expType: expType[index],
                                    jobDesc: jobDesc[index],
                                    isCurrentJob: isCurrentJob[index],
                                    jobStartDate: jobStartDate[index],
                                    jobEndDate: jobEndDate[index],
                                    isUnpaid: isUnpaid[index],
                                    fixedSalaryInput: fixedSalaryInput[index],
                                    variableSalaryInput: variableSalaryInput[index],
                                    variableSalaryUnitInput: variableSalaryUnitInput[index],
                                    companyName: companyNameArray[index],
                                    isWFH: isWFH[index],
                                    workLocation: workLocations[index],
                                    workLatLong: workLatLongs[index],
                                    envRating: envRatings[index],
                                    growthRating: growthRatings[index],
                                    learningRating: learningRatings[index],
                                    facilityRating: facilityRatings[index],
                                    locationRating: locationRatings[index],
                                    managerRating: managerRatings[index],
                                    salaryRating: salaryRatings[index],
                                    reviewTags: reviewTags[index],
                                    isReviewPublic: isReviewPublic[index],
                                    goodReview: goodReviews[index],
                                    badReview: badReviews[index],
                                    audioReview: audioReviews[index],
                                    videoReview: videoReviews[index],
                                    overallRating: overallRatings[index]
                                  });
                                  setShowOnePageRatings(true); //show one page ratings at the time of editing exp
                                  if (isWFH[index] === true) {
                                    setWorkLocationType("wfh");
                                  } else if (isWFH[index] === false || undefined) {
                                    setWorkLocationType("location");
                                  }
                                }}
                              >
                                <Grid container item xs={12} justifyContent="flex-end" style={{ position: 'absolute', right: '-16px', top: '-32px' }}>
                                  <IconButton
                                    onClick={() => {
                                      setActiveExpIndex(index);
                                      setIsEditModeOn(true);
                                      setPrevExpFormVals({
                                        role: jobRoleArray[index],
                                        expType: expType[index],
                                        jobDesc: jobDesc[index],
                                        isCurrentJob: isCurrentJob[index],
                                        jobStartDate: jobStartDate[index],
                                        jobEndDate: jobEndDate[index],
                                        isUnpaid: isUnpaid[index],
                                        fixedSalaryInput: fixedSalaryInput[index],
                                        variableSalaryInput: variableSalaryInput[index],
                                        variableSalaryUnitInput: variableSalaryUnitInput[index],
                                        companyName: companyNameArray[index],
                                        isWFH: isWFH[index],
                                        workLocation: workLocations[index],
                                        workLatLong: workLatLongs[index],
                                        envRating: envRatings[index],
                                        growthRating: growthRatings[index],
                                        learningRating: learningRatings[index],
                                        facilityRating: facilityRatings[index],
                                        locationRating: locationRatings[index],
                                        managerRating: managerRatings[index],
                                        salaryRating: salaryRatings[index],
                                        reviewTags: reviewTags[index],
                                        isReviewPublic: isReviewPublic[index],
                                        goodReview: goodReviews[index],
                                        badReview: badReviews[index],
                                        audioReview: audioReviews[index],
                                        videoReview: videoReviews[index],
                                        overallRating: overallRatings[index]
                                      });
                                      setShowOnePageRatings(true); //show one page ratings at the time of editing exp
                                      if (isWFH[index] === true) {
                                        setWorkLocationType("wfh");
                                      } else if (isWFH[index] === false || undefined) {
                                        setWorkLocationType("location");
                                      }
                                    }}
                                    style={{ display: isEditDeleteDisabled ? 'none' : 'block' }}
                                  >
                                    <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }}></EditRoundedIcon>
                                  </IconButton>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(); //to prevent event bubbling
                                      setActiveExpIndexForDelete(index);
                                      setIsDeleteExpDialogOpen(true);
                                    }}
                                    style={{ display: isEditDeleteDisabled ? 'none' : 'inherit' }}
                                  >
                                    <DeleteForeverRoundedIcon style={{ color: theme.palette.common.greyMedium }}></DeleteForeverRoundedIcon>
                                  </IconButton>
                                </Grid>
                                <Typography variant="subtitle1" >{jobRoleArray[index] ? jobRoleArray[index] : ''} </Typography>
                                <Typography variant="body1" >{companyNameArray[index] ? companyNameArray[index] : null} </Typography>
                                <Grid container item xs={12} justifyContent="flex-start" style={{ marginTop: 5 }}>
                                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                                    {expType[index] && expType[index] !== 'select' ? expType[index] : null} </Typography>
                                  {expType[index] && expType[index] !== 'select' &&
                                    <Typography variant="body2" style={{ color: theme.palette.common.grey, marginLeft: 5, marginRight: 5 }}> |
                                    </Typography>
                                  }
                                  <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{jobStartDate[index] ?
                                    moment(jobStartDate[index]).format('MMM YYYY') : null} - {jobEndDate[index] && jobEndDate[index] !== "Present"
                                      ? moment(jobEndDate[index]).format('MMM YYYY')
                                      : jobEndDate[index] && jobEndDate[index] === "Present" ? 'Present' : null}
                                  </Typography>
                                </Grid>
                              </Grid>
                              :
                              <React.Fragment>
                                {/****** designation/role ******/}
                                <Grid item xs={12}>
                                  <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <FormLabel component="legend" className={classes.tfLabel}>Designation/Title*</FormLabel>
                                    <TextField
                                      id={"designation_id" + index}
                                      inputProps={{ maxLength: 0 }}
                                      variant="outlined"
                                      placeholder="Search or add new"
                                      classes={{ root: classes.tfRoot2 }}
                                      value={jobRoleArray[index] ? jobRoleArray[index] : ''}
                                      onClick={() => {
                                        handleOpenFullPageAC('role');
                                        setIndex(index);
                                      }}
                                      helperText={isErrorExp.role ? "required" : ""}
                                      error={isErrorExp.role}
                                    />
                                  </FormControl>
                                </Grid>

                                {/****** company******/}
                                <Grid container item xs={12} >
                                  <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <FormLabel component="legend" className={classes.tfLabel}>Company Name*</FormLabel>
                                    <TextField
                                      id={"companyName_id" + index}
                                      inputProps={{ maxLength: 0 }}
                                      variant="outlined"
                                      placeholder="Search or add new"
                                      classes={{ root: classes.tfRoot2 }}
                                      value={companyNameArray[index] ? companyNameArray[index] : ''}
                                      onClick={() => {
                                        handleOpenFullPageAC('company');
                                        setIndex(index);
                                      }}
                                      helperText={isErrorExp.company ? "required" : ""}
                                      error={isErrorExp.company}
                                    />
                                  </FormControl>
                                </Grid>

                                {/****** employment Type ******/}
                                <Grid item xs={6} >
                                  <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <FormLabel component="legend" className={classes.tfLabel}>Experience Type*</FormLabel>
                                    <TextField
                                      id={"employmentType_id" + index}
                                      select

                                      value={expType[index] ? expType[index] : "select"}
                                      //onChange={(event) => {setExpType([...expType, expType[index] = event.target.value])}}
                                      onChange={(event) => {
                                        let temp_array = []
                                        if (expType != []) {
                                          temp_array = [...expType];
                                          let _val = event.target.value;
                                          temp_array[index] = _val;
                                          setExpType(temp_array);
                                          setIsSaveBtnDisabled(false);
                                          showSaveCancelBtns();
                                        }
                                        expErrorReset();
                                      }}
                                      inputProps={{ maxLength: 10 }}
                                      variant="outlined"
                                      placeholder="Select"
                                      classes={{ root: classes.selectRoot }}
                                      helperText={isErrorExp.expType ? "Please अपना experience type डालें" : ""}
                                      error={isErrorExp.expType}
                                    >{expTypeArray.map((option, index) => (
                                      // <MenuItem key={option.value} value={option.value}>
                                      //   {option.label}
                                      // </MenuItem>
                                      <MenuItem key={index} value={option} disabled={option === "select" ? true : false}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                    </TextField>
                                  </FormControl>
                                </Grid>

                                {/****** is current job checkbox ******/}
                                <Grid item xs={12} style={{ width: '100%' }}>
                                  <FormControlLabel
                                    id={"isCurrentJob_id" + index}
                                    control={<Checkbox
                                      checked={isCurrentJob[index] ? isCurrentJob[index] : false}
                                      //onChange={handleCurrentJobChange}
                                      onChange={(event) => {
                                        let temp_array = []
                                        if (isCurrentJob != []) {
                                          temp_array = [...isCurrentJob];
                                          temp_array[index] = event.target.checked;
                                          if (event.target.checked === true) {
                                            changeArrayItem(jobEndDate, setJobEndDate, "Present", index)
                                          }
                                          setIsCurrentJob(temp_array)
                                          setIsSaveBtnDisabled(false);
                                          showSaveCancelBtns();
                                        }
                                      }}
                                      name="checkedCurrentJob"
                                      color="primary" />}
                                    label="अभी मैं यहाँ work करता/करती हूँ" />
                                </Grid>
                                {/****** datepicker ******/}
                                <Grid
                                  item
                                  container
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  direction="row"
                                  style={{ position: 'relative', marginTop: 15, marginBottom: 30 }}
                                >
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={5}>
                                      <FormControl component="fieldset" style={{ width: '100%' }}>
                                        <FormLabel component="legend" className={classes.tfLabel}>Start Date*</FormLabel>
                                        <DatePicker
                                          autoOk
                                          disableFuture
                                          openTo="year"
                                          variant="inline"
                                          format="MMM yyyy"
                                          views={["year", "month"]}
                                          id={"startDate_id" + index}
                                          value={jobStartDate[index] ? jobStartDate[index] : null}
                                          minDate={new Date("2000-01-01")}
                                          onChange={(date) => {
                                            let temp_array = [];
                                            let newItem = '';
                                            if (jobStartDate != []) {
                                              temp_array = [...jobStartDate];
                                              //console.log(date.getMonth())
                                              newItem = moment(date).format('DD-MMM-YYYY');
                                              let sliced = newItem.slice(2);
                                              newItem = newItem.replace(newItem, '01' + sliced)
                                              //console.log(newItem + " " + sliced)
                                              //temp_array[index] = moment(date).format('DD-MMM-YYYY');
                                              temp_array[index] = newItem
                                              setJobStartDate(temp_array)
                                              setIsSaveBtnDisabled(false);
                                              showSaveCancelBtns();
                                            }
                                            expErrorReset();
                                          }}
                                          inputVariant="outlined"
                                          className={classes.datePickerInput}
                                          helperText={isErrorExp.startDt ? "required" : ""}
                                          error={isErrorExp.startDt}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <React.Fragment>
                                      {!isCurrentJob[index] ?
                                        <Grid item xs={5}>
                                          <FormControl component="fieldset" style={{ width: '100%' }}>
                                            <FormLabel component="legend" className={classes.tfLabel}>End Date*</FormLabel>
                                            <DatePicker
                                              autoOk
                                              disableFuture
                                              openTo="year"
                                              variant="inline"
                                              format="MMM yyyy"
                                              views={["year", "month"]}
                                              id={"endDate_id" + index}
                                              value={jobEndDate[index] && jobEndDate[index] !== 'Present' ? jobEndDate[index] : null}
                                              onChange={(date) => {
                                                let temp_array = [];
                                                let newItem = '';
                                                let month = date.getMonth();
                                                if (jobEndDate != []) {
                                                  temp_array = [...jobEndDate];
                                                  newItem = moment(date).format('DD-MMM-YYYY');
                                                  let sliced = newItem.slice(2);
                                                  if (month === 1) {
                                                    newItem = newItem.replace(newItem, '28' + sliced)
                                                  } else {
                                                    newItem = newItem.replace(newItem, '30' + sliced)
                                                  }

                                                  temp_array[index] = newItem
                                                  setJobEndDate(temp_array)
                                                  setIsSaveBtnDisabled(false);
                                                  showSaveCancelBtns();
                                                }
                                                expErrorReset();
                                              }}
                                              inputVariant="outlined"
                                              className={classes.datePickerInput}
                                              helperText={isErrorExp.endDt ? "required" : isErrorExp.endDtGreater ? "end date must be after start date" : ""}
                                              error={isErrorExp.endDt || isErrorExp.endDtGreater}
                                            />
                                          </FormControl>
                                        </Grid>
                                        : null}
                                    </React.Fragment>
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                {/****** job description ******/}
                                <Grid item xs={12}>
                                  <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <FormLabel component="legend" className={classes.tfLabel}>Job description</FormLabel>
                                    <TextField
                                      id={"job_desc_id" + index}
                                      inputProps={{ maxLength: 250 }}
                                      multiline
                                      rows={5}
                                      variant="outlined"
                                      placeholder="पूरी detail में अपने काम के बारें में लिखें। जितनी अधिक details आप share करेंगे उतने अच्छे से companies आपके professional background के बारे में जानकर आपको contact करेंगी। "
                                      classes={{ root: classes.TfRootSimple }}
                                      value={jobDesc[index] ? jobDesc[index] : ''}
                                      onChange={(event) => {
                                        // handleOpenFullPageAC('role');
                                        // setIndex(index);
                                        changeArrayItem(jobDesc, setJobDesc, event.target.value, index);
                                        setIsSaveBtnDisabled(false);
                                        showSaveCancelBtns();
                                      }}
                                    // helperText={isErrorExp.role ? "required" : ""}
                                    // error={isErrorExp.role}
                                    />
                                  </FormControl>
                                </Grid>

                                {/****** is unpaid job checkbox ******/}
                                <Grid item xs={12} style={{ width: '100%', marginBottom: 20 }}>

                                  <Typography component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>Salary</Typography>

                                  <FormControlLabel
                                    id={"isUnpaid_id" + index}
                                    control={<Checkbox
                                      checked={isUnpaid[index] ? isUnpaid[index] : false}
                                      //onChange={handleIsUnpaidChange}
                                      onChange={(event) => {
                                        let temp_array = []
                                        if (isUnpaid != []) {
                                          temp_array = [...isUnpaid];
                                          temp_array[index] = event.target.checked;
                                          setIsUnpaid(temp_array)
                                          setIsSaveBtnDisabled(false);
                                          showSaveCancelBtns();
                                        }
                                      }}
                                      name="checkedUnpaid"
                                      color="primary" />}
                                    label="Unpaid" />
                                </Grid>
                                {/****** salary ******/}
                                {!isUnpaid[index] && (
                                  <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="row"
                                    style={{ position: 'relative', marginBottom: 0 }}
                                  >
                                    <Grid item xs={5} >
                                      <FormControl component="fieldset" style={{ width: '100%' }}>
                                        <FormLabel component="legend" className={classes.tfLabel}>Fixed Amount</FormLabel>
                                        <TextField
                                          required
                                          id={"monthlySalary_id" + index}
                                          variant="outlined"
                                          value={fixedSalaryInput[index] ? fixedSalaryInput[index] : ""}
                                          onChange={(event) => {
                                            let temp_array = []
                                            if (fixedSalaryInput != []) {
                                              temp_array = [...fixedSalaryInput];
                                              temp_array[index] = event.target.value.replace(/[^0-9]/g, '');
                                              setFixedSalaryInput(temp_array)
                                              setIsSaveBtnDisabled(false);
                                              showSaveCancelBtns();
                                            }
                                          }}
                                          classes={{ root: classes.TfRootSimple }}
                                          inputProps={{ maxLength: 10 }}
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={5} >

                                    </Grid>

                                    <Grid item xs={5} >
                                      <FormControl component="fieldset" style={{ width: '100%' }}>
                                        <FormLabel component="legend" className={classes.tfLabel}>Incentive Amount</FormLabel>

                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                          <div>
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              onClose={handleTooltipClose}
                                              open={open}
                                              disableFocusListener
                                              disableHoverListener
                                              disableTouchListener
                                              placement="top"
                                              arrow
                                              title="Employer की तरफ से एक additional payment e.g. bonus, etc."
                                            >
                                              {/* <Button onClick={handleTooltipOpen}>Click</Button> */}

                                              <IconButton
                                                aria-label="information about incentive amount"
                                                size="small"
                                                style={{ position: "absolute", top: '-47%', left: '82%', zIndex: '9', cursor: 'default' }}
                                                onClick={() => {
                                                  if (open) handleTooltipClose();
                                                  else handleTooltipOpen();

                                                }}>
                                                <InfoOutlinedIcon className={classes.infoIcon} />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </ClickAwayListener>



                                        <TextField
                                          required
                                          id={"monthlyIncentive_id" + index}
                                          variant="outlined"
                                          value={variableSalaryInput[index] ? variableSalaryInput[index] : ""}
                                          onChange={(event) => {
                                            let temp_array = []
                                            if (variableSalaryInput != []) {
                                              temp_array = [...variableSalaryInput];
                                              temp_array[index] = event.target.value.replace(/[^0-9]/g, '');
                                              setVariableSalaryInput(temp_array)
                                              setIsSaveBtnDisabled(false);
                                              showSaveCancelBtns();
                                            }
                                          }}
                                          classes={{ root: classes.TfRootSimple, }}
                                          inputProps={{ maxLength: 10 }}
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={5} >
                                      <FormControl component="fieldset" style={{ width: '100%' }}>
                                        <FormLabel component="legend" className={classes.tfLabel}>Incentive Unit</FormLabel>
                                        <TextField
                                          required
                                          id={"monthlyIncentiveUnit_id" + index}
                                          variant="outlined"
                                          value={variableSalaryUnitInput[index] ? variableSalaryUnitInput[index] : ""}
                                          onChange={(event) => {
                                            let temp_array = []
                                            if (variableSalaryUnitInput != []) {
                                              temp_array = [...variableSalaryUnitInput];
                                              temp_array[index] = event.target.value;
                                              setVariableSalaryUnitInput(temp_array)
                                              setIsSaveBtnDisabled(false);
                                              showSaveCancelBtns();
                                            }
                                          }}
                                          classes={{ root: `${classes.TfRootSimple} ${classes.textBefore}` }}
                                          inputProps={{ maxLength: 20 }}
                                          placeholder="month, sale, etc."
                                        // InputProps={{
                                        //   startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                        // }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                )}

                                {/****** Location ******/}
                                <Grid item xs={12} style={{ marginBottom: 30 }}>
                                  {/* <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <FormLabel component="legend" className={classes.tfLabel}>Work Location*</FormLabel>
                                    <TextField
                                      id={"workLocation_id" + index}
                                      variant="outlined"
                                      placeholder="Search your location"
                                      classes={{ root: classes.tfRoot2 }}
                                      value={workLocations && workLocations[index] ? workLocations[index] : ''}
                                      onClick={() => {
                                        setIsWorkLocationACPageOpen(true);
                                        setIndex(index);
                                      }}
                                      helperText={isErrorExp.location ? "required" : ""}
                                      error={isErrorExp.location}
                                    />
                                  </FormControl> */}

                                  <FormControl id="workLocationType_id" component="fieldset" style={{ width: '100%' }}>
                                    {/* <FormLabel component="legend" className={classes.tfLabel}>Work Location*</FormLabel> */}

                                    <Typography component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>Work Location</Typography>

                                    <RadioGroup aria-label="Work location Type" name="workLocationType"
                                      value={workLocationType ? workLocationType : ""}
                                    >
                                      <div onClick={() => {
                                        setWorkLocationType("location");
                                        let temp_array = [...isWFH];
                                        let newVal = false;
                                        temp_array[index] = newVal
                                        setIsWFH(temp_array);
                                        setIsSaveBtnDisabled(false);
                                        showSaveCancelBtns();
                                      }}>
                                        <FormControlLabel value="location"
                                          //  onClick={() => { setWorkLocationType("location"); }} 
                                          control={<Radio color="primary" />} label="Add location" />
                                        <TextField
                                          id={"workLocation_id" + index}
                                          variant="outlined"
                                          placeholder="Search your location"
                                          classes={{ root: classes.tfRoot2_withPL }}
                                          value={workLocations && workLocations[index] ? workLocations[index] : ''}
                                          onClick={() => {
                                            setIsWorkLocationACPageOpen(true);
                                            setIndex(index);
                                          }}
                                        // helperText={isErrorExp.location ? "required" : ""}
                                        // error={isErrorExp.location}
                                        />
                                      </div>

                                      <FormControlLabel value="wfh"
                                        onClick={() => {
                                          setWorkLocationType("wfh");
                                          let temp_array = [...isWFH];
                                          let newVal = true;
                                          temp_array[index] = newVal;
                                          setIsWFH(temp_array);

                                          let temp_locs = [...workLocations];
                                          let newLoc = "";
                                          temp_locs[index] = newLoc;
                                          setWorkLocations(temp_locs);

                                          setIsSaveBtnDisabled(false);
                                          showSaveCancelBtns();
                                        }}
                                        control={<Radio color="primary" />} label="Work from home" />

                                    </RadioGroup>

                                  </FormControl>
                                </Grid>

                                {/****** ratings and reviews ******/}
                                <Grid direction="column" container item xs={12} >
                                  {overallRatings[index] || avgRatings[index] ?
                                    <Grid direction="column" container item xs={12} style={{ marginBottom: 50 }}>
                                      <div className={classes.tfLabel} style={{ marginBottom: 0, fontWeight: 500 }}>इस company में काम करने का अपना अनुभव हमें बताएं।</div>
                                      <Typography variant="body1" style={{ color: theme.palette.common.grey, opacity: '0.6', marginBottom: 12 }}>आपकी ratings और reviews {isReviewPublic[index] ? "public" : "गुप्त"} हैं।</Typography>
                                      <Grid container item xs={12}>
                                        <Grid container item xs={8} >
                                          {/* <Rating
                                            id={"companyRating_id" + index}
                                            name="company_rating_readonly"
                                            value={overallRatings[index] ? overallRatings[index] : !overallRatings[index] && avgRatings[index] ? avgRatings[index] : 0}
                                            precision={0.5}
                                            icon={<StarRoundedIcon fontSize="small" />}
                                            readOnly
                                          /> */}
                                          <CustomRatings
                                            id={"companyRating_id" + index}
                                            type={"static"}
                                            // value={overallRatings[index] ? overallRatings[index] : !overallRatings[index] && avgRatings[index] ? avgRatings[index] : 0}
                                            value={overallRatings[index] ? overallRatings[index] : avgRatings[index] ? avgRatings[index] : 0}
                                          // onChange={(newValue) => {
                                          //   changeOverallRatings(newValue, index);
                                          // }}
                                          />
                                          {/* <Typography style={{ marginLeft: 10, paddingTop: 13 }}>{overallRatings[index] ? overallRatings[index] : avgRatings[index] ? avgRatings[index] : "not rated"}</Typography> */}
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                          <IconButton
                                            onClick={() => { handleAddRatings(index); }}
                                            style={{ paddingRight: 0 }}>
                                            <Typography align="right" style={{ color: theme.palette.primary.dark }}>Edit</Typography>
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    :
                                    <FormControl component="fieldset" style={{ width: '100%' }}>
                                      <FormLabel
                                        component="legend"
                                        className={classes.tfLabel}
                                        style={{ marginBottom: 0, fontWeight: 500 }}
                                      >इस company में काम करने का अपना अनुभव हमें बताएं।</FormLabel>
                                      <Typography variant="body1" style={{ color: theme.palette.common.grey, opacity: '0.6', marginBottom: 12 }}>आपकी ratings और reviews {isReviewPublic[index] ? "public" : "गुप्त"} रखी जाएंगी</Typography>
                                      <div
                                        id={"ratingsAndReviews_id" + index}
                                        className={classes.TfRootSimple}
                                        style={{
                                          padding: '14.5px 15px',
                                          background: '#f5f5f5',
                                          borderRadius: 4,

                                        }}
                                        onClick={() => {
                                          handleAddRatings(index)
                                        }}
                                      ><Typography variant="body1" style={{ color: theme.palette.common.grey, opacity: '0.6' }}>अपने अनुभव की ratings और reviews साँझा करें। </Typography></div>
                                      {/* <TextField
                                        id={"ratingsAndReviews_id" + index}
                                        inputProps={{ maxLength: 0 }}
                                        variant="outlined"
                                        placeholder="Add ratings and reviews"
                                        classes={{ root: classes.TfRootSimple }}
                                        onClick={() => {
                                          handleAddRatings(index)
                                        }}
                                      // helperText={isErrorExp.ratings ? "Ratings required" : ""}
                                      // error={isErrorExp.ratings}
                                      /> */}
                                    </FormControl>}

                                  {isRatingsOpen[index] &&
                                    <AddRatingsReviews
                                      expId={expIdArray[index]}
                                      envRating={envRatings[index]}
                                      changeEnvRating={changeEnvRatings}
                                      growthRating={growthRatings[index]}
                                      changeGrowthRating={changeGrowthRatings}
                                      facilityRating={facilityRatings[index]}
                                      changeFacilityRating={changeFacilityRatings}
                                      learningRating={learningRatings[index]}
                                      changeLearningRating={changeLearningRatings}
                                      locationRating={locationRatings[index]}
                                      changeLocationRating={changeLocationRatings}
                                      managerRating={managerRatings[index]}
                                      changeManagerRating={changeManagerRatings}
                                      isUnpaid={isUnpaid[index]}
                                      salaryRating={salaryRatings[index]}
                                      changeSalaryRating={changeSalaryRatings}
                                      reviewTags={reviewTags}
                                      setReviewTags={setReviewTags}
                                      isReviewPublic={isReviewPublic[index]}
                                      changeIsReviewPublic={changeIsReviewPublic}
                                      goodReview={goodReviews[index]}
                                      changeGoodReview={changeGoodReviews}
                                      badReview={badReviews[index]}
                                      changeBadReview={changeBadReviews}
                                      audioReview={audioReviews[index]}
                                      changeAudioReview={changeAudioReviews}
                                      videoReview={videoReviews[index]}
                                      changeVideoReview={changeVideoReviews}
                                      overallRating={overallRatings[index]}
                                      changeOverallRating={changeOverallRatings}
                                      closeRatingsModal={closeRatingsModal}
                                      index={index}
                                      companyName={companyNameArray[index]}
                                      setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                                      showSaveCancelBtns={showSaveCancelBtns}
                                      showOnePageRatings={showOnePageRatings}
                                      handleSnackBar={handleSnackBar}
                                    />}
                                </Grid>

                              </React.Fragment>
                            }
                            {/* add btn not displayed for edit mode */}
                            {!isEditModeOn && (index === numberExp - 1 || (index === 0 && numberExp === 0)) ?
                              <Button
                                id={"addexperienceBtn_id" + index}
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                  addExpBtnAction === "save and add new" ? handleSaveAndAddExp(index)
                                    : handleAddExpForm()
                                }}
                                style={{ justifyContent: "flex-start", textTransform: "capitalize" }}
                              >
                                {addExpBtnAction === "add new" ? "Add New" : "Save and Add New"}
                              </Button>
                              : null}
                          </Grid>
                          : null}
                      </Grid>)}
                  </div>
                </TabPanelSec>
                {/* : null} */}

                {/****** SKILLS ******/}
                <TabPanelSec value={secondaryTabValue} index={3}>
                  <div style={{ paddingTop: 90, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>
                    <Typography variant="h3" color="primary"
                      style={{ background: 'white', padding: '20px 23px' }} className={`${classes.pageHeading} ${classes.pageHeadingEditSec}`} >अपनी Skills के बारे में बताइये </Typography>
                    {skillsCountArr.map((item, index) => (<Grid
                      container
                      id={"skill_id" + index}
                      key={index}
                      // ref={educationRef}
                      className={classes.section}
                      direction="column"
                    >
                      {/****** badge ******/}
                      {numberSkills > 1 && (
                        <Grid item xs={12} style={{ position: 'sticky', top: 7, zIndex: 9 }}>
                          <Grid container item xs={12}
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: 1, flexBasis: 1, backgroundColor: theme.palette.common.greyLight, marginBottom: 12, marginTop: 12 }}>
                            <Grid item style={{ position: "absolute", width: 24, height: 24, lineHeight: "24px", textAlign: 'center', verticalAlign: 'center', borderRadius: '50%', backgroundColor: theme.palette.primary.main, color: 'white' }}>{index + 1}</Grid>
                          </Grid>
                        </Grid>
                      )}

                      {!isSkillOpen[index] ?
                        <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 20px', marginTop: 30, position: 'relative' }}
                          onClick={(e) => { //duplicate function as on edit
                            e.stopPropagation();
                            setActiveSkillIndex(index);
                            setIsSkillEditModeOn(true);
                            setPrevSkillFormVals({
                              skill: skillNameArray[index],
                              proficiency: proficiencyArray[index],
                              domain: domainArray[index],
                              source: skillSourceArray[index],
                              info: skillInfoArray[index],
                            });

                          }}
                        >
                          <Grid container item xs={12} justifyContent="flex-end" style={{ position: 'absolute', right: '-16px', top: '-32px' }}>
                            <IconButton
                              onClick={() => {
                                setActiveSkillIndex(index);
                                setIsSkillEditModeOn(true);
                                setPrevSkillFormVals({
                                  skill: skillNameArray[index],
                                  proficiency: proficiencyArray[index],
                                  domain: domainArray[index],
                                  source: skillSourceArray[index],
                                  info: skillInfoArray[index],
                                });

                              }}
                              style={{ display: isSkillEditDeleteDisabled ? 'none' : 'block' }}
                            >
                              <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }}></EditRoundedIcon>
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();//added to prevent event bubbling
                                setActiveSkillIndexForDelete(index);
                                setIsDeleteSkillDialogOpen(true);
                              }}
                              style={{ display: isSkillEditDeleteDisabled ? 'none' : 'inherit' }}
                            >
                              <DeleteForeverRoundedIcon style={{ color: theme.palette.common.greyMedium }}></DeleteForeverRoundedIcon>
                            </IconButton>
                          </Grid>
                          <Typography variant="subtitle1" >{skillNameArray[index] ? skillNameArray[index] : ''} </Typography>
                          <Rating
                            id={"skillRatingReadOnly_id" + index}
                            //name={"overall_rating"+ index}
                            value={proficiencyArray[index] ? proficiencyArray[index] : 0}
                            readOnly
                            precision={1}
                            icon={<StarRoundedIcon fontSize="medium"
                            //style={{ fontSize: '3.5rem' }} 
                            />}
                          />
                        </Grid>
                        :
                        <>
                          {/****** skill name ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Skill*</FormLabel>
                              <TextField
                                id={"skillName_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={skillNameArray[index] ? skillNameArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('skillName');
                                  setIndex(index);
                                }}
                                helperText={isErrorSkill.name ? "Skill name डालें" : ""}
                                error={isErrorSkill.name}
                              />
                            </FormControl>
                          </Grid>
                          {/****** Skill proficiency ******/}
                          <Grid item xs={12} >
                            <FormControl component="fieldset" style={{ width: '100%', marginBottom: 30 }}>
                              <FormLabel component="legend" className={classes.tfLabel}>अपनी skill को rating दीजिए</FormLabel>
                              <Rating
                                id={"skillRating_id" + index}
                                //name={"overall_rating"+ index}
                                value={proficiencyArray[index] ? proficiencyArray[index] : 0}
                                onChange={(event) => {
                                  let _arr = [...proficiencyArray];
                                  _arr[index] = event.target.value;
                                  setProficiencyArray(_arr);
                                  setIsSaveBtnDisabled(false);
                                  showSaveCancelBtns();
                                  skillErrorReset()
                                }}
                                precision={1}
                                icon={<StarRoundedIcon fontSize="large"
                                //style={{ fontSize: '3.5rem' }} 
                                />}
                              />
                            </FormControl>
                          </Grid>
                          {/****** domain ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>Domain</FormLabel>
                              <TextField
                                id={"domain_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={domainArray[index] ? domainArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('domain');
                                  setIndex(index);
                                }}
                                helperText={isErrorSkill.domain ? "required" : ""}
                                error={isErrorSkill.domain}
                              />
                            </FormControl>
                          </Grid>

                          {/****** skill source ******/}
                          <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>आपने यह skill कहाँ सीखी या use की?</FormLabel>
                              <TextField
                                id={"skillSource_id" + index}
                                inputProps={{ maxLength: 0 }}
                                variant="outlined"
                                placeholder="Search or add new"
                                classes={{ root: classes.tfRoot2 }}
                                value={skillSourceArray[index] ? skillSourceArray[index] : ''}
                                onClick={() => {
                                  handleOpenFullPageAC('skillSource');
                                  setIndex(index);
                                }}
                              />
                            </FormControl>
                          </Grid>

                          {/****** skill info ******/}
                          <Grid item xs={12} >
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel}>अपनी skill के बारे में और बताएँ...</FormLabel>
                              <TextField
                                required
                                id={"skillInfo_id" + index}
                                value={skillInfoArray[index] ? skillInfoArray[index] : ''}
                                autoComplete="off"
                                inputProps={{
                                  maxLength: 250,
                                  //autoComplete: 'new-password',
                                  autoComplete: 'off',//to disable autofill
                                }}
                                onChange={(event) => {
                                  let temp_array = []
                                  if (skillInfoArray != []) {
                                    temp_array = [...skillInfoArray];
                                    temp_array[index] = event.target.value;
                                    //console.log(temp_array)
                                    setSkillInfoArray(temp_array);
                                    setIsSaveBtnDisabled(false);
                                    showSaveCancelBtns();
                                  }
                                }}
                                placeholder="Enter"
                                variant="outlined"
                                classes={{ root: classes.tfRoot2 }}
                              //helperText={isErrorSkill.info ? "required" : ""}
                              //error={isErrorSkill.info}
                              />
                            </FormControl>
                          </Grid>
                        </>}
                      {!isSkillEditModeOn && (index === numberSkills - 1 || (index === 0 && numberSkills === 0)) ?
                        <Button
                          id={"addSkillBtn_id" + index}
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            addSkillBtnAction === "save and add new" ? handleSaveAndAddSkill(index)
                              : handleAddSkillForm()
                          }}
                          style={{ justifyContent: "flex-start", textTransform: "capitalize" }}
                        >
                          {addSkillBtnAction === "add new" ? "Add New" : "Save and Add New"}
                        </Button>
                        : null}

                    </Grid>))}</div>
                </TabPanelSec>

                {/****** Single Page Resume ******/}
                <TabPanelSec value={secondaryTabValue} index={4}>
                  <div style={{ paddingTop: 100, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>

                    {/* Personal Info */}
                    <Grid className={classes.sectionContainer} container direction='column'>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="space-between"
                          alignItems="flex-end"
                        ><Typography variant="h3" color="primary">Personal Info
                          </Typography></Grid>

                        <IconButton
                          aria-label="edit button"
                          style={{ marginBottom: '-12px' }}
                          onClick={() => {
                            setSecondaryTabValue(0);
                            setIsAboutMe(true);
                            setIsEducation(false);
                            setIsExperience(false);
                            setIsSkills(false)
                          }}
                        >
                          <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }} />
                        </IconButton>
                      </Grid>

                      <Grid
                        container
                        id={"info_id"}
                        className={classes.sectionMain}
                        style={{ padding: 0 }}
                      >

                        <Grid container item xs={5} md={3} style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                        >
                          <div className={classes.imgWrapper}
                            style={{ width: 120, height: 160 }}
                            id="imgWrapper">
                            {
                              (profilePhoto) ? <img className={classes.img} src={profilePhoto} id="my-image" style={{ opacity: 0 }}
                                onLoad={handleProfileImage}
                              /> :
                                <div className={classes.imgPlaceholder}>
                                  <Typography variant="h3" align="center" style={{ color: theme.palette.common.grey }} >मेरी Picture</Typography>
                                  <InsertEmoticonOutlinedIcon style={{ color: theme.palette.common.grey, fontSize: '3rem' }} />
                                </div>
                            }
                            {isImgLoading && <CircularProgress color="primary" style={{ position: 'absolute' }} />}
                          </div>
                        </Grid>

                        <Grid container item xs={7} md={9} direction="column" style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                        >
                          <Typography variant="subtitle1" >{firstName ? firstName : ''} {lastName ? lastName : ''}{!firstName && !lastName && <span style={{ color: 'lightgrey' }}>Your Name</span>}</Typography>
                          {medhaAlumni && <Typography variant="body2" style={{ color: theme.palette.common.grey, marginBottom: 5 }}>Medha Alumnus {`(${studentID})`}</Typography>}

                          <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                            <CakeOutlinedIcon
                              fontSize={'inherit'}
                              style={{ color: theme.palette.primary.main, marginRight: 4 }}></CakeOutlinedIcon>
                            <Typography variant="body2">
                              {dob ? moment(dob).format('DD MMM YYYY') : <span style={{ color: 'lightgrey' }}>Date of Birth</span>}
                            </Typography>
                          </Grid>

                          <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                            <PhoneIcon
                              fontSize={'inherit'}
                              style={{ color: theme.palette.primary.main, marginRight: 4 }}></PhoneIcon>
                            <Typography variant="body2">
                              {phoneNumber ? phoneNumber : <span style={{ color: 'lightgrey' }}>-</span>}
                            </Typography>
                          </Grid>

                          <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                            <EmailOutlinedIcon
                              fontSize={'inherit'}
                              style={{ color: theme.palette.primary.main, marginRight: 4 }}></EmailOutlinedIcon>
                            <Typography variant="body2" style={{ lineBreak: 'anywhere' }}>
                              {email ? email : <span style={{ color: 'lightgrey' }}>Email ID</span>}
                            </Typography>
                          </Grid>

                          <Grid container item justifyContent="flex-start" style={{ flexWrap: "nowrap", marginBottom: 4 }}>
                            <LocationOnOutlinedIcon
                              fontSize={'inherit'}
                              style={{ color: theme.palette.primary.main, marginRight: 4 }}></LocationOnOutlinedIcon>
                            <Typography variant="body2" >{locationValue ? locationValue : <span style={{ color: 'lightgrey' }}>Location</span>} </Typography>
                          </Grid>
                        </Grid>
                      </Grid>


                    </Grid>
                    {/* Education */}
                    <Grid className={classes.sectionContainer} container direction='column'>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="space-between"
                          alignItems="flex-end"
                        ><Typography variant="h3" color="primary">Education
                          </Typography></Grid>

                        <IconButton
                          aria-label="edit button"
                          style={{ marginBottom: '-12px' }}
                          onClick={() => {
                            setSecondaryTabValue(1);
                            setIsAboutMe(false);
                            setIsEducation(true);
                            setIsExperience(false);
                            setIsSkills(false)
                          }}
                        >
                          <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }} />
                        </IconButton>
                      </Grid>
                      {realNumberEdu === 0 ?
                        <Grid container className={classes.sectionMain} >
                          <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                            अपनी educational qualifications add करें...
                          </Typography>
                        </Grid>
                        :
                        eduCountArr.map((item, index) => (<Grid
                          container
                          id={"single_page_edu_id" + index}
                          key={index}
                          // ref={educationRef}
                          className={classes.sectionMain}
                          direction="column"
                        >
                          <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                            onClick={(e) => { //duplicate function as on edit
                              e.stopPropagation();
                              setActiveEduIndex(index);
                              setIsEduEditModeOn(true);
                              setPrevEduFormVals({
                                institute: instituteNameArray[index],
                                qualType: qualTypeArray[index],
                                degree: courseNameArray[index],
                                stream: specializationNameArray[index],
                                year: graduationYearInputArray[index],
                              });

                            }}
                          >
                            <Typography variant="subtitle1" >{instituteNameArray[index] ? instituteNameArray[index] : ''} </Typography>
                            <Typography variant="body1" >{courseNameArray[index] ? courseNameArray[index] : null} </Typography>
                            <Grid container item xs={12} justifyContent="flex-start" style={{ marginTop: 5 }}>
                              <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{qualTypeArray[index] && qualTypeArray[index] !== 'select' ? qualTypeArray[index] : null} </Typography>
                              {qualTypeArray[index] && qualTypeArray[index] !== 'select' &&
                                <Typography variant="body2" style={{ color: theme.palette.common.grey, marginLeft: 5, marginRight: 5 }}> |
                                </Typography>
                              }

                              <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                                {graduationYearInputArray[index] ? graduationYearInputArray[index] : null}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>))}
                    </Grid>

                    {/* Skills */}
                    <Grid className={classes.sectionContainer} container direction='column'>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="space-between"
                          alignItems="flex-end"
                        ><Typography variant="h3" color="primary">Skills
                          </Typography></Grid>

                        <IconButton
                          aria-label="edit button"
                          style={{ marginBottom: '-12px' }}
                          onClick={() => {
                            setSecondaryTabValue(3);
                            setIsAboutMe(false);
                            setIsEducation(false);
                            setIsExperience(false);
                            setIsSkills(true)
                          }}
                        >
                          <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }} />
                        </IconButton>
                      </Grid>
                      {realNumberSkills === 0 ?
                        <Grid container className={classes.sectionMain} >
                          <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                            अपनी skills add करें...
                          </Typography>
                        </Grid>
                        :
                        skillsCountArr.map((item, index) => (<Grid
                          container
                          id={"single_page_skill_id" + index}
                          key={index}
                          // ref={educationRef}
                          className={classes.sectionMain}
                          direction="column"
                        >

                          <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                            onClick={(e) => { //duplicate function as on edit
                              e.stopPropagation();
                              setActiveSkillIndex(index);
                              setIsSkillEditModeOn(true);
                              setPrevSkillFormVals({
                                skill: skillNameArray[index],
                                proficiency: proficiencyArray[index],
                                domain: domainArray[index],
                                source: skillSourceArray[index],
                                info: skillInfoArray[index],
                              });

                            }}
                          >

                            <Typography variant="subtitle1" >{skillNameArray[index] ? skillNameArray[index] : ''} </Typography>
                            {proficiencyArray[index] && proficiencyArray[index] != 0 && <Rating
                              id={"skillRatingSinglePage_id" + index}
                              value={proficiencyArray[index] ? proficiencyArray[index] : 0}
                              readOnly
                              precision={1}
                              icon={<StarRoundedIcon fontSize="medium"
                              />}
                            />}
                          </Grid>

                        </Grid>))}
                    </Grid>

                    {/* Experience */}
                    <Grid className={classes.sectionContainer} container direction='column'>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="space-between"
                          alignItems="flex-end"
                        ><Typography variant="h3" color="primary">Experience
                          </Typography></Grid>

                        <IconButton
                          aria-label="edit button"
                          style={{ marginBottom: '-12px' }}
                          onClick={() => {
                            setSecondaryTabValue(2);
                            setIsAboutMe(false);
                            setIsEducation(false);
                            setIsExperience(true);
                            setIsSkills(false)
                          }}
                        >
                          <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }} />
                        </IconButton>
                      </Grid>
                      {realNumberExp === 0 || experienceRadio === "fresher" ?
                        <Grid container className={classes.sectionMain} >
                          <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                            {experienceRadio === "fresher" ? "आपने अपने आपको fresher बताया है। अगर आपके पास work experience है तो add करें..." : "अपना work experience add करें..."}
                          </Typography>
                        </Grid>
                        :
                        expCountArr.map((item, index) =>
                          <Grid
                            container
                            //ref={experienceRef}
                            id={"single_page_exp_id" + index}
                            key={index}
                            className={classes.sectionMain}
                            direction="column">

                            {experienceRadio === "experienced" ?
                              <Grid container item xs={12} direction="column"
                              >

                                <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                                  onClick={() => {
                                    setActiveExpIndex(index);
                                    setIsEditModeOn(true);
                                    setPrevExpFormVals({
                                      role: jobRoleArray[index],
                                      expType: expType[index],
                                      jobDesc: jobDesc[index],
                                      isCurrentJob: isCurrentJob[index],
                                      jobStartDate: jobStartDate[index],
                                      jobEndDate: jobEndDate[index],
                                      isUnpaid: isUnpaid[index],
                                      fixedSalaryInput: fixedSalaryInput[index],
                                      variableSalaryInput: variableSalaryInput[index],
                                      variableSalaryUnitInput: variableSalaryUnitInput[index],
                                      companyName: companyNameArray[index],
                                      isWFH: isWFH[index],
                                      workLocation: workLocations[index],
                                      workLatLong: workLatLongs[index],
                                      envRating: envRatings[index],
                                      growthRating: growthRatings[index],
                                      learningRating: learningRatings[index],
                                      facilityRating: facilityRatings[index],
                                      locationRating: locationRatings[index],
                                      managerRating: managerRatings[index],
                                      salaryRating: salaryRatings[index],
                                      reviewTags: reviewTags[index],
                                      isReviewPublic: isReviewPublic[index],
                                      goodReview: goodReviews[index],
                                      badReview: badReviews[index],
                                      audioReview: audioReviews[index],
                                      videoReview: videoReviews[index],
                                      overallRating: overallRatings[index]
                                    });
                                    setShowOnePageRatings(true); //show one page ratings at the time of editing exp
                                    if (isWFH[index] === true) {
                                      setWorkLocationType("wfh");
                                    } else if (isWFH[index] === false || undefined) {
                                      setWorkLocationType("location");
                                    }
                                  }}
                                >

                                  <Typography variant="subtitle1" >{jobRoleArray[index] ? jobRoleArray[index] : ''} </Typography>
                                  <Typography variant="body1" >{companyNameArray[index] ? companyNameArray[index] : null} </Typography>
                                  <Grid container item xs={12} justifyContent="flex-start" style={{ marginTop: 5 }}>
                                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                                      {expType[index] && expType[index] !== 'select' ? expType[index] : null} </Typography>
                                    {expType[index] && expType[index] !== 'select' &&
                                      <Typography variant="body2" style={{ color: theme.palette.common.grey, marginLeft: 5, marginRight: 5 }}> |
                                      </Typography>
                                    }
                                    <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{jobStartDate[index] ?
                                      moment(jobStartDate[index]).format('MMM YYYY') : null} - {jobEndDate[index] && jobEndDate[index] !== "Present"
                                        ? moment(jobEndDate[index]).format('MMM YYYY')
                                        : jobEndDate[index] && jobEndDate[index] === "Present" ? 'Present' : null}
                                    </Typography>
                                  </Grid>
                                </Grid>

                              </Grid>
                              : null}
                          </Grid>)}
                    </Grid>

                    <Grid className={classes.sectionContainer} container direction='column'>
                      {/* Preferences */}
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        direction="row"
                        className={classes.pageHeading}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="space-between"
                          alignItems="flex-end"
                        ><Typography variant="h3" color="primary">Preferences
                          </Typography></Grid>

                        <IconButton
                          aria-label="edit button"
                          style={{ marginBottom: '-12px' }}
                          onClick={() => {
                            setSecondaryTabValue(5);
                            setIsAboutMe(false);
                            setIsEducation(false);
                            setIsExperience(false);
                            setIsSkills(false)
                            setIsPreferences(true)
                          }}
                        >
                          <EditRoundedIcon style={{ color: theme.palette.common.greyMedium }} />
                        </IconButton>
                      </Grid>
                      {prefObj.length === 0 ?
                        <Grid container className={classes.sectionMain} >
                          <Typography variant="body2" style={{ color: theme.palette.common.grey }}>
                            अपने job preferences add करें...
                          </Typography>
                        </Grid>
                        :

                        <Grid
                          container
                          id={"single_page_pref_id"}
                          className={classes.sectionMain}
                          direction="column"
                        >
                          <Grid container item xs={12} direction="column" style={{ background: 'white', padding: '0 0 0px', marginTop: 10, position: 'relative' }}
                            onClick={(e) => { //duplicate function as on edit
                              e.stopPropagation();
                              setActiveEduIndex(index);
                              setIsEduEditModeOn(true);
                              setPrevEduFormVals({
                                institute: instituteNameArray[index],
                                qualType: qualTypeArray[index],
                                degree: courseNameArray[index],
                                stream: specializationNameArray[index],
                                year: graduationYearInputArray[index],
                              });

                            }}
                          >

                            <Typography variant="body1" >Job Type: <span style={{ fontWeight: 500 }}>{prefJobType ? prefJobType : ''} </span></Typography>
                            {prefJobLocation &&
                              <Typography variant="body1" >
                                Location: <span style={{ fontWeight: 500 }}>{prefJobLocation.map((item, index) => {
                                  if (index === prefJobLocation.length - 1) {
                                    return item
                                  } else {
                                    return item + ", "
                                  }
                                })}</span></Typography>
                            }
                            {prefQualName &&
                              <Typography variant="body1" >
                                Qualification: <span style={{ fontWeight: 500 }}>{prefQualName.map((item, index) => {
                                  if (index === prefQualName.length - 1) {
                                    return item
                                  } else {
                                    return item + ", "
                                  }
                                })}</span></Typography>
                            }
                            {prefSalary ? <Typography variant="body1">
                              Salary: <span style={{ fontWeight: 500 }}>{prefSalary}</span>
                            </Typography> : null}

                          </Grid>
                        </Grid>

                      }
                    </Grid>



                    {isSignedIn ?
                      <Grid container
                        className={classes.sectionMain}
                        direction="column"
                        xs={6}
                      >

                        <Button
                          id="deleteProfile_id"
                          aria-label="save"
                          // className={classes.buttonRed}
                          style={{ marginBottom: 50, marginTop: 25, backgroundColor: '#55c6a9', color: '#fff' }}
                          onClick={handleVideoDialogOpen}
                        >
                          Watch Demo
                        </Button>
                        {/* <Button
                          id="logoutProfile_id"
                          color="primary"
                          aria-label="save"
                          onClick={handleDialogOpen}
                          className={classes.buttonRed}
                          style={{ textTransform: 'capitalize', marginTop: 10  }}
                        >
                          Delete account
                        </Button> */}
                      </Grid> : null}

                    {/* Edit resume button */}
                    {/* <Grid container id="editProfileBtn" className={classes.fabBtnWrapper} justifyContent="flex-end"
                      style={{ zIndex: matchesMinHeight ? 99 : '-1' }} >
                      <Fab
                        id="editProfile_id"
                        variant="extended"
                        color="primary"
                        aria-label="cancel"
                        className={classes.fabActive}
                        // classes={{ disabled: classes.disabledFabBtn }}
                        //disabled={isBtnBusy ? true : false}
                        onClick={() => { setSecondaryTabValue(0) }}
                        style={{ height: 40 }}
                      >
                        <EditRoundedIcon style={{ color: theme.palette.common.white, paddingRight: 4 }}></EditRoundedIcon> Edit
                      </Fab>

                    </Grid> */}


                  </div>
                </TabPanelSec>

                {/****** Preferences ******/}
                <TabPanelSec value={secondaryTabValue} index={5}>
                  <div style={{ paddingTop: 90, paddingBottom: 70, minHeight: 'calc(100vh - 90px)', height: 'calc(100vh)', background: '#eee', overflowY: 'auto' }}>
                    <Typography variant="h3" color="primary"
                      style={{ background: 'white', padding: '20px 23px' }} className={`${classes.pageHeading} ${classes.pageHeadingEditSec}`} >अपनी job preferences के बारे में बताइये </Typography>
                    <Grid container
                      //id="aboutMe_id"                     
                      className={classes.section}
                      direction="column"
                    >
                      {/****** prefJobType ******/}
                      <Grid item xs={12} style={{ width: '100%', marginBottom: 15 }}>
                        <FormControl id="prefJobType_id" component="fieldset"
                          error={isErrorAboutMe.prefJobType}>
                          <FormLabel component="legend" className={classes.tfLabelDark} style={{ marginBottom: 8 }}>Job Type</FormLabel>
                          <RadioGroup row aria-label="position" name="prefJobType" value={prefJobType} >
                            {
                              jobTypeList.map((item, index) => (
                                <FormControlLabel value={item} onClick={() => { setPrefJobType(item); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }} control={<Radio color="primary" size="small" />} label={item} />
                              ))
                            }
                          </RadioGroup>
                          <FormHelperText>{isErrorPreferences.job_type ? "Please select a job type" : ""}</FormHelperText>
                        </FormControl>
                      </Grid>

                      {/****** pref Course for job ******/}
                      <Grid item xs={12} className={classes.my4} >
                        <FormLabel component="legend" className={classes.tfLabelDark} style={{ marginBottom: 8 }}>Preferred Qualification </FormLabel>
                        <Autocomplete
                          //   classes={{ root: classes.locationAutoComplete }}
                          multiple
                          name="job_location"
                          options={coursesListOnly}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            } else if (typeof option == 'object') {
                              //console.log("case of ['']")
                              return ""
                            }
                            return option
                          }
                          }
                          renderOption={(option) => {
                            return option
                          }}
                          filterSelectedOptions
                          getOptionSelected={(option, value) => option === value}
                          value={prefQualName || []}
                          onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue) { // when value is selected from dropdown
                              setprefQualName(newValue)
                              setIsSaveBtnDisabled(false);
                              showSaveCancelBtns();
                            }
                          }}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={'Select Qualifications'}
                              classes={{ root: classes.TfRoot }}
                              style={{ marginBottom: 10 }}
                            />
                          )}
                        />

                      </Grid>

                      {/****** prefJobLocation ******/}
                      <Grid item xs={12} className={classes.my4} style={{ marginTop: 20 }} >
                        <FormLabel component="legend" className={classes.tfLabelDark} style={{ marginBottom: 8 }}>Preferred Location </FormLabel>
                        <Autocomplete
                          //   classes={{ root: classes.locationAutoComplete }}
                          multiple
                          name="job_location"
                          options={locationList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            } else if (typeof option == 'object') {
                              //console.log("case of ['']")
                              return ""
                            }
                            return option
                          }
                          }
                          renderOption={(option) => {
                            return option
                            // if (option !== 'Work From Home') {
                            //   return option
                            // }
                          }}
                          filterSelectedOptions
                          getOptionSelected={(option, value) => option === value}
                          value={prefJobLocation || []}
                          onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue) { // when value is selected from dropdown
                              setPrefJobLocation(newValue);
                              setIsSaveBtnDisabled(false);
                              showSaveCancelBtns();
                            }
                          }}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={'Select locations'}
                              classes={{ root: classes.TfRoot }}
                              style={{ marginBottom: 10 }}
                            />
                          )}
                        />

                        <FormControlLabel
                          style={{ color: '#00000091' }}
                          control={
                            <Checkbox
                              checked={prefWFH}
                              onChange={(e) => {
                                setPrefWFH(e.target.checked);
                                setIsSaveBtnDisabled(false);
                                showSaveCancelBtns();
                              }
                              }
                              size='small'
                              name="job_type"
                              color="primary"
                              value={'isWorkFromHome'}
                            />
                          }
                          classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                          label={'isWorkFromHome'}
                        />
                      </Grid>

                      {/****** salary pref ******/}
                      <Grid container style={{ marginTop: 20 }} alignItems='center'>
                        <Grid item xs={6}>
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" className={classes.tfLabelDark}>Preferred Salary </FormLabel>
                            <TextField
                              id={"preferred_salary_id"}
                              select
                              value={prefSalary ? prefSalary : "select"}
                              onChange={(event) => {
                                setPrefSalary(event.target.value);
                                setIsSaveBtnDisabled(false);
                                showSaveCancelBtns();

                                setIsErrorPreferences({ job_type: false });
                              }}
                              inputProps={{ maxLength: 10 }}
                              variant="outlined"
                              placeholder="Select"
                              classes={{ root: classes.selectRoot }}

                            >{prefSalaryArray.map((option, index) => (
                              // <MenuItem key={option.value} value={option.value}>
                              //   {option.label}
                              // </MenuItem>
                              <MenuItem key={index} value={option} disabled={option === "select" ? true : false}>
                                {option}
                              </MenuItem>
                            ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} > <Typography variant="body1" style={{ color: theme.palette.common.grey, paddingLeft: 10 }}>and above</Typography></Grid>
                      </Grid>

                    </Grid>
                  </div>
                </TabPanelSec>
                {/* Save and cancel buttons */}
                <Grid container id="saveAndCancelBtns" className={classes.fabBtnWrapper} justifyContent="flex-end"
                  style={{ display: "none", zIndex: matchesMinHeight ? 99 : '-1' }} >
                  <Fab
                    id="cancelProfile_id"
                    variant="extended"
                    aria-label="cancel"
                    className={classes.fabDisabled}
                    // classes={{ disabled: classes.disabledFabBtn }}
                    disabled={isBtnBusy ? true : false}
                    onClick={doCancel}
                    style={{ marginRight: 12 }}
                  >
                    Cancel
                  </Fab>
                  <Fab
                    id="saveProfile_id"
                    variant="extended"
                    color="primary"
                    aria-label="save"
                    className={classes.fabActive}
                    disabled={isSaveBtnDisabled}
                    classes={{ disabled: isBtnBusy ? classes.busyFabBtn : classes.disabledFabBtn }}
                    onClick={() => {
                      if (isEducation) handleSaveEdu();
                      else if (isExperience) handleSaveExp();
                      else if (isAboutMe) handleAddEditAboutMe();
                      else if (isSkills) handleSaveSkill();
                      else if (isPreferences) handleAddEditPreferences();
                    }}
                  >
                    {isBtnBusy ? "Saving " : "Save"}
                    {isBtnBusy && (<CircularProgress size={20} style={{ color: theme.palette.primary.main, marginLeft: 10 }} />)}
                  </Fab>
                </Grid>

                {/* Full page autocomplete */}
                {isAutocompletePageOpen ?
                  showAC.role ?
                    <FullPageAutocomplete
                      index={index}
                      label={'Designation / Job role*'}
                      arr={jobRoleArray}
                      setArr={setJobRoleArray}
                      list={JobRoles}
                      optionKey={'job_role_name'}
                      errorReset={expErrorReset}
                      isError={isErrorExp}
                      setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                      showSaveCancelBtns={showSaveCancelBtns}
                      handleCloseFullPageAC={handleCloseFullPageAC}
                    />
                    : showAC.company ?
                      <FullPageAutocomplete
                        index={index}
                        label={'Company Name*'}
                        arr={companyNameArray}
                        setArr={setCompanyNameArray}
                        list={companies}
                        optionKey={'company_name'}
                        errorReset={expErrorReset}
                        isError={isErrorExp}
                        setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                        showSaveCancelBtns={showSaveCancelBtns}
                        handleCloseFullPageAC={handleCloseFullPageAC}
                      />
                      : showAC.institute ?
                        <FullPageAutocomplete
                          index={index}
                          label={'Institute*'}
                          arr={instituteNameArray}
                          setArr={setInstituteNameArray}
                          list={institutes}
                          optionKey={'name'}
                          errorReset={eduErrorReset}
                          isError={isErrorEdu}
                          setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                          showSaveCancelBtns={showSaveCancelBtns}
                          handleCloseFullPageAC={handleCloseFullPageAC}
                        />
                        : showAC.degree ?
                          <FullPageAutocomplete
                            index={index}
                            label={'Degree/Certification*'}
                            arr={courseNameArray}
                            setArr={setCourseNameArray}
                            list={courses}
                            optionKey={'course_name'}
                            errorReset={eduErrorReset}
                            isError={isErrorEdu}
                            setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                            showSaveCancelBtns={showSaveCancelBtns}
                            handleCloseFullPageAC={handleCloseFullPageAC}
                          />
                          : showAC.stream ?
                            <FullPageAutocomplete
                              index={index}
                              label={'Stream or Specialization(Optional)'}
                              arr={specializationNameArray}
                              setArr={setSpecializationNameArray}
                              list={specializations}
                              optionKey={'specialization_name'}
                              errorReset={eduErrorReset}
                              isError={isErrorEdu}
                              setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                              showSaveCancelBtns={showSaveCancelBtns}
                              handleCloseFullPageAC={handleCloseFullPageAC}
                            />
                            : showAC.skillName ?
                              <FullPageAutocomplete
                                index={index}
                                label={'Skill Name'}
                                arr={skillNameArray}
                                setArr={setSkillNameArray}
                                list={allSkills}
                                optionKey={'skill_name'}
                                errorReset={skillErrorReset}
                                isError={isErrorSkill}
                                setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                                showSaveCancelBtns={showSaveCancelBtns}
                                handleCloseFullPageAC={handleCloseFullPageAC}
                              />
                              : showAC.domain ?
                                <FullPageAutocomplete
                                  index={index}
                                  label={'Domain'}
                                  arr={domainArray}
                                  setArr={setDomainArray}
                                  list={allDomains}
                                  optionKey={'sector_name'}
                                  errorReset={skillErrorReset}
                                  isError={isErrorSkill}
                                  setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                                  showSaveCancelBtns={showSaveCancelBtns}
                                  handleCloseFullPageAC={handleCloseFullPageAC}
                                />
                                : showAC.skillSource ?
                                  <FullPageAutocomplete
                                    index={index}
                                    label={'Source'}
                                    arr={skillSourceArray}
                                    setArr={setSkillSourceArray}
                                    list={allSkillSources}
                                    optionKey={'company_name'}
                                    errorReset={skillErrorReset}
                                    isError={isErrorSkill}
                                    setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                                    showSaveCancelBtns={showSaveCancelBtns}
                                    handleCloseFullPageAC={handleCloseFullPageAC}
                                  />
                                  : null
                  : null
                }

                {/* Full page autocomplete */}
                {isWorkLocationACPageOpen ?
                  <div style={{
                    position: 'fixed',
                    left: 0,
                    width: '100%',
                    padding: '10px 23px',
                    zIndex: 9999,
                    background: 'white',
                    top: 0,
                    height: '100vh',
                    overflow: 'scroll'
                  }}>

                    <React.Fragment>
                      <AppBar position="fixed" className={classes.tabBar}>
                        <Toolbar variant="dense" style={{ width: '100%' }} >

                          <IconButton edge="start" className={classes.menuButton} aria-label="back button"
                            onClick={() => {
                              setIsWorkLocationACPageOpen(false);
                            }
                            }>
                            <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                          </IconButton>
                          <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark }}>
                            Work Location
                          </Typography>

                        </Toolbar>
                      </AppBar>

                      <Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%', marginTop: 50 }}>
                          {/* <FormLabel component="legend" className={classes.tfLabel}>Location</FormLabel> */}
                          <Location
                            index={index}
                            locationValue={workLocations && workLocations[index] ? workLocations[index] : ''}
                            workLocations={workLocations ? workLocations : ""}
                            setWorkLocations={setWorkLocations}
                            latLong={workLatLongs}
                            setLatLong={setWorkLatLongs}
                            errorReset={expErrorReset}
                            // isErrorAboutMe={isErrorAboutMe}
                            setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                            showSaveCancelBtns={showSaveCancelBtns}
                            setIsLocationAutocompletePageOpen={setIsWorkLocationACPageOpen}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>

                  </div> : null}

              </React.Fragment>
            }
          </TabPanel>
          <TabPanel value={primaryTabValue} index={1}>
            <FavouriteCompanies classes={classes} />
          </TabPanel>
          {/* <TabPanel value={primaryTabValue} index={2}>
            <JobInvites />
          </TabPanel> */}
          {/* <TabPanel value={primaryTabValue} index={3}>
            <FollowedCompanies />
          </TabPanel> */}
        </>
        :
        <div className={classes.pageInfo}
          style={{
            // paddingTop: matchesMinHeight ? '70px' : 0,
            paddingTop: 0,
            height: '92vh',
            justifyContent: 'center',
            backgroundImage: `url(${Wallpaper})`,
            backgroundColor: '#fcfcfc'
          }}
        >
          {/* <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} /> */}
          <Typography className={classes.pageInfoText} variant="subtitle1">Please sign in to create or access your profile.</Typography>
          {/* <Typography className={classes.pageInfoText} variant="h3">अगर आपका Meeत पे account है sign in करें।</Typography> */}
          <Button
            id="Search_all_positions"
            variant="contained"
            className={`${classes.pageButton} ${classes.pageButton_pri}`}
            style={{ width: 170 }}
            onClick={() => {
              AnalyticsHandler.track('Profile_signIn_BtnClick');
              handleDrawer();
            }}
          >
            Sign in
          </Button>

          {/* <Typography className={classes.pageInfoText}
            style={{ paddingTop: 88 }}
            variant="h3">अपना account बनाने के लिए हमें contact करें।</Typography>
          <Button
            variant="contained"
            className={`${classes.pageButton} ${classes.pageButton_pri}`}
            style={{ width: 170 }}
            onClick={() => {
              window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
            }}
          >
            Whatsapp करें
          </Button> */}

        </div>
      }
      {/* <div className={classes.bottomBarMobile}> */}
      {secondaryTabValue === 4 && <BottomAppBar
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        handleDrawer={handleDrawer}
        handleSettingsDrawer={handleSettingsDrawer}
        navValue={navValue}
        setNavValue={setNavValue}
        denyTabChange={denyTabChange}
        isSaveBtnDisabled={isSaveBtnDisabled}
      // setDisplaySearchBar={setDisplaySearchBar}
      // setDisplayAllJobs={setDisplayAllJobs}
      //executeScroll={executeScroll}
      >
      </BottomAppBar>}
      {/* </div> */}
      <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} severity={snackBarMessageSeverity} />

      {/* Delete Account Dialog box */}
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="delete-account-dialog"
        open={isDialogOpen}
        maxWidth={'sm'}
        fullScreen
        style={{ overflow: 'hidden' }}
      >

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ padding: 0, overflow: 'hidden' }}>
          <Grid container justifyContent="center">
            <IconButton aria-label="close" >
              <WarningRoundedIcon style={{ fontSize: '8rem', color: theme.palette.common.redLight }} />
            </IconButton>
            <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
              हम आपकी सहायता कैसे कर सकते हैं?
            </Typography>

            <Button
              id="profile_whatsapp_button"
              variant="contained"
              className={`${classes.pageButton} ${classes.pageButton_pri}`}
              style={{ textTransform: 'unset', width: 'auto' }}
              onClick={() => {
                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
              }}
            >
              हमें WhatsApp पे 'Hi' भेजें
            </Button>
          </Grid>

        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            style={{ backgroundColor: '#fff', color: theme.palette.common.red, textTransform: 'unset', width: 'auto' }}
            onClick={deleteAccount}
          >
            मुझे अपना Meeत account delete करना हैं. मैं समझता हूँ कि ऐसा करने से मुझे new opportunities और companies नहीं मिलेगी।
          </Button>
        </DialogActions>
      </Dialog>

      {/* Demo video Dialog box */}
      <Dialog
        onClose={handleVideoDialogClose}
        aria-labelledby="delete-account-dialog"
        open={isVideoDialogOpen}
        maxWidth={'sm'}
        fullScreen
        style={{ overflow: 'hidden' }}
      >

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleVideoDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ padding: 0, overflow: 'hidden' }}>
          <ReactPlayer playing url='https://youtu.be/JroneE1ZpXA?si=ogqC-hArmphs6NLt' controls width='100vw' height='100vh' />

        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog box */}
      <Dialog onClose={handleConfDialogClose} aria-labelledby="customized-dialog-title" open={isConfDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleConfDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं कि आप logout करना चाहते हैं?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            onClick={() => {
              AnalyticsHandler.track('Profile_logout_BtnClick');
              handleLogout();
            }
            }
          >
            Yes
          </Button>

          <Button autoFocus
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_pri}`}
            onClick={handleConfDialogClose}
          >
            No
          </Button>

        </DialogActions>
      </Dialog>

      {/* Delete Edu Confirmation Dialog box */}
      <Dialog onClose={handleDeleteEduDialogClose} aria-labelledby="customized-dialog-title" open={isDeleteEduDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDeleteEduDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं कि आप delete करना चाहते हैं?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            onClick={() => {
              deleteEdu(activeEduIndexForDelete);
              handleDeleteEduDialogClose();
            }
            }
          >
            Yes
          </Button>

          <Button autoFocus
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            style={{ backgroundColor: theme.palette.common.greyLight }}
            onClick={handleDeleteEduDialogClose}
          >
            No
          </Button>

        </DialogActions>
      </Dialog>

      {/* Delete Exp Confirmation Dialog box */}
      <Dialog onClose={handleDeleteExpDialogClose} aria-labelledby="customized-dialog-title" open={isDeleteExpDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDeleteExpDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं कि आप delete करना चाहते हैं?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            onClick={() => {
              deleteExp(activeExpIndexForDelete);
              handleDeleteExpDialogClose();
            }
            }
          >
            Yes
          </Button>

          <Button autoFocus
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            style={{ backgroundColor: theme.palette.common.greyLight }}
            onClick={handleDeleteExpDialogClose}
          >
            No
          </Button>

        </DialogActions>
      </Dialog>

      {/* Delete Skill Confirmation Dialog box */}
      <Dialog onClose={handleDeleteSkillDialogClose} aria-labelledby="customized-dialog-title" open={isDeleteSkillDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDeleteSkillDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं कि आप delete करना चाहते हैं?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>

          <Button
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            onClick={() => {
              deleteSkill(activeSkillIndexForDelete);
              handleDeleteSkillDialogClose();
            }
            }
          >
            Yes
          </Button>

          <Button autoFocus
            color="primary"
            size="medium"
            className={`${classes.button} ${classes.button_sec}`}
            style={{ backgroundColor: theme.palette.common.greyLight }}
            onClick={handleDeleteSkillDialogClose}
          >
            No
          </Button>

        </DialogActions>
      </Dialog>
      {/* Notification starts */}
      <Dialog
        open={openNotificationDialog}
        onClose={closeNotificationDialog}
        fullScreen
      >
        <DialogContent classes={{ root: classes.notificationDialogRoot }}>
          <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
            <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
            <Typography variant='body1' >Notifications</Typography>
          </Grid>
          <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
        </DialogContent>
      </Dialog>

      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />

      <LogoutDrawer
        canDrawerOpen={canSettingsDrawerOpen}
        refresh={refreshSettings}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshSettingsOnly}
        setCanDrawerOpen={setCanSettingsDrawerOpen}
        doRefresh={doRefreshSettings}
        handleSnackBar={handleSnackBar}
      />
    </React.Fragment>
  )
}