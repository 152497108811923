import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import '../../style/App.css';
import { useStyles } from './styles';
import { handleAutoCompleteInputChange, onChange1_AC, onChange2_AC } from '../../Helpers';

const filter = createFilterOptions();

export default function FullPageAutocomplete({
  index, label,
  arr, setArr, list, optionKey,
  errorReset, isError,
  setIsSaveBtnDisabled, showSaveCancelBtns,
  handleCloseFullPageAC }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={{
      position: 'fixed',
      left: 0,
      width: '100%',
      padding: '10px 23px',
      zIndex: 9999,
      background: 'white',
      top: 0,
      //height: 588,
      height: '100vh',
      overflow: 'scroll'
    }}>
      <React.Fragment>
        <AppBar position="fixed" className={classes.tabBar}>
          <Toolbar variant="dense" style={{ width: '100%' }} >

            <IconButton edge="start" className={classes.menuButton} aria-label="back button"
              onClick={() => {
                handleCloseFullPageAC();
              }}>
              <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
            </IconButton>
            <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark }}>
            {label}
            </Typography>

          </Toolbar>
        </AppBar>

        <React.Fragment>
          <Grid item xs={12}>

            <FormControl component="fieldset" style={{ width: '100%', marginTop: 50 }}>
              {/* <FormLabel component="legend" className={classes.tfLabel}>{label}</FormLabel> */}
              <Autocomplete
                id={"institute_id" + index}
                freeSolo
                //open={openInst}// for controlling open event
                //onOpen={handleOpenInst}// for controlling open event
                //onClose={() => setOpenInst(false)}// for controlling open event
                autoSelect
                value={arr[index] ? arr[index] : ''}
                key={arr[index] ? arr[index] : !arr[index]}
                options={list}
                //inputValue={inputValInst} // for controlling open event
                onInputChange={(event, value) => {
                  // setInputValInst(value); // for controlling open event
                  // if (value.length >= 1) { // if a letter is typed then open
                  //   setOpenInst(true)
                  // } else {
                  //   setOpenInst(false)
                  // }
                  if (arr[index]){
                    let inputLength = arr[index].length;
                    if (inputLength < value.length || inputLength > value.length){
                      //console.log("input changed");
                      setIsSaveBtnDisabled(false);
                      showSaveCancelBtns();
                    }
                  }
                  
                  if (value === "") {
                    handleAutoCompleteInputChange(arr, setArr, index)
                    errorReset();
                  }
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  if (option[optionKey])
                    return option[optionKey];
                  else
                    return (".....");
                }}
                renderOption={(option) => {
                  if(option.inputValue){
                    return (<h4 style={{color: theme.palette.primary.main, marginTop: 12}}>{`${option[optionKey]}`}</h4>)
                  } else {
                    return option[optionKey]
                  }
                  
                }}
                onChange={(event, newValue) => { 
                  if (newValue) {
                    if (typeof newValue === 'string') {
                      onChange1_AC(arr, setArr, index, newValue);
                      setIsSaveBtnDisabled(false);
                      showSaveCancelBtns();
                      handleCloseFullPageAC();
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      onChange1_AC(arr, setArr, index, newValue.inputValue);
                      setIsSaveBtnDisabled(false);
                      showSaveCancelBtns();
                      handleCloseFullPageAC();
                    } else {
                      onChange2_AC(arr, setArr, index, newValue[optionKey], newValue[optionKey]);
                      setIsSaveBtnDisabled(false);
                      showSaveCancelBtns();
                      handleCloseFullPageAC();
                    }
                  }
                  //console.log(newValue)                 
                  errorReset();
                }
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      [optionKey]: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                classes={{ inputRoot: classes.inputRootAc, popper: classes.popperAC }}
                renderInput={(params) => (
                  <TextField {...params}
                    variant="outlined"
                    placeholder="Search or add new"
                    classes={{ root: classes.TfRoot }}
                    autoFocus
                  // helperText={isError.degree ? "Institute name डालें" : ""}
                  // error={isError.degree}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      </React.Fragment>
    </div>
  )
}