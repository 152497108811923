import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Jobs from "../views/jobs/JobsMain";
import Error from "../views/errorsAndLoaders/Error"
import JobDetail from "../views/jobs/JobDetail"
import CompanyDetail from "../views/company/CompanyDetail"
import CompaniesByLocation from "../views/company/CompaniesByLocation"
import ProfilePage from "../views/Profile/ProfilePage"
import Jobs_role from "../views/jobs/Jobs_role"
import RecommendedJobs from "../views/jobs/RecommendedJobs"
import AllJobs from "../views/jobs/AllJobsPage"
import SearchFilterJobs from "../views/jobs/SearchFilterJobs"
import JobsByLocation from "../views/jobs/JobsByLocation"
import Support from "../views/support/support.jsx"
import Notifications from "../views/notifications/Notifications"
import RatingsReviews from "../views/Profile/AddRatingsReviews"
import Terms from "../views/Terms"
import PrivacyPolicy from "../views/PrivacyPolicy"
import MyJobs from "../views/myJobs/MyJobsMain"
import DeleteAccount from '../components/DeleteAccount.js';

class Routes extends Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/" component={Jobs} />
                    <Route exact path="/jobs" component={Jobs} />
                    <Route exact path="/my-jobs" component={MyJobs} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/support" component={Support} />
                    <Route exact path="/companyProfile/:_id" component={CompanyDetail} /> 
                    <Route exact path="/companies/:_location" component={CompaniesByLocation} />                   
                    <Route exact path="/jobDetail/:_id" component={JobDetail} />
                    <Route exact path="/ratings" component={RatingsReviews} />
                    {/* <Redirect from="*" to="/error" /> */}
                    <Route exact path="/recommended-jobs" component={RecommendedJobs} />
                    <Route exact path="/jobs/:_city" component={JobsByLocation} />
                    <Route exact path="/all-jobs" component={AllJobs} />
                    <Route exact path="/search" component={SearchFilterJobs} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/delete-account" component={DeleteAccount} />
                    <Route exact path="/error" component={Error} />
                    {/* the role route should be in the end else those paths will be populated in the search bar on the roles page */}
                    <Route exact path="/:_role" component={Jobs_role} />
                    
                </Switch>
            </React.Fragment>
        );
    }
}
export default Routes;
