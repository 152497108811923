import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import BottomAppBar from '../../components/BottomAppBar';
import '../../style/App.css';
import Button from '@material-ui/core/Button';
import { updateLastSeenAt } from '../../Helpers';
import AnalyticsHandler from '../../AnalyticsHandler';
import LogoutDrawer from '../../components/LogoutDrawer';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import logo from '../../assets/logo_header.png';
import { Badge, Dialog, DialogContent } from '@material-ui/core';
import Notifications from '../../components/Notifications';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ApiHandler from '../../ApiHandler';
import { Helmet } from 'react-helmet-async';
import supportIcon from '../../assets/support-icon.svg';
import { useHistory } from "react-router-dom";
import Footer from '../../components/Footer';
import Insta from '../../assets/insta.png';
import Fb from '../../assets/fb.png';
import Linkedin from '../../assets/linkedin.png';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SignInUpDrawer from '../../components/SignInDrawer';
import SnackBarGeneric from '../../components/SnackBarGeneric';

const useStyles = makeStyles((theme) => ({
  headingSection: {
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  pageButton_pri: {
    backgroundColor: '#55c6a9',
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  mobileNotificationIcon: {
    position: 'absolute',
    top: 0,
    right: 43,
    zIndex: 9,
    padding: '12px 0'
  },
  notificationDialogRoot: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    minHeight: '68vh',
    // marginBottom: 40,
    '@media (max-width: 915px)': {
      display: 'none'
    }
  },
  sectionMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 915px)': {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  sectionContainer: {
    width: "95vw",
    height: 150,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: '#fff',
    padding: '0 2em',
    margin: '0.3em 0',
    '@media (max-width: 600px)': {
      height: 120,
    }
  },
  sectionContainerDesktop: {
    width: "45vw",
    height: 150,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: '#fff',
    padding: '0 2em',
    margin: '0.3em 0',
    '@media (max-width: 600px)': {
      height: 120,
    }
  },
  pageInfoText: {
    fontFamily: "Mukta",
    fontWeight: "400",
    fontSize: 17,
    padding: '0 10px',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: 13,
    }
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: 500,
    font: "Roboto",
    '@media (max-width: 600px)': {
      fontSize: 14
    }
  },
  addressTitle: {
    fontSize: 16,
    '@media (max-width: 600px)': {
      fontSize: 14
    }
  },
  addressText: {
    fontSize: 16,
    marginLeft: 8,
    color: '#797979',
    '@media (max-width: 600px)': {
      fontSize: 13
    }
  },
  lineBreak: {
    height: 1.5,
    backgroundColor: '#e1e1e1',
    width: '95%',
    marginTop: '1em',
    padding: '0px 2em'
  },
  footer: {
    '@media (max-width: 915px)': {
      display: 'none'
    }
  }
}))

export default function Support(props) {

  const classes = useStyles();
  const history = useHistory();
  const [navValue, setNavValue] = React.useState(3);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  //let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  //to update user's last seen at timestamp on load and at the time of login
  React.useEffect(() => {
    if (isSignedIn) {
      updateLastSeenAt(); //to update user's last seen at timestamp
    }
  }, [])

  // drawer callback starts
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const [refresh, doRefresh] = React.useState(false);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // Settings drawer callback starts
  const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
  const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);

  const [refreshSettings, doRefreshSettings] = React.useState(false);

  const handleSettingsDrawer = () => {
    setCanSettingsDrawerOpen(true)
    doRefreshSettings(prev => !prev)
  }
  // Settings drawer callback ends

   // snackbar callback starts
   const [snackBarMessage, setSnackBarMessage] = React.useState('')
   const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
   const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
   const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
   const handleSnackBar = (message, severity) => {
     if (severity) {
       setSnackBarMessageSeverity(severity);
     } else {
       setSnackBarMessageSeverity("success");
     }
     setCanSnackBarOpen(true);
     setSnackBarMessage(message);
     doRefreshSnackBar(prev => !prev);
   }
   // snackbar callback ends


  //notification start
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  const closeNotificationDialog = () => {
    setOpenNotificationDialog(false);
  }

  //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

  React.useEffect(() => {
    document.body.classList.add('wallpaper--icon');
    return () => {
      document.body.classList.remove('wallpaper--icon');

    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{"Get in touch - Reach out to our team | Meetworks"}</title>
        <meta name="description" content={"Connect with us if you have inquiries about job listings or want to explore partnership opportunities. Reach out and let's build the future of your career together"} />
        <meta
          name="keywords"
          content={"customer support, helpdesk, feedback form, online job portal, job search sites in india"} />
      </Helmet>
      {/* Top App Bar on Mobile */}
      <AppBar position="fixed" style={{ background: '#fff' }}>
        <Toolbar variant="dense" style={{ padding: 0 }}>
          <div style={{ width: '100%' }}>
            <div className={classes.sectionMobile}>
              <Button
                style={{ display: 'flex' }}
                id="meetLogo_id"
                onClick={() => {
                  history.push({
                    pathname: '/'
                  });
                }}>
                <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
              </Button>
              <Grid
                item xs={9} md={11}
                container
                direction='column'
                justifyContent="center"
                alignItems='flex-start'>

              </Grid>
              {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                <Badge badgeContent={notificationBadgeCount} color="error">
                  <NotificationsOutlinedIcon color='action' onClick={() => setOpenNotificationDialog(true)} />
                </Badge>
              </IconButton>}
              <IconButton
                id="shareButton"
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                onClick={() => {
                  if (isSignedIn) handleSettingsDrawer()
                  else handleDrawer()
                }}
              >
                <SettingsOutlinedIcon />
              </IconButton>

              {/* <IconButton
                  id="shareButton"
                  style={{ position: 'absolute', top: 0, right: 50, zIndex: 9, padding: '12px 0' }}
                // onClick={() => {

                // }}
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={`${classes.headingSection} ${classes.sectionMobile}`} >
        <h1 style={{ alignItems: "center", font: "Roboto", fontWeight: "500", fontSize: 20, marginTop: '9vh', marginBottom: '1em', marginTop: 60 }}>  Contact Us </h1>
        <div style={{ width: "95%", height: "120px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", backgroundColor: '#fff', padding: '0 2em', boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)' }}>
          <span style={{ font: "Mukta", fontWeight: "500", fontSize: 14, padding: '0 10px' }}>Career-related किसी भी सवाल के लिए हमें याद करें!  </span>
          <Button
            id="whatsapp_button"
            variant="contained"
            className={`${classes.pageButton} ${classes.pageButton_pri}`}
            style={{ width: 200, fontSize: '1rem', textTransform: 'capitalize', marginTop: '1em' }}
            onClick={() => {
              AnalyticsHandler.track('Support_whatsapp_BtnClick');
              window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
            }}
          >
            {/* अपने मीत को  */}
            WhatsApp करें
          </Button>
        </div>
        <div className={classes.lineBreak}></div>
        <div style={{ width: "95%", height: "120px", display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: '#fff', marginTop: '1em', padding: '0 2em', boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)' }}>
          <div style={{ display: 'flex', marginBottom: '1em' }}>
            <span style={{ fontSize: 14 }}>Address:</span> <span style={{ fontSize: 12, marginLeft: 12, color: '#797979' }}>Medha Learning Foundation, 407, Dr. Bajinath Road, New Hyderabad, Lucknow - 226007, Uttar Pradesh, India</span>
          </div>
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: 14 }}>Email Id:</span> <span style={{ fontSize: 12, fontWeight: 500, marginLeft: 12, color: '#5bc2a8', textDecoration: 'underline' }}>namaste@meetworks.in</span>
          </div>
        </div>
        <div className={classes.lineBreak}></div>
        <div style={{ width: "95%", height: "120px", display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: '#fff', marginTop: '1em', padding: '0 2em', boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)' }}>
          <span style={{ fontSize: 14, marginBottom: 5, fontWeight: 500, font: "Roboto", }}>Follow us on social media</span>
          <a href='https://www.instagram.com/meet_app/' style={{ display: 'flex', alignItems: 'center', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><img src={Insta} width={20} height={20} style={{ marginRight: 8 }} /><span style={{ color: '#5bc2a8', textDecoration: 'underline' }} >Instagram </span><OpenInNewIcon fontSize='small' color='primary' style={{ fontSize: 12, marginLeft: 5 }} /></a>

          <a href='https://www.facebook.com/meetworks.in' style={{ display: 'flex', alignItems: 'center', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><img src={Fb} width={20} height={20} style={{ marginRight: 8 }} /><span style={{ color: '#5bc2a8', textDecoration: 'underline' }} >Facebook </span><OpenInNewIcon fontSize='small' color='primary' style={{ fontSize: 12, marginLeft: 5 }} /></a>

          <a href='https://www.linkedin.com/company/meetworks' style={{ display: 'flex', alignItems: 'center', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><img src={Linkedin} width={20} height={20} style={{ marginRight: 8 }} /><span style={{ color: '#5bc2a8', textDecoration: 'underline' }} >Linkedin </span><OpenInNewIcon fontSize='small' color='primary' style={{ fontSize: 12, marginLeft: 5 }} /></a>

        </div>
        {/* <div style={{ height: "10%", width: "100%", position: 'absolute', bottom: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <span style={{ font: "Roboto", fontWeight: "400", fontSixe: "14px", color: "#797979" }}> Or contact us via email </span>
          <span style={{ font: "Roboto", fontWeight: "500", fontSize: "16px", color: "#5BC2A8" }}> namaste@meetworks.in </span>
        </div> */}
        {/* Notification starts */}
        <Dialog
          open={openNotificationDialog}
          onClose={closeNotificationDialog}
          fullScreen
        >
          <DialogContent classes={{ root: classes.notificationDialogRoot }}>
            <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
              <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
              <Typography variant='body1' >Notifications</Typography>
            </Grid>
            <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
          </DialogContent>
        </Dialog>
      </div>
      <div className={`${classes.headingSection} ${classes.sectionDesktop}`}>
        <h1 style={{ alignItems: "center", font: "Roboto", fontWeight: "500", fontSize: 25, marginBottom: '1em', marginTop: 65 }}> Contact Us </h1>
        <Grid wrap='nowrap' container style={{ width: '70vw', margin: '20px 0' }} >
          <Grid container item xs={4} style={{ backgroundColor: '#fff', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px', marginRight: 20, padding: '0 10px' }} direction='column' alignItems='center' justifyContent='center' >
            <img src={supportIcon} width={150} height={150} />
            <span className={classes.pageInfoText}>Career-related किसी भी सवाल के लिए हमें याद करें!</span>
            <Button
              id="whatsapp_button"
              variant="contained"
              className={`${classes.pageButton} ${classes.pageButton_pri}`}
              style={{ width: 200, fontSize: '1rem', textTransform: 'capitalize', marginTop: '1em' }}
              onClick={() => {
                AnalyticsHandler.track('Support_whatsapp_BtnClick');
                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
              }}
            >
              WhatsApp करें
            </Button>
          </Grid>
          <Grid xs={8} container item direction='column' alignItems='center' justifyContent='center' >
            <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px' }} className={classes.sectionContainerDesktop}>
              <div style={{ display: 'flex', marginBottom: '1em' }}>
                <span className={classes.addressTitle}>Address:</span> <span className={classes.addressText}>Medha Learning Foundation, 407, Dr. Bajinath Road, New Hyderabad, Lucknow - 226007, Uttar Pradesh, India</span>
              </div>
              <div style={{ display: 'flex' }}>
                <span className={classes.addressTitle}>Email Id:</span> <a href='mailto:namaste@meetworks.in' className={classes.addressText} style={{ color: '#5bc2a8' }} >namaste@meetworks.in</a>
              </div>
            </div>
            <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px' }} className={classes.sectionContainerDesktop}>
              <span className={classes.subtitle}>Follow us on social media</span>
              <a href='https://www.instagram.com/meet_app/' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><img src={Insta} width={20} height={20} style={{ marginRight: 5 }} />Instagram</a>
              <a href='https://www.facebook.com/meetworks.in' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><img src={Fb} width={20} height={20} style={{ marginRight: 5 }} />Facebook</a>
              <a href='https://www.linkedin.com/company/meetworks' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><img src={Linkedin} width={20} height={20} style={{ marginRight: 5 }} />Linkedin</a>

            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <BottomAppBar
        navValue={navValue}
        setNavValue={setNavValue}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        handleDrawer={handleDrawer}
        handleSettingsDrawer={handleSettingsDrawer}
      > </BottomAppBar>
      <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

      <LogoutDrawer
        canDrawerOpen={canSettingsDrawerOpen}
        refresh={refreshSettings}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshSettingsOnly}
        setCanDrawerOpen={setCanSettingsDrawerOpen}
        doRefresh={doRefreshSettings}
        handleSnackBar={handleSnackBar}
      />
      <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />


    </>
  )
}