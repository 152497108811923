var request = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

var OTPURL = "/users/send_otp_meet2";
var ResendOTPURL = "/users/resend_otp_meet2";
var Sign_in_URL = "/users/login_meet2";
var Logout_URL = "/users/logout";
var Image_upload_URL = "/employer/image_upload";
var Get_job_roles_URL = "/select/get_job_roles";
var GetJobCandidatesURL = "/jobposting/getjobcandidates/";
var Get_company_names_URL = "/employer/companies_name_meetV2/";
var sendFollowCompanyURL = "/companies/savefavoritecompanies";
var getNotificationsURL = "/notifications/get_notificationlist";
var notificationReadUnredURL = '/notifications/send_read_flag'

var getCall = (endpoint, callback) => {
  request.get(BASE_URL + endpoint)
    .then(response => {
      return response
    })
    .catch(response => {
      return response
    })
    .then(callback)
}
var postCall = (endpoint, data, callback) => {
  request.post(BASE_URL + endpoint, data, { withCredentials: true })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    })
    .then(callback)
}

var ApiHandler = {
  sendOTP(data, cb) {
    request.post(BASE_URL + OTPURL, data)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  resendOTP(data, cb) {
    request.post(BASE_URL + ResendOTPURL, data)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  signIn: function (mobile, otp, cb) {
    request.post(BASE_URL + Sign_in_URL, { mobile: mobile, otp: otp }, { withCredentials: true })
      .then(res => {
        return res
      })
      .catch(function (response) {
        return response
      })
      .then(cb)
  },
  logout(cb) {
    request.post(BASE_URL + Logout_URL, {}, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  sendEmailForVerification(jid, cb) {
    request.get(BASE_URL + '/users/send_verification_mail/' + jid)
      .then((response => {
        return response
      }))
      .catch(error => {
        return error
      })
      .then(cb)
  },
  profileCompletedFlagToggle(data, cb) {
    request.post(BASE_URL + '/users/profile_completed', data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllNotifications(data, cb) {
    request.post(BASE_URL + getNotificationsURL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  changeUnreadStatus(data, cb) {
    request.post(BASE_URL + notificationReadUnredURL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getJobRoles(cb) {
    request.get(BASE_URL + Get_job_roles_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getjobCandidates(jobid, offset, cb) {
    request.get(BASE_URL + GetJobCandidatesURL + jobid + '/' + offset)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getCompanyNames(cb) {
    request.get(BASE_URL + Get_company_names_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  followCompanyToggle(data, cb) {
    request.post(BASE_URL + sendFollowCompanyURL, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllJobRolesandCount(pp, cb) {
    request.get(BASE_URL + "/companies/getAllJobRolesandCount/" + pp)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getRolesAndOpeningsForLocation(data, cb) {
    request.post(BASE_URL + "/companies/getRolesAndOpeningsForLocation", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getRecommendedJobs(jid, data, cb) {
    request.post(BASE_URL + "/companies/getJobByPreference/" + jid, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getFavCompaniesList(offset, limit, jid, cb) {
    request.get(BASE_URL + "/companies/getAllfavcompany/" + offset + '/' + limit + '/' + jid)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getJobsByCities(cb) {
    getCall("/companies/getTopCitiesandVacancies", cb)
  },
  //getJobsByCity(city, lat, long, offset, limit, cb) {// lat long removed as they are not working
  getJobsByCity(city, offset, limit, cb) {
    request.get(BASE_URL + "/companies/getJobsByCity" + '/' + offset + '/' + limit + '?select=' + city)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getCompaniesByDistrict(district, offset, limit, cb) {
    request.get(BASE_URL + "/companies/getCompanyByDistrict" + '/' + offset + '/' + limit + '?district=' + district)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getCompaniesByCities(cb) {
    getCall("/select/v2/analytics_top_locations/0/10", cb)
  },
  getTopRatedCompanies(cb) {
    getCall("/companies/getTopRatedCompanies", cb)
  },
  getAllJobPosts(data, cb) {
    request.post(BASE_URL + "/companies/getAllJobPosts", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getNearestJobs(data, cb) {
    request.post(BASE_URL + "/companies/v2/getNearestJobs", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getFarNearestJobs(data, cb) {
    request.post(BASE_URL + "/companies/v2/getFarNearestJobs", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getWorkHomeJobs(offset, limit, cb) {
    request.get(BASE_URL + "/companies/getWorkHomeJobs" + '/' + offset + '/' + limit)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllAppliedJobPosts(data, cb) {
    request.post(BASE_URL + "/users/getapplied_jobdetails", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllInvites(jobseekerid, offset, limit, cb) {
    request.get(BASE_URL + "/users/getallinvites" + '/' + offset + '/' + limit + '?jobseeker_id=' + jobseekerid)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getJobPostForaRole(data, cb) {
    request.post(BASE_URL + "/companies/getJobPostForaRole", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getNearestJobsForJobRole(data, cb) {
    request.post(BASE_URL + "/companies/getNearestJobPostForJobRole ", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getFarNearestJobsForJobRole(data, cb) {
    request.post(BASE_URL + "/companies/getFarNearestJobPostForJobRole ", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getWFHJobsForJobRole(data, cb) {
    request.post(BASE_URL + "/companies/getWFHJobPostForJobRole", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getJobDetails(data, cb) {
    request.post(BASE_URL + "/companies/getJobDetails", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  applyToJob(data, cb) {
    request.post(BASE_URL + "/users/applytojob", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  isAppliedJob(ppJobseekerId, ppJobId, cb) {
    request.get(BASE_URL + "/companies/isAppliedJob/" + ppJobseekerId + "/" + ppJobId)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getScreeningFormResponse(ppJobseekerId, ppJobId, cb) {
    request.get(BASE_URL + "/jobposting/screeningfield/" + ppJobseekerId + "/" + ppJobId)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  postScreeningForm(data, cb) {
    request.post(BASE_URL + "/jobposting/screeningfield", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getJobCountforARole(ppJobId, cb) {
    request.get(BASE_URL + "/companies/getJobCountforaRole/" + ppJobId)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getCompanyDetails(data, cb) {
    request.post(BASE_URL + "/companies/getCompanyDetails", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getCompanies_V2(cb) {
    request.get(BASE_URL + "/employer/companies_name_meetV2")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getJobRoles_V2(cb) {
    request.get(BASE_URL + "/select/get_jobroles")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getInstitutes_V2(cb) {
    request.get(BASE_URL + "/select/get_institutes")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getSpecializations_V2(cb) {
    request.get(BASE_URL + "/select/get_specializations")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getCourseTypes_V2(cb) {
    request.get(BASE_URL + "/select/get_course_types")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getCourses_V2(cb) {
    request.get(BASE_URL + "/select/get_courses")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getEmploymentTypes(cb) {
    request.get(BASE_URL + "/select/get_experience_type")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllSectors(cb) {
    request.get(BASE_URL + "/select/get_all_sectors")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getFavCompanies(data, cb) {
    request.post(BASE_URL + "/companies/getfavcompany", data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getExperience(data, cb) {
    request.post(BASE_URL + "/users/get_experience_user_meet2", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getProfile(data, cb) {
    request.post(BASE_URL + "/users/get_jobseeker_profile_meet2", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getQualifications(data, cb) {
    request.post(BASE_URL + "/users/get_qualification_user_meet2", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  addEdu(data, cb) {
    request.post(BASE_URL + "/users/add_qualification", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  editEdu(data, cb) {
    request.post(BASE_URL + "/users/edit_qualification", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  deleteEdu(data, cb) {
    const config = {
      data: data,
      withCredentials: true
    }
    request.delete(BASE_URL + "/users/delete_qualification", config)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  addExp(data, cb) {
    request.post(BASE_URL + "/users/add_experience", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  editExp(data, cb) {
    request.post(BASE_URL + "/users/edit_experience", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  deleteExp(data, cb) {
    request.delete(BASE_URL + "/users/delete_experience", { data, withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  // deleteExp(data, cb) {
  //   request.delete(BASE_URL + "/users/delete_experience", {data})
  //     .then(response => cb)
  //     .catch(error => { console.log(error.message) }
  //     )
  // },
  uploadProfilePic(data, cb) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true
    }
    request.post(BASE_URL + "/users/image_upload_meet2", data, config)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error);
        return error
      })
      .then(cb)
  },
  addEditAboutMe(data, cb) {
    request.post(BASE_URL + "/users/create_profile_meet2", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  deleteProfilePic(data, cb) {
    request.delete(BASE_URL + "/users/deleteProfilePicture", { data, withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  addEditLocation(data, cb) {
    request.post(BASE_URL + "/users/add_jobseeker_location", data, { withCredentials: true })
      .then(response => {
        return response;
      }).catch(error => {
        return error
      }).then(cb)
  },
  deleteAccount(data, cb) {
    request.delete(BASE_URL + "/users/delete_jobseeker_account", { data, withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  updateUserLastSeenAt(data, cb) {
    request.post(BASE_URL + "/users/updateUserLastSeen", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getReviewsByCompany(offset, limit, company_id, cb) {
    request.get(BASE_URL + "/companies/getReviews/" + offset + "/" + limit + "/" + company_id)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllReviewTags(cb) {
    request.get(BASE_URL + "/select/get_all_review_tags")
      .then(response => {
        return response
      })
      .catch(error => error)
      .then(cb);
  },
  uploadReviewVideo(data, experienceId, cb) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true,
    }
    request.post(BASE_URL + "/users/video_upload/" + experienceId, data, config)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error);
        return error
      })
      .then(cb)
  },
  uploadReviewAudio(data, experienceId, cb) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      withCredentials: true,
    }
    request.post(BASE_URL + "/users/audio_upload/" + experienceId, data, config)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error);
        return error
      })
      .then(cb)
  },
  deleteReviewVideo(param, cb) {
    request.delete(BASE_URL + "/users/delete_video/" + param, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  deleteReviewAudio(param, cb) {
    request.delete(BASE_URL + "/users/delete_audio/" + param, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  sendDeviceTokenToFCM(data, cb) {
    request.post(BASE_URL + "/users/savedevicetoken", data, { withCredentials: true })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    })
    .then(cb)
},
subscribeDeviceToTopic(data, cb) {
  request.post(BASE_URL + "/notifications/subscribe_topic", data, { withCredentials: true })
  .then(response => {
    return response;
  })
  .catch(error => {
    return error;
  })
  .then(cb)
},
  getAllSkills(cb) {
    request.get(BASE_URL + "/select/get_all_skills")
      .then(response => {
        return response
      })
      .catch(error => error)
      .then(cb);
  },
  getUserSkills(jid, cb) {
    request.get(BASE_URL + "/users/skills/" + jid)
      .then(response => {
        return response
      })
      .catch(error => error)
      .then(cb);
  },
  addSkill(data, cb) {
    request.post(BASE_URL + "/users/skills", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  editSkill(data, cb) {
    request.post(BASE_URL + "/users/updateskills", data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  deleteSkill(param, cb) {
    request.delete(BASE_URL + "/users/skills/" + param, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  searchAndFilterJobs(data, param, cb) {
    request.post(BASE_URL + "/companies/searchandfilterjobs" + param, data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getAllLocations(cb) {
    getCall("/select/get_all_locations", cb)
  },
  getAllLocations(cb) {
    getCall("/select/get_all_locations", cb)
  },
  getPreferences(data, cb) {
    postCall("/users/user_pref/", data, cb)
  },
  addPreferences(data, cb) {
    postCall("/users/add_pref", data, cb)
  },
  editPreferences(data, cb) {
    postCall("/users/updateUser_pref", data, cb)
  },
  deletePreferences(data, cb) {
    const config = {
      data: data,
      withCredentials: true
    }
    request.delete(BASE_URL + "/users/delete_pref", config)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getSearchAutocompleteList(searchTerm, cb) {
    request.get(BASE_URL + "/companies/automated_search_Jobs?search_text=" + searchTerm)
      .then(response => {
        return response
      })
      .catch(error => error)
      .then(cb);
  },
}

module.exports = ApiHandler;