import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import ApiHandler from '../ApiHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import loader from '../assets/logo-loader.gif'


const Notifications = (props) => {
    const { classes } = props;
    const history = useHistory();

    const [allNotifications, setAllNotifications] = React.useState([]);
    const [totalNotificationCount, setTotalNotificationCount] = React.useState(0);
    let offset = 0;
    const jobseeker_id = props.localIsLoggedIn ? localStorage.getItem('jobseeker_id') : '';

    //fetch all notifications
    React.useEffect(() => {
        let jobseekerID = localStorage.getItem("jobseeker_id");
        let payload = {
            "offset": offset,
            "limit": 100,
            "jobseeker_id": jobseekerID
        }
        ApiHandler.getAllNotifications(payload, res => {
            let unreadNotif = 0;
            if (res.status == 200) {
                unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
                props.setNotificationBadgeCount(unreadNotif);
                setAllNotifications(res.data[0].results);
                if (res.data[0].totalCount.length !== 0) {
                    setTotalNotificationCount(res.data[0].totalCount[0].count);
                }
            }
        })
    }, []);

    //redirect to the page when user clicks on a notification
    const redirectToPage = (type) => {
        let currentPath = history.location.pathname;
        if (type === 'expr_review') {
            if (currentPath === '/profile') {
                props.setNotificationBar(false);
            } else {
                history.push({
                    pathname: '/profile'
                })
            }
        }
        else if (type === 'medha_alumnus_status') {
            if (currentPath === '/profile') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/profile'
                })
            }
        } 
        else if (type === 'InvitedJobStatus') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/my-jobs'
                })
            }
        } 
        else if (type === 'InvitedJobseekers') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {
                history.push({
                    pathname: '/my-jobs'
                })
            }
        } 
        else if (type === 'AppliedJobStatus') {
            if (currentPath === '/my-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/my-jobs'
                })
            }
        } 
        else if (type === 'jobpost') {
            if (currentPath === '/all-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/all-jobs'
                })
            }
        } 
        else if (type === 'all_jobpostings') {
            if (currentPath === '/all-jobs') {
                props.setNotificationBar(false);
            } else {

                history.push({
                    pathname: '/all-jobs'
                })
            }
        } 
        else {
            history.push({
                pathname: '/all-jobs'
            })
        }
    }

    //change read/unread status
    const markAsReadUnread = (e, index, item) => {
        let id = e.currentTarget.getAttribute('id');
        let payload = {
            "_id": id
        }
        if (!item.is_read) {
            ApiHandler.changeUnreadStatus(payload, res => {
                if (res.status == 200) {
                    redirectToPage(item.notification_type)
                }
            })
        } else {
            redirectToPage(item.notification_type)
        }
    }

    const fetchMoreNotifications = () => {
        offset += 100;
        let payload = {
            "offset": offset,
            "limit": 100,
            "jobseeker_id": jobseeker_id
        }
        ApiHandler.getAllNotifications(payload, res => {
            setAllNotifications(allNotifications.concat(res.data[0].results));
        })
    }

    //set notificaton time
    const timeAgo = (date) => {
        const seconds = Math.floor((new Date() - date) / 1000);
        let interval = Math.floor(seconds / 31536000);
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + 'd';
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + 'h';
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + 'm';
        }
        if (seconds < 10) return 'just now';

        return Math.floor(seconds) + 's';
    };

    return (
        <>
            <Grid container style={{ marginTop: props.variant === 'mobile' ? 60 : 0 }}>
                <InfiniteScroll
                    dataLength={allNotifications.length}
                    next={fetchMoreNotifications}
                    hasMore={allNotifications.length !== totalNotificationCount}
                    loader={<img src={loader} width={50} height={50} />}
                    scrollThreshold={0.7}
                    scrollableTarget='notificationDiv'
                >
                    {allNotifications.map((item, index) => {
                        return <Grid key={item._id} id={item._id} onClick={(e) => markAsReadUnread(e, index, item)} style={{ backgroundColor: !item.is_read ? 'rgb(85 198 169 / 20%)' : '#fff', minHeight: 80, padding: '10px 15px', borderBottom: '1px solid #afafaf', cursor: 'pointer' }} container item>
                            <Grid xs={1} item>
                                <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: !item.is_read ? '#5bc2a8' : '#AFAFAF', alignSelf: 'flex-start', marginTop: 5 }}></div>
                            </Grid>
                            <Grid xs={10} direction='column' container item style={{paddingRight: props.variant === 'mobile' ? '2em' : '3em'}}>
                                <Typography style={{fontWeight: 700}} variant='body2'>{item.notification_title}</Typography>
                                <Typography variant='body2'>{item.notification_data}</Typography>
                                {/* <Typography variant='body2'>hello</Typography> */}
                            </Grid>
                            <Grid style={{ alignSelf: 'flex-start' }} xs={1}>
                                <span style={{ color: '#afafaf', fontSize: 12 }}>{timeAgo(new Date(item.notification_date))}</span>
                            </Grid>
                        </Grid>
                    })}
                </InfiniteScroll>
            </Grid>
        </>
    )
}

export default Notifications