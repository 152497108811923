import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import OtpInput from 'react-otp-input';

import ApiHandler from '../ApiHandler';
import AnalyticsHandler from '../AnalyticsHandler';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    height: 60,
    flexGrow: 1,
  },
  drawerPaper: {
    borderRadius: '20px 20px 0px 0px',
  },
  drawer: {
    width: 'auto',
    height: 'auto',
    minHeight: 300,
    paddingTop: 40,
    background: '#FFFFFF',
    boxShadow: ' 0px -4px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerCloseBtn: {
    alignSelf: 'flex-end',
    margin: 8,
  },
  drawerBackBtn: {
    position: 'absolute',
    alignSelf: 'flex-start',
    margin: 8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 300,
    marginTop: 20,
  },
  TfRoot: {
    width: '100%',
    marginBottom: 45,
    //marginBottom: 25,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfBlockRoot: {
    //width: '100%',
    //marginBottom: 20,
    borderRadius: 4,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      width: 48,
      textAlign: 'center',
      '&:focus': {
        background: theme.palette.primary.light,
      },
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  drawerActionButton: {
    width: '100%',
    maxWidth: 200,
    height: 36,
    marginBottom: 15,
    backgroundColor: '#14967B',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#14967B',
    },
  },
  linkButton: {
    textTransform: 'unset',
    color: '#14967B',
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  fcRoot: {
    marginRight: 3,
  },
  hrefLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    }
  }
}));

export default function SignInUpDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [toSignIn, setToSignIn] = React.useState(true);
  const [mobileInput, setMobileInput] = useState('');
  const [isSendOTPDisabled, setIsSendOTPDisabled] = React.useState(true);
  const [isSignInDisabled, setIsSignInDisabled] = React.useState(true);
  const [returningUser, setReturningUser] = useState({ isReturning: false, userName: "", profileCompleted: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [checkedTc, setCheckedTc] = React.useState(true);
  const [checkedNotifs, setCheckedNotifs] = React.useState(true);
  const [signInStage, setSignInStage] = React.useState(false);
  const [isResendOTPTimerDisplayed, setIsResendOTPTimerDisplayed] = React.useState(true);
  const [isOTPStage, setIsOTPStage] = React.useState(false);
  const [timerValue, setTimerValue] = useState(60);

  const resetSignInDrawer = () => {
    setMobileInput("");
    setCheckedTc(true);
    setCheckedNotifs(true);
    setToSignIn(true);
    setIsOTPStage(false);
    setSignInStage(false);
    resetOTPTimer();
    resetErrorMsg();
  }

  const resetErrorMsg = () => {
    setIsAlertOpen(false);
    setTimeout(() => {
      setErrorMessage("");
    }, 200);
  }

  const goToSendOTPScreen = () => {
    setCode("");
    setIsOTPStage(false);
    setSignInStage(false);
  }

  const otpInputRef = React.useRef("null");

  const resetAndFocusOTPInput = () => {
    setCode("");
    if (otpInputRef.current) {
      otpInputRef.current.querySelector("input").focus();
    }
  }

  const resetOTPTimer = () => {
    setTimerValue(60);
    setIsTimerOn(true);
    setIsResendOTPTimerDisplayed(true);
  }

  const handleSendOTP = (event) => {
    let mobile = mobileInput;
    let data = { mobile: mobile }
    // setIsOTPStage(true);
    // setSignInStage(true);

    // resetOTPTimer();
    // resetErrorMsg();
    ApiHandler.sendOTP(data, function (res) {
      if (res.status === 200) {
        setIsOTPStage(true);
        setCode("");
        setSignInStage(true);
        resetOTPTimer();
        resetErrorMsg();
      }
      else {
        setErrorMessage("Oops! Something went wrong! Please try again.");
        setIsAlertOpen(true);
      }
    })
  }

  const handleAutoSendNewOTP = (event) => {
    let mobile = mobileInput;
    let data = { mobile: mobile }
    resetErrorMsg();
    ApiHandler.sendOTP(data, function (res) {
      if (res.status === 200) {
        resetOTPTimer();
      }
      else {
        setErrorMessage("Oops! Something went wrong! Please try again.");
        setIsAlertOpen(true);
      }
    })
  }

  const [code, setCode] = useState("");

  const handleOTPInputChange = (code) => {
    setCode(code);
    resetErrorMsg();
  }

  const handleResendOTPTimerDisplayed = (event) => {
    setIsResendOTPTimerDisplayed(false);
  }

  const handleResendOTPClick = (event) => {
    let mobile = mobileInput;
    let data = { mobile: mobile }
    ApiHandler.resendOTP(data, function (res) {
      if (res.status === 200) {
        resetOTPTimer();
        resetAndFocusOTPInput();
        resetErrorMsg();
      }
      else {
        let statusCode = res.response.status;
        let msg = res.response.data.message;
        if (statusCode === 400) {
          if (msg === "Mobile number is not valid") {
            setErrorMessage("Please enter a valid Mobile number.");
            setIsAlertOpen(true);
          } else if (msg === "otp_expired") {
            handleAutoSendNewOTP();
            resetAndFocusOTPInput();
          } else if (msg === "OTP already verified") {
            setErrorMessage("OTP is already verified.");
            setIsAlertOpen(true);
          } else if (msg === "OTP retry count maxed out") {
            setErrorMessage("You have reached the maximum limit to resend OTP. Please try after sometime.");
            setIsAlertOpen(true);
          } else if (msg === "Max limit reached for this otp verification") {
            setErrorMessage("Max limit reached for this OTP verification.");
          } else {
            setErrorMessage("Oops! Something went wrong!");
          }
        } else if (statusCode === 500) {
          setErrorMessage("Oops! Something went wrong! Please try again.");
        } else {
          setErrorMessage("Oops! Something went wrong!");
        }
      }
    })
  }

  const handleCheckedTc = (event) => {
    setCheckedTc(event.target.checked);
    resetErrorMsg();
  }

  const handleCheckedNotifs = (event) => {
    setCheckedNotifs(event.target.checked);
  }

  const handleSignInClick = () => {
    let mobile = mobileInput;
    let otp = code;

    if (mobile === '') {
      setErrorMessage("Please enter mobile number.");
      setIsAlertOpen(true);
    } else if (otp.toString().length !== 4) {
      setErrorMessage("Please enter OTP.");
      setIsAlertOpen(true);
    } else if (checkedTc === false) {
      setErrorMessage("Please read and agree to the Terms and Conditions to Sign in.");
      setIsAlertOpen(true);
    }
    else {
      ApiHandler.signIn(mobile, otp, function (res) {
        if (res.status === 200) {
          // window.analytics.identify({  //identify user
          //   name: res.data.result.user_first_name + " " + res.data.result.user_last_name,
          //   mobile: res.data.result.user_mobile,
          // });
          AnalyticsHandler.identify();
          if(process.env.REACT_APP_API_ROOT.trim() === "https://api.meetworks.in"){
            window.mixpanel.people.set_once({
              $name: res.data.result.user_first_name + " " + res.data.result.user_last_name,
              $mobile: res.data.result.user_mobile,
            })
          }
          
          AnalyticsHandler.track('SignInDrawer_signIn_Success');
          setToSignIn(false);
          localStorage.setItem('jobseeker_id', res.data.result._id);
          localStorage.setItem('profile_completed', res.data.result.profile_completed);
          let msg = res.data.message;
          if (msg === "Successfully Loggedin") { //set returning user
            setReturningUser({ isReturning: true, userName: res.data.result.user_first_name, profileCompleted: res.data.result.profile_completed });
            localStorage.setItem('isNewUser', false);
            setTimeout(() => {
              props.setCanDrawerOpen(false);
              props.doRefresh(prev => !prev);
              resetSignInDrawer();
            }, 7000);
          }
          if (msg !== "Successfully Loggedin") { //set new user
            localStorage.setItem('isNewUser', true);
          }
          
          localStorage.setItem('is_logged_in', true);
          props.setIsSignedIn(true);
          if (!res.data.result.profile_completed) setTimeout(() => { handleGoToProfileClick() }, 7000);// go to profile if profile is not complete

        } else if (res.response) {
          let statusCode = res.response.status;
          let msg = res.response.data.message;

          if (statusCode === 400) {
            if (msg === "Mobile no. not found") {
              setErrorMessage("Mobile number not found.");
            } else if (msg === "OTP expired") {
              setErrorMessage("OTP expired! No worries, we can send a new one.");
              goToSendOTPScreen();
              // setCode("");
              // setIsOTPStage(false);
              // setSignInStage(false);
            } else if (msg === "Mobile no. already verified") {
              setErrorMessage("Oops! Something went wrong! No worries, we can send a new OTP.");
              goToSendOTPScreen();
            } else if (msg === "OTP not match") {
              setErrorMessage("Please enter the correct OTP.");
            } else if (msg === "Max limit reached for this otp verification") {
              setErrorMessage("Max limit reached for this OTP verification. Get a new OTP.");
              goToSendOTPScreen();
              // setCode("");
              // setIsOTPStage(false);
              // setSignInStage(false);
            } else if (msg === "error in login") {
              setErrorMessage("Oops! Something went wrong!");
            } else {
              setErrorMessage("Oops! Something went wrong!");
            }
          } else {
            setErrorMessage("Oops! Something went wrong!");
          }
          setIsAlertOpen(true);
        }
      })
    }
  }

  const handleGoToProfileClick = () => {
    //reset the signin drawer starts
    setMobileInput("");
    setCheckedTc(true);
    setCheckedNotifs(true);
    setToSignIn(true);
    setIsOTPStage(false);
    setSignInStage(false);
    resetOTPTimer();
    resetErrorMsg();

    history.push({
      pathname: '/profile'
    });

    props.setCanDrawerOpen(false);
    props.doRefresh(prev => !prev);
  }

  const handleCloseDrawerAndRemain = () => {
    // history.push({
    //   pathname: '/'
    // });
    props.setCanDrawerOpen(false);
    props.doRefresh(prev => !prev);
    resetSignInDrawer();
  }

  //Drawer starts
  const [drawerState, setDrawerState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open === false) {
      setMobileInput("");
      setCheckedTc(true);
      setCheckedNotifs(true);
      setToSignIn(true);
      setIsOTPStage(false);
      setSignInStage(false);
      resetOTPTimer();
      resetErrorMsg();
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  //Drawer ends

  const signIn = (anchor) => {
    return (
      <div className={classes.drawer} id="sign_in_section" role="presentation">
        {/* <IconButton
          id="drawerclose_btn_id"
          className={classes.drawerCloseBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}> <CloseIcon style={{ color: '#999', cursor: 'pointer' }}></CloseIcon></IconButton> */}
        {toSignIn
          ?
          <React.Fragment>

            <Typography variant="h6" color="primary" style={{ marginBottom: 20, fontWeight: 500 }}>
              Sign in to move ahead in your career.
              {/* अगर आपका Meeत पे account है login करें */}
            </Typography>

            <Collapse in={isAlertOpen}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    id="alertclose_btn_id"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { resetErrorMsg(); }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <div>
                  <Typography variant="body1">
                    {errorMessage}
                  </Typography>
                </div>
              </Alert>
            </Collapse>

            <form noValidate autoComplete="off" className={classes.form}>
              <TextField
                required
                id="mobileInput_id"
                value={mobileInput}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  let newVal = e.target.value.replace(/[^0-9]/g, '');
                  setMobileInput(newVal);
                  resetErrorMsg();
                }}
                variant="outlined"
                placeholder="Mobile number डालें"
                classes={{ root: classes.TfRoot }}
                autoFocus
              />

              {isOTPStage ? (
                <React.Fragment>
                  <Grid container justifyContent="center" ref={otpInputRef}>
                    <OtpInput
                      value={code}
                      onChange={handleOTPInputChange}
                      numInputs={4}
                      separator={<span style={{ width: "12px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid transparent",
                        background: "#f5f5f5",
                        borderRadius: "4px",
                        width: "48px",
                        height: "48px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue"
                      }}
                      focusStyle={{
                        background: "#D5F5ED",
                        outline: "none"
                      }}
                    />
                  </Grid>

                  {isResendOTPTimerDisplayed ?
                    <Typography
                      variant="body1"
                      style={{ color: theme.palette.common.grey, margin: '15px 0 10px' }}>
                      Resend OTP in
                      <span
                        style={{ color: theme.palette.common.red, fontWeight: 500 }}
                      //onClick={handleResendOTPTimerDisplayed}
                      > {timerValue} s
                      </span>
                    </Typography>
                    :
                    <Button
                      id="resend_otp_btn_id"
                      color="primary"
                      onClick={handleResendOTPClick}
                      style={{ margin: '15px 0 10px', padding: 0, lineHeight: 1.5, textTransform: 'unset' }}>
                      Resend OTP
                    </Button>
                  }
                </React.Fragment>
              ) :
                <>
                  <Button
                    id="send_otp_btn_id"
                    variant="contained"
                    onClick={handleSendOTP}
                    className={classes.drawerActionButton}
                    disabled={isSendOTPDisabled}>
                    Send OTP
                  </Button>

                  {/* <Typography className={classes.pageInfoText}
                    style={{ paddingBottom: 10 }}
                    variant="subtitle2">या</Typography>

                  <Typography className={classes.pageInfoText}
                    style={{ paddingBottom: 25 }}
                    variant="subtitle1">अपना account बनाने के लिए हमें <a className={classes.hrefLink}
                      href="https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team">Whatsapp करें</a>।</Typography> */}

                </>

              }

              {signInStage &&
                (
                  <React.Fragment>
                    <Grid item xs container direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} style={{ padding: '0 10px', marginBottom: '-10px' }}>
                        <FormControlLabel
                          classes={{ label: classes.checkboxLabel, root: classes.fcRoot }}
                          control={<Checkbox
                            name="tnc"
                            defaultChecked
                            size="small"
                            icon={icon}
                            onChange={handleCheckedTc}
                            checkedIcon={checkedIcon} />}
                          label="I agree to the" /><a href="https://www.meetworks.in/terms" target="_blank" style={{ textDecoration: 'none', color: theme.palette.common.blueDark }}><span style={{ fontSize: 12 }}>Terms and Conditions</span></a>
                      </Grid>
                      <Grid item xs={12} style={{ padding: '0 10px', marginBottom: 20 }}>
                        <FormControlLabel
                          classes={{ label: classes.checkboxLabel, root: classes.fcRoot }}
                          control={<Checkbox
                            id="send_notifications"
                            name="notifs"
                            defaultChecked
                            onChange={handleCheckedNotifs}
                            size="small"
                            icon={icon}
                            checkedIcon={checkedIcon} />}
                          label="मुझे WhatsApp पर notifications भेजें। " />
                      </Grid>
                    </Grid>

                    <Button
                      id="sign_in_btn_id"
                      variant="contained"
                      onClick={handleSignInClick}
                      className={classes.drawerActionButton}
                      disabled={isSignInDisabled}
                    >
                      Sign In
                    </Button>
                  </React.Fragment>
                )}
            </form>
          </React.Fragment>
          : <React.Fragment>
            <div className={classes.form}>
              <Typography variant="h6" color="primary" style={{ marginBottom: 20, fontWeight: 500 }}>
                {returningUser.isReturning && returningUser.userName ? "Welcome back " + returningUser.userName + "!" : "You have signed in successfully!"}
              </Typography>
              {!returningUser.profileCompleted ?
                <Typography variant="body1" style={{ marginBottom: 24, fontWeight: 600, fontSize: '1rem' }}>
                  {"अपनी profile complete करें और अपनी पसंद की jobs पर apply करें!"}
                </Typography>
                : null
              }

              <Button
                id="goToProfile_btn_id"
                variant="contained"
                className={classes.drawerActionButton}
                onClick={returningUser.isReturning && returningUser.profileCompleted ? handleCloseDrawerAndRemain : handleGoToProfileClick}
              >
                {/* {returningUser.isReturning && returningUser.profileCompleted ? "Apply to Jobs" : "Go to Profile"} */}
                Okay
              </Button>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }

  const icon = <CheckBoxOutlineBlankIcon style={{ color: "#E2E2E2" }} fontSize="small" />;
  const checkedIcon = <CheckBoxIcon style={{ color: "#5BC2A8" }} fontSize="small" />;

  //detect timer value to stop the timer
  const [isTimerOn, setIsTimerOn] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (isTimerOn && timerValue !== 0) {
      timerInterval = setInterval(() => {
        setTimerValue((prev) => (--prev));
      }, 1000);
    } else {
      setIsResendOTPTimerDisplayed(false);
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval)
  }, [isTimerOn, timerValue])

  //mobile input validation
  useEffect(() => {
    if (mobileInput.length === 10) {
      setIsSendOTPDisabled(false);
      setIsSignInDisabled(false);
    } else {
      setIsSendOTPDisabled(true);
      setIsSignInDisabled(true);
    }
  }, [mobileInput])

  // open drawer on trigger from parent component
  useEffect(() => {
    if (props.canDrawerOpen === true) {
      setDrawerState({ ...drawerState, ['bottom']: true });
    }
    else {
      setDrawerState({ ...drawerState, ['bottom']: false });
    }
  }, [props.refresh]);

  return (
    <div>
      <Drawer anchor='bottom'
        open={drawerState['bottom']}
        onClose={toggleDrawer('bottom', false)}
        style={{ background: 'none' }} classes={{ paper: classes.drawerPaper }}>
        {signIn('bottom')}
      </Drawer>
    </div>
  );
}