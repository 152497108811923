import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
//import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
//import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import IconButton from '@material-ui/core/IconButton';

import logo from '../assets/logo_header.png';
import { useHistory } from "react-router-dom";
import ApiHandler from '../ApiHandler';
import AnalyticsHandler from '../AnalyticsHandler';
import FeedbackAlert from './FeedbackAlert';
import { Badge, Grid, Typography } from '@material-ui/core';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import Notifications from './Notifications';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    //minHeight: 60,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.17)',
    [theme.breakpoints.down('xs')]: {
      top: 'auto',
      bottom: 0,
      backgroundColor: theme.palette.primary.paper,
      boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.17)',
    },
  },
  toolbarRoot: {
    //minHeight: 60,
  },
  toolbarGutters: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 2,
    },
  },
  headerButton: {
    textTransform: "none",
    width: 100,
    height: 35,
  },
  headerButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  headerButton_sec: {
    backgroundColor: "transparent",
    color: theme.palette.primary.dark,
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  flexContainer: {
    justifyContent: 'space-evenly'
  },
  navTabBar: {
    //height: 60,
    flexGrow: 1,
    //paddingLeft: 12,
    [theme.breakpoints.down('md')]: {
      //height: 50,
    },
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    minHeight: 60,
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      // minHeight: 50,
      minHeight: 54,
      minWidth: 90,
    },
  },
  indicator: {
    height: 3,
    top: 0,
    display: 'none'
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      marginBottom: '0!important',
      fontSize: 13,
      '& .MuiTab-wrapper > *:first-child': { //svg
        marginBottom: 0,
        fontSize: 20,
      },
      '& .MuiTab-wrapper > svg': { //svg
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: theme.palette.background.paper,
        //transition: 'all 1s ease-in'
      },
      '& .MuiTab-wrapper > svg.selectedLabel': {
        backgroundColor: theme.palette.primary.light,
        padding: '2px 10px',
        borderRadius: 10,
      }
    },
  },
  selected: {
    fontWeight: 700,
    //  color: theme.palette.primary.light,
    //backgroundColor: theme.palette.primary.light,
  },
  textColorPrimary: {
    color: theme.palette.common.grey,
  },
  selectedLabel: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    padding: '2px 10px',
    borderRadius: 10,
  },
  desktopNotificationContainer: {
    width: '35vw',
    position: 'fixed',
    top: 55,
    backgroundColor: '#fff',
    zIndex: 1300,
    right: 16,
    boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 25%)',
    height: '67vh',
    overflow: 'auto',
    borderRadius: 5,
    [theme.breakpoints.up('lg')]: {
      top: 60
    }
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function MainAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(props.navValue);
  const history = useHistory();
  const [localIsLoggedIn, setLocalIsLoggedIn] = React.useState(props.isSignedIn);

  const matchesMinHeight = useMediaQuery('(min-height:500px)');
  const matchesMinWidth = useMediaQuery('(min-width: 500px)');
  //console.log(navValue)
  React.useEffect(() => {
    setLocalIsLoggedIn(props.isSignedIn)
  }, [props.isSignedIn])

  const handleNavChange = (event, newValue) => {
    // if(props.denyTabChange){
    //   props.denyTabChange();
    //   return
    // }
    setNavValue(newValue);
    if (props.setNavValue) {
      props.setNavValue(newValue);
    }
    if (newValue === 0) {
      if (props.isSaveBtnDisabled === false) {
        props.denyTabChange();
        return
      }
      AnalyticsHandler.track('BottomAppBar_findWork_BtnClick');
      if (props.showDefaultJobsPageState) {
        props.showDefaultJobsPageState();
      }
      // if (props.setDisplaySearchBar) {
      //   props.setDisplaySearchBar(false);
      // }
      // if (props.setDisplayAllJobs) {
      //   props.setDisplayAllJobs(false);
      // }
      history.push({
        pathname: '/'
      });
      // if (props.executeScroll) {
      //   props.executeScroll();
      // }
      // if (props.setStartedTyping){
      //   props.setStartedTyping(false);
      // }
    }
    else if (newValue === 1) {
      if (props.isSaveBtnDisabled === false) {
        props.denyTabChange();
        return
      }
      AnalyticsHandler.track('BottomAppBar_myjobs_BtnClick');
      history.push({
        pathname: '/my-jobs'
      });
    }
    else if (newValue === 2) {
      if (props.isSaveBtnDisabled === false) {
        props.denyTabChange();
        return
      }
      AnalyticsHandler.track('BottomAppBar_profile_BtnClick');
      history.push({
        pathname: '/profile'
      });
    }
    else if (newValue === 3) {
      if (props.isSaveBtnDisabled === false) {
        props.denyTabChange();
        return
      }
      AnalyticsHandler.track('BottomAppBar_support_BtnClick');
      history.push({
        pathname: '/support'
      });
    }


    // } else if (newValue === 4) {
    //   history.push({
    //     pathname: '/analytics'
    //   });
    // }
    // }, 200)
  };

  const signOut = () => {
    // test code to manage the session cookie issues
    let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
    if (!sessionCookie) {
      localStorage.setItem('company_id', "");
      localStorage.setItem('company_email_id', "");
      localStorage.setItem('company_name', "");
      localStorage.setItem('employer_name', "");
      localStorage.removeItem('is_logged_in');
      props.setIsSignedIn(false);
      handleAlert('You have successfully logged out! Please log in to access all your account information.', 'success');
      return;
    }
    ApiHandler.logout(response => {
      if (response.status == 200) {
        handleAlert('You have successfully logged out! Please log in to access all your account information.', 'success');
        localStorage.setItem('company_id', "");
        localStorage.setItem('company_email_id', "");
        localStorage.setItem('company_name', "");
        localStorage.setItem('employer_name', "");
        localStorage.removeItem('is_logged_in');
        props.setIsSignedIn(false);
      }
      else {
        handleAlert('Oops! Something went wrong. Please try again.', 'error');
      }
    });
  }

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  const [deskNotifyBarDisplay, setDeskNotifyBarDisplay] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

  return (
    // <ThemeProvider theme={MyTheme}>
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar} style={{ display: matchesMinHeight || matchesMinWidth ? "inherit" : "none" }}>
        <Toolbar variant="dense" classes={{ root: classes.toolbarRoot, gutters: classes.toolbarGutters }}>
          <Button
            className={classes.logo}
            id="meetLogo_id"
            onClick={() => {
              history.push({
                pathname: '/'
              });
            }}>
            <img alt="meet logo" style={{ width: 80, marginRight: '11%' }} src={logo} />
          </Button>
          <div className={classes.grow}>
            <div className={classes.sectionDesktop}>
              <Tabs
                value={navValue}
                onChange={handleNavChange}
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                centered
                aria-label="navigation header bar"
                className={classes.navTabBar}
                classes={{ indicator: classes.indicator }}
              >

                <Tab id="jobs_tab_id" label="Home" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 0 ? <HomeRoundedIcon /> : <HomeOutlinedIcon />} {...a11yProps(0)} />
                <Tab id="myjobs_tab_id" label="My Jobs" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 1 ? <BusinessCenterRoundedIcon /> : <BusinessCenterOutlinedIcon />} {...a11yProps(1)} />
                <Tab id="profile_tab_id" label="Profile" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 2 ? <PersonRoundedIcon /> : <PersonOutlineRoundedIcon />} {...a11yProps(2)} />
                <Tab id="support_tab_id" label="Support" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 3 ? <HeadsetMicRoundedIcon /> : <HeadsetMicOutlinedIcon />} {...a11yProps(3)} />

                {/* <Tab id="company_tab_id" label="Company" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 3 ? <BusinessRoundedIcon /> : <BusinessOutlinedIcon />} {...a11yProps(3)} /> */}
                {/* <Tab id="analytics_tab_id" label="Analytics" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 4 ? <AssessmentRoundedIcon /> : <AssessmentOutlinedIcon />} {...a11yProps(4)} /> */}
              </Tabs>
              {props.isSignedIn && <IconButton onClick={() => setDeskNotifyBarDisplay(p => !p)} className={classes.desktopNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                <Badge badgeContent={notificationBadgeCount} color="error">
                  <NotificationsOutlinedIcon color='action' />
                </Badge>
              </IconButton>}
              <IconButton
                id="shareButton"
                style={{ padding: '12px' }}
                onClick={() => {
                  if (props.isSignedIn) props.handleSettingsDrawer()
                  else props.handleDrawer()
                }}
              >
                <SettingsOutlinedIcon />
              </IconButton>

              {/* {(!localIsLoggedIn) && (
                <Button
                  id="signin_btn_id"
                  variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`} onClick={props.handleDrawer}>
                  Sign In
                </Button>
              )}

              {(localIsLoggedIn) ?
                <Button id="signout_btn_id" variant="contained" onClick={signOut} className={`${classes.headerButton} ${classes.headerButton_sec}`}>
                  Sign Out
                </Button>
                : null} */}

            </div>
            <div className={classes.sectionMobile}>
              <Tabs
                value={navValue}
                onChange={handleNavChange}
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                // centered
                aria-label="navigation header bar"
                className={classes.navTabBar}
                // style={{ paddingLeft: 0, minHeight: 50 }}
                classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }}
              >

                <Tab id="jobs_mobile_tab_id" label="Home" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected, textColorPrimary: classes.textColorPrimary }} icon={navValue === 0 ? <HomeRoundedIcon className="selectedLabel" /> : <HomeOutlinedIcon />} {...a11yProps(0)} />
                <Tab id="myjobs_mobile_tab_id" label="My Jobs" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected, textColorPrimary: classes.textColorPrimary }} icon={navValue === 1 ? <BusinessCenterRoundedIcon className="selectedLabel" /> : <BusinessCenterOutlinedIcon />} {...a11yProps(1)} />
                <Tab id="profile_mobile_tab_id" label="Profile" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected, textColorPrimary: classes.textColorPrimary }} icon={navValue === 2 ? <PersonRoundedIcon className="selectedLabel" /> : <PersonOutlineRoundedIcon />} {...a11yProps(2)} />
                <Tab id="support_mobile_tab_id" label="Support" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected, textColorPrimary: classes.textColorPrimary }} icon={navValue === 3 ? <HeadsetMicRoundedIcon className="selectedLabel" /> : <HeadsetMicOutlinedIcon />} {...a11yProps(3)} />
                {/* <Tab id="company_mobile_tab_id" label="Company" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 3 ? <BusinessRoundedIcon /> : <BusinessOutlinedIcon />} {...a11yProps(3)} /> */}
                {/* <Tab id="analytics_mobile_tab_id" label="Analytics" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 4 ? <AssessmentRoundedIcon /> : <AssessmentOutlinedIcon />} {...a11yProps(4)} /> */}
              </Tabs>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {/* desktop notifications */}
      <div style={{ display: deskNotifyBarDisplay ? 'block' : 'none' }} className={classes.desktopNotificationContainer}>
        <Grid style={{ height: 60, position: 'sticky', top: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)', padding: '0 2em', marginBottom: 2 }} alignItems='center' justifyContent='space-between' container>
          <Typography variant='h6' >Your Notifications</Typography>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setDeskNotifyBarDisplay(false)} />
        </Grid>
        <Notifications classes={classes} localIsLoggedIn={localIsLoggedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setDeskNotifyBarDisplay} variant='desktop' />
      </div>
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
    </React.Fragment>
    // </ThemeProvider>
  );
}